import type { FunctionComponent } from 'react';
import { twJoin } from 'tailwind-merge';

import { Button } from '@/core/tamagoshiTailwind/components/Button/Button';
import { AIIcon } from '@/core/tamagoshiTailwind/icons/system/AIIcon';
import { CrossIcon } from '@/core/tamagoshiTailwind/icons/system/CrossIcon';
import { SearchIcon } from '@/core/tamagoshiTailwind/icons/system/SearchIcon';
import { headerSearchCommon } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/components/SearchForm/translations';

interface Props {
  hasValue: boolean;
  onReset: () => void;
  searchAI?: boolean;
}

export const SearchFormControls: FunctionComponent<Props> = ({
  hasValue,
  onReset,
  searchAI,
}) => {
  return (
    <div className="absolute right-[6px] top-[6px] flex h-[32px] items-center">
      {hasValue && (
        <Button
          className="relative size-[16px] rounded-full border-0
            hover:bg-foundation-inverted active:bg-foundation-inverted
            desktop:after:mr-m desktop:after:h-[24px] desktop:after:border-r
            desktop:after:border-foundation-secondary"
          aria-label={headerSearchCommon.delete}
          onClick={onReset}
          accessibilityLabel={headerSearchCommon.delete}
          icon={CrossIcon}
          variant="tertiary"
        />
      )}
      <button
        className={twJoin(
          `text-foundation-on-primary flex items-center justify-center
          rounded-full bg-accent-primary p-xs`,
          hasValue && 'hidden desktop:block',
        )}
        aria-label={headerSearchCommon.magnifyingGlass}
        type="submit"
      >
        <SearchIcon />
        {searchAI && <AIIcon />}
      </button>
    </div>
  );
};

SearchFormControls.displayName = 'SearchFormControls';

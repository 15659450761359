import type { ActionCreator } from 'redux';

import type { ActionWithPayload } from '@/domains/core/applicationState/interfaces';
import type {
  AutoCompleteSearchResults,
  EmptySearchResults,
} from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/algolia';

export const SET_SEARCH_SUGGESTIONS_VISIBILITY =
  'productDiscovery/HeaderLeonidas/SET_SEARCH_SUGGESTIONS_VISIBILITY';

export type SetSearchSuggestionsVisibilityAction = ActionWithPayload<
  { visible: boolean },
  typeof SET_SEARCH_SUGGESTIONS_VISIBILITY
>;

export const setSearchSuggestionsVisibility: ActionCreator<
  SetSearchSuggestionsVisibilityAction
> = (visible: boolean) => ({
  payload: { visible },
  type: SET_SEARCH_SUGGESTIONS_VISIBILITY,
});

export const SET_SEARCH_INPUT =
  'productDiscovery/HeaderLeonidas/SET_SEARCH_INPUT';

export type SetSearchInputAction = ActionWithPayload<
  { inputValue: string },
  typeof SET_SEARCH_INPUT
>;

export const setSearchInput: ActionCreator<SetSearchInputAction> = (
  inputValue: string,
) => ({
  payload: { inputValue },
  type: SET_SEARCH_INPUT,
});

export const SUGGESTIONS_FETCHING_STATUS =
  'productDiscovery/HeaderLeonidas/SUGGESTIONS_FETCHING_STATUS';

export interface SuggestionsFetchingStatus {
  type: typeof SUGGESTIONS_FETCHING_STATUS;
  status: boolean;
}

export const suggestionsFetchingStatus = (
  status: boolean,
): SuggestionsFetchingStatus => ({
  type: SUGGESTIONS_FETCHING_STATUS,
  status,
});

export const GET_AUTO_COMPLETE_SUGGESTIONS =
  'productDiscovery/HeaderLeonidas/GET_AUTO_COMPLETE_SUGGESTIONS';

export interface GetAutoCompleteSuggestions {
  type: typeof GET_AUTO_COMPLETE_SUGGESTIONS;
  query: string;
}

export const getAutoCompleteSuggestions = (
  query: string,
): GetAutoCompleteSuggestions => ({
  type: GET_AUTO_COMPLETE_SUGGESTIONS,
  query,
});

export const GET_AUTO_COMPLETE_SUGGESTIONS_SUCCESS =
  'productDiscovery/HeaderLeonidas/GET_AUTO_COMPLETE_SUGGESTIONS_SUCCESS';

export interface GetAutoCompleteSuggestionsSuccess {
  type: typeof GET_AUTO_COMPLETE_SUGGESTIONS_SUCCESS;
  termQuery: string;
  resultsQueries: AutoCompleteSearchResults;
  bestCategoryEnabled: boolean;
}

export const getAutoCompleteSuggestionsSuccess = (
  termQuery: string,
  resultsQueries: AutoCompleteSearchResults,
  bestCategoryEnabled: boolean,
): GetAutoCompleteSuggestionsSuccess => ({
  type: GET_AUTO_COMPLETE_SUGGESTIONS_SUCCESS,
  termQuery,
  resultsQueries,
  bestCategoryEnabled,
});

export const GET_AUTO_COMPLETE_SUGGESTIONS_FAILED =
  'productDiscovery/HeaderLeonidas/GET_AUTO_COMPLETE_SUGGESTIONS_FAILED';

export interface GetAutoCompleteSuggestionsFailed {
  type: typeof GET_AUTO_COMPLETE_SUGGESTIONS_FAILED;
  error: Error;
  query: string;
}

export const getAutoCompleteSuggestionsFailed = (
  error: Error,
  query: string,
): GetAutoCompleteSuggestionsFailed => ({
  type: GET_AUTO_COMPLETE_SUGGESTIONS_FAILED,
  error,
  query,
});

export const RESET_AUTO_COMPLETE_SUGGESTIONS =
  'productDiscovery/HeaderLeonidas/RESET_AUTO_COMPLETE_SUGGESTIONS';

export interface ResetAutoCompleteSuggestions {
  type: typeof RESET_AUTO_COMPLETE_SUGGESTIONS;
}

export const resetAutoCompleteSuggestions =
  (): ResetAutoCompleteSuggestions => ({
    type: RESET_AUTO_COMPLETE_SUGGESTIONS,
  });

export const UPDATE_EMPTY_SEARCH_SUGGESTIONS =
  'productDiscovery/HeaderLeonidas/UPDATE_EMPTY_SEARCH_SUGGESTIONS';

export interface UpdateEmptySearchSuggestions {
  type: typeof UPDATE_EMPTY_SEARCH_SUGGESTIONS;
}

export const updateEmptySearchSuggestions =
  (): UpdateEmptySearchSuggestions => ({
    type: UPDATE_EMPTY_SEARCH_SUGGESTIONS,
  });

export const GET_EMPTY_SEARCH_SUGGESTIONS =
  'productDiscovery/HeaderLeonidas/GET_EMPTY_SEARCH_SUGGESTIONS';

export interface GetEmptySearchSuggestions {
  type: typeof GET_EMPTY_SEARCH_SUGGESTIONS;
}

export const getEmptySearchSuggestions = (): GetEmptySearchSuggestions => ({
  type: GET_EMPTY_SEARCH_SUGGESTIONS,
});

export const GET_EMPTY_SEARCH_SUGGESTIONS_SUCCESS =
  'productDiscovery/HeaderLeonidas/GET_EMPTY_SEARCH_SUGGESTIONS_SUCCESS';

export interface getEmptySearchSuggestionsSuccess {
  type: typeof GET_EMPTY_SEARCH_SUGGESTIONS_SUCCESS;
  resultsQueries: EmptySearchResults;
  staticSuggestions?: boolean;
  lastSeenProducts?: boolean;
}

export const getEmptySearchSuggestionsSuccess = (
  resultsQueries: EmptySearchResults,
  staticSuggestions?: boolean,
  lastSeenProducts?: boolean,
): getEmptySearchSuggestionsSuccess => ({
  type: GET_EMPTY_SEARCH_SUGGESTIONS_SUCCESS,
  resultsQueries,
  staticSuggestions,
  lastSeenProducts,
});

export const GET_EMPTY_SEARCH_SUGGESTIONS_FAILED =
  'productDiscovery/HeaderLeonidas/GET_EMPTY_SEARCH_SUGGESTIONS_FAILED';

export interface getEmptySearchSuggestionsFailed {
  type: typeof GET_EMPTY_SEARCH_SUGGESTIONS_FAILED;
  error: Error;
}

export const getEmptySearchSuggestionsFailed = (
  error: Error,
): getEmptySearchSuggestionsFailed => ({
  type: GET_EMPTY_SEARCH_SUGGESTIONS_FAILED,
  error,
});

export const UPDATE_SUGGESTIONS_HISTORY =
  'productDiscovery/HeaderLeonidas/UPDATE_SUGGESTIONS_HISTORY';

export interface UpdateSuggestionsHistory {
  type: typeof UPDATE_SUGGESTIONS_HISTORY;
}

export const updateSuggestionsHistory = (): UpdateSuggestionsHistory => ({
  type: UPDATE_SUGGESTIONS_HISTORY,
});

export const REMOVE_ALL_SUGGESTIONS_HISTORY =
  'productDiscovery/HeaderLeonidas/REMOVE_ALL_SUGGESTIONS_HISTORY';

export interface RemoveAllSuggestionsHistory {
  type: typeof REMOVE_ALL_SUGGESTIONS_HISTORY;
}

export const removeAllSuggestionsHistory = (): RemoveAllSuggestionsHistory => ({
  type: REMOVE_ALL_SUGGESTIONS_HISTORY,
});

export type Action =
  | SetSearchSuggestionsVisibilityAction
  | SetSearchInputAction
  | SuggestionsFetchingStatus
  | GetAutoCompleteSuggestions
  | GetAutoCompleteSuggestionsSuccess
  | GetAutoCompleteSuggestionsFailed
  | ResetAutoCompleteSuggestions
  | UpdateSuggestionsHistory
  | RemoveAllSuggestionsHistory
  | GetEmptySearchSuggestions
  | getEmptySearchSuggestionsSuccess
  | getEmptySearchSuggestionsFailed;

import type { FC } from 'react';

import { Text } from '@/core/tamagoshi/Text/Text';

import { subtitle, title } from './translations';

import styles from './EmptyLeftSuggestions.module.scss';

export const EmptyLeftSuggestions: FC = () => (
  <li className={styles.emptyLeftSuggestionsContainer}>
    <Text tag="p" weight="semiBold" className={styles.title}>
      {title}
    </Text>
    <Text tag="p" className={styles.subtitle}>
      {subtitle}
    </Text>
  </li>
);

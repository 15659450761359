import type { MultipleQueriesQuery } from '@algolia/client-search';

import { PlainMultipleQueriesQuery } from '@/domains/productDiscovery/algolia/interfaces/interfaces';
import { getAlgoliaAppSuggestionsIndexes } from '@/domains/productDiscovery/algolia/settings/settings.common';
import { sanitizeQuery } from '@/domains/productDiscovery/Listings/services/querystring';

import {
  buildLastSeenProductsSearchParameters,
  buildSimilarityProductsSearchParameters,
  buildStaticProductsSuggestionsSearchParameters,
  buildTopSalesSuggestionsSearchParameters,
} from '../../../productSuggestions/modules/emptySearchResults/services/algolia/searchParameters';
import {
  AUTOCOMPLETE_NB_SUGGESTIONS_BRANDS,
  AUTOCOMPLETE_NB_SUGGESTIONS_CATEGORIES,
  AUTOCOMPLETE_NB_SUGGESTIONS_QUERIES,
  EMPTYSEARCH_NB_SUGGESTIONS_WITHOUT_HISTORY,
  NB_RANDOM_QUERIES_TO_FETCH,
  PRODUCTS_IN_SEARCHBAR_SLIDER,
  PRODUCTS_IN_SEARCHBAR_SLIDER_WITH_SIMILARITY,
} from '../../constants';
import { buildPlainMultipleQueriesQuery } from './buildMultipleQueriesQuery';

export const buildSuggestionsSearchParameters = ({
  query = '',
  bestCategoryEnabled = false,
}: {
  query: string;
  bestCategoryEnabled?: boolean;
}): MultipleQueriesQuery[] => {
  const indexes = getAlgoliaAppSuggestionsIndexes();

  return [
    buildPlainMultipleQueriesQuery(query, indexes.query, {
      hitsPerPage: AUTOCOMPLETE_NB_SUGGESTIONS_QUERIES,
    }),
    buildPlainMultipleQueriesQuery(query, indexes.category, {
      hitsPerPage: AUTOCOMPLETE_NB_SUGGESTIONS_CATEGORIES,
      ruleContexts: ['redirection'],
    }),
    buildPlainMultipleQueriesQuery(query, indexes.brand, {
      hitsPerPage: AUTOCOMPLETE_NB_SUGGESTIONS_BRANDS,
      ruleContexts: ['redirection'],
    }),
    bestCategoryEnabled && {
      query,
      indexName: indexes.bestCategory,
    },
  ].filter(Boolean) as MultipleQueriesQuery[];
};

export const buildTopSuggestionsSearchParameters = ({
  staticSuggestions = [],
  lastSeenProducts = [],
  similarityProducts = [],
}: {
  staticSuggestions?: string[];
  lastSeenProducts?: string[];
  similarityProducts?: string[];
}) => {
  const indexes = getAlgoliaAppSuggestionsIndexes();
  const query = '';
  const nbProductsInSuggestion = similarityProducts.length
    ? PRODUCTS_IN_SEARCHBAR_SLIDER_WITH_SIMILARITY
    : PRODUCTS_IN_SEARCHBAR_SLIDER;

  const requestParams: MultipleQueriesQuery[] = [
    buildPlainMultipleQueriesQuery(query, indexes.query, {
      hitsPerPage: NB_RANDOM_QUERIES_TO_FETCH,
    }),
    buildPlainMultipleQueriesQuery(query, indexes.category, {
      hitsPerPage: EMPTYSEARCH_NB_SUGGESTIONS_WITHOUT_HISTORY,
    }),
    buildPlainMultipleQueriesQuery(query, indexes.brand, {
      hitsPerPage: EMPTYSEARCH_NB_SUGGESTIONS_WITHOUT_HISTORY,
    }),
  ];

  requestParams.push(buildTopSalesSuggestionsSearchParameters({}));

  if (staticSuggestions.length) {
    requestParams.push(
      buildStaticProductsSuggestionsSearchParameters({
        staticSuggestions,
      }),
    );
  }

  if (lastSeenProducts.length) {
    requestParams.push(
      buildLastSeenProductsSearchParameters({
        lastSeenProducts,
        options: {
          hitsPerPage: nbProductsInSuggestion,
        },
      }),
    );
  }

  if (similarityProducts.length) {
    requestParams.push(
      buildSimilarityProductsSearchParameters({
        similarityProducts,
        options: {
          hitsPerPage: nbProductsInSuggestion,
        },
      }),
    );
  }

  return requestParams;
};

export const buildSuggestionsRedirectionsSearchParameters = (
  query = '',
): PlainMultipleQueriesQuery[] => {
  const indexes = getAlgoliaAppSuggestionsIndexes();
  const decodedQuery = sanitizeQuery(query);

  return [
    {
      params: {
        hitsPerPage: 0,
        ruleContexts: ['redirection'],
      },
      query: decodedQuery,
      indexName: indexes.category,
    },
    {
      params: {
        hitsPerPage: 0,
        ruleContexts: ['redirection'],
      },
      query: decodedQuery,
      indexName: indexes.brand,
    },
  ];
};

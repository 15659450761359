import classnames from 'classnames';
import { type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { ModalContent } from '@/core/tamagoshi/Modal/ModalContent';
import { Modal } from '@/domains/core/tamagoshi/Modal/Modal';
import { hideAdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.actions';
import { useAdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/hooks/useAdvertiserInformation';

import styles from './AdvertiserInformationPopup.module.scss';

export interface AdvertiserInformationPopupProps {
  className?: string;
}

export const AdvertiserInformationPopup: FunctionComponent<
  AdvertiserInformationPopupProps
> = ({ className }) => {
  const showDSAModalBannerCampaigns = useAdvertiserInformation();
  const rootClassnames = classnames(styles.root, className);
  const dispatch = useDispatch();

  if (!showDSAModalBannerCampaigns) {
    return null;
  }
  const { dsaPopup, content } = showDSAModalBannerCampaigns;

  if (!dsaPopup) {
    return null;
  }

  return (
    <div className={rootClassnames}>
      <Modal
        className={styles.modalWrapper}
        onClose={() => {
          dispatch(hideAdvertiserInformation());
        }}
      >
        <ModalContent>
          <div
            className={styles.body}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: `${content}` }}
          />
        </ModalContent>
      </Modal>
    </div>
  );
};

AdvertiserInformationPopup.displayName = 'AdvertiserInformationPopup';

import type { FunctionComponent } from 'react';

import { Image } from '@/domains/core/Image/Image';
import { CURRENCY } from '@/domains/core/settings/constants';
import {
  MiniProductCard as MiniProductCardTamagoshi,
  type MiniProductCardProps as MiniProductCardPropsTamagoshi,
} from '@/domains/core/tamagoshi/MiniProductCard/MiniProductCard';
import { MiniProductCardLink } from '@/productDiscovery/MiniProductCard/components/MiniProductCardLink.default';

import { mapCurrency } from '../ProductCard/utils/mapCurrency';
import { getMiniProductCardTranslations } from './translations';

export interface MiniProductCardProps
  extends Omit<MiniProductCardPropsTamagoshi, 'currency' | 'as' | 'imageAs'> {
  favorite?: boolean;
}

export const MiniProductCard: FunctionComponent<MiniProductCardProps> = ({
  favorite = false,
  translations,
  ...props
}) => (
  <MiniProductCardTamagoshi
    {...props}
    currency={mapCurrency(CURRENCY)}
    as={MiniProductCardLink}
    imageAs={Image as MiniProductCardPropsTamagoshi['imageAs']}
    translations={getMiniProductCardTranslations()}
  />
);

MiniProductCardTamagoshi.displayName = 'MiniProductCard';

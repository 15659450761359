import { Round as CloseIcon } from '@manomano-internal/tamagoshi-icons/esm/material/Close';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import {
  useEffect,
  useRef,
  useState,
  type DetailedHTMLProps,
  type FunctionComponent,
  type HTMLAttributes,
} from 'react';
import { createPortal } from 'react-dom';

import { Button } from '../Button/Button';
import type { ModalProps } from './Modal.interface';

import styles from './scss/modal.module.scss';

/**
 * @deprecated Use `@/core/tamagoshiTailwind/components/Modal/Modal` instead.
 */
export const Modal: FunctionComponent<
  ModalProps & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({
  isCloseButtonVisible = true,
  hasPadding = true,
  className,
  onClose,
  isFullWidth = false,
  closeButtonAriaLabel = 'close',
  maxWidth = 'md',
  children,
  trapFocus = true,
  preventEventPropagation = false,
  onTransitionEnd,
  onAnimationStart,
  onAnimationEnd,
  containerClassName,
  ...restProps
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  // The way to test the modal on server-side rendering
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const bodyClassNames = classnames(
    { [styles.fullWidth]: isFullWidth },
    styles.body,
    styles[maxWidth],
    hasPadding && styles.withPadding,
    className,
  );

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (preventEventPropagation) event.stopPropagation();
    if (!modalRef.current?.contains(event.target as Node)) {
      onClose();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Esc' || event.key === 'Escape') {
      onClose();
    }
  };

  const modalMarkup = (
    <div
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={styles.overlay}
      role="presentation"
    >
      <FocusTrap active={trapFocus}>
        <div
          className={classnames(styles.container, containerClassName)}
          role="none presentation"
          {...restProps}
        >
          <div
            className={bodyClassNames}
            ref={modalRef}
            role="dialog"
            onTransitionEnd={onTransitionEnd}
            onAnimationStart={onAnimationStart}
            onAnimationEnd={onAnimationEnd}
          >
            {children}
            {isCloseButtonVisible && (
              <Button
                variant="tertiary"
                type="button"
                className={styles.close}
                aria-label={closeButtonAriaLabel}
                onClick={onClose}
              >
                <CloseIcon focusable={false} aria-hidden />
              </Button>
            )}
          </div>
        </div>
      </FocusTrap>
    </div>
  );

  return isClient ? createPortal(modalMarkup, document.body) : null;
};

Modal.displayName = 'Modal';

import { useSelector } from 'react-redux';

import type { LeonidasQuickAccessLinks } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/Header.model';

import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';

export const quickAccessLinksSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): LeonidasQuickAccessLinks | undefined =>
  state.productDiscovery?.header?.quickAccessLinks;

export const useQuickAccessLinks = () => useSelector(quickAccessLinksSelector);

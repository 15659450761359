import type { FunctionComponent } from 'react';

import { MenuCampaign } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuCampaign/MenuCampaign';
import { useMenuCampaigns } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useMenuCampaigns';

import styles from '../../Menu.module.scss';

interface Props {
  categoryId: number;
  categoryName: string;
}

export const MenuCampaigns: FunctionComponent<Props> = ({
  categoryId,
  categoryName,
}) => {
  const campaigns = useMenuCampaigns(categoryId)?.slice(0, 2);

  return campaigns && campaigns.length > 0 ? (
    <div className={styles.campaignsContainer}>
      <div className={styles.campaigns}>
        {campaigns.map((campaign, index) => (
          <MenuCampaign
            campaign={campaign}
            key={campaign.id}
            position={index}
          />
        ))}
      </div>
    </div>
  ) : null;
};

MenuCampaigns.displayName = 'MenuCampaigns';

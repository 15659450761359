import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDynamicSaga } from '@/domains/core/applicationState/hooks/useDynamicSaga';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { initHeaderBanners } from '@/domains/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBanners.actions';
import { rootSaga } from '@/domains/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBanners.saga';
import { useHeaderBanners } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useHeaderBanners';

export const useInitHeaderBanners = () => {
  useDynamicSaga('headerBannerSaga', rootSaga);
  const headerBanners = useHeaderBanners();

  const auth = useSelector(authSelector);
  const { hasRetrievedCustomerSegments } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasRetrievedCustomerSegments && !headerBanners) {
      dispatch(initHeaderBanners());
    }
  }, [dispatch, hasRetrievedCustomerSegments, headerBanners]);

  return headerBanners;
};

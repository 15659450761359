import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useState, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { useTrackViewedElement } from '@/core/tracking/hooks/useTrackViewedElement';
import { Link } from '@/domains/core/routing/components/Link';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { CampaignModalProps } from '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { BannerCampaignAdvertiserLabel } from '@/productDiscovery/CommercialAnimation/components/AdvertiserInformation/BannerCampaignAdvertiserLabel';
import { GTM_CLICK_ON_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/clickOnBannerCampaign';
import { GTM_DISPLAY_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/displayBannerCampaign';
import { setMenuVisibility } from '@/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';

import MenuCampaignImg from './MenuCampaignImg';

import styles from './MenuCampaign.module.scss';

const DynamicModal = dynamic<CampaignModalProps>(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal'
      )
    ).CampaignModal,
);

interface Props {
  className?: string;
  campaign: BannerCampaign;
  position: number;
}

export const MenuCampaign: FunctionComponent<Props> = ({
  className,
  campaign,
  position,
}) => {
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);

  const [campaignRef] = useTrackViewedElement(() => {
    Gtm.push(
      GTM_DISPLAY_BANNER_CAMPAIGN(
        'burger_menu_brand_block',
        campaign.id,
        campaign.name,
        campaign.link.url || '',
        position,
        campaign.sponsor !== null && campaign.advertiser !== null,
        campaign.pageType || undefined,
        campaign.trackingId || undefined,
      ),
    );
  });

  const handleClickEvent = () => {
    Gtm.push(
      GTM_CLICK_ON_BANNER_CAMPAIGN(
        'burger_menu_brand_block',
        campaign.id,
        campaign.name,
        campaign.link.url || '',
        position,
        !!(campaign.sponsor !== null && campaign.advertiser !== null),
        campaign.pageType || undefined,
        campaign.trackingId || undefined,
      ),
    );
    dispatch(setMenuVisibility(false));
  };

  const handleCampaignClick = () => {
    setDisplayModal(true);
    handleClickEvent();
  };

  return !campaign.link.url || campaign.link.target !== '_modal' ? (
    <Link
      className={classnames(styles.container, className)}
      href={campaign.link.url || ''}
      target={campaign.link.target}
      onClick={handleClickEvent}
      ref={campaignRef}
    >
      {(campaign.sponsor || campaign.advertiser) && (
        <BannerCampaignAdvertiserLabel
          sponsor={campaign.sponsor}
          advertiser={campaign.advertiser}
          userSegments={campaign.userSegments}
          withRadius={false}
          spaceSlug="menu_category"
        />
      )}
      <MenuCampaignImg
        desktopUrl={campaign.image.desktopUrl || ''}
        altText={campaign.image.altText}
      />
    </Link>
  ) : (
    <>
      <button
        className={classnames(styles.container, styles.itemLink, className)}
        data-testid="menuCampaign-button"
        onClick={handleCampaignClick}
        type="button"
        ref={campaignRef}
      >
        {(campaign.sponsor || campaign.advertiser) && (
          <BannerCampaignAdvertiserLabel
            sponsor={campaign.sponsor}
            advertiser={campaign.advertiser}
            userSegments={campaign.userSegments}
            withRadius={false}
            spaceSlug="menu_category"
          />
        )}
        <MenuCampaignImg
          desktopUrl={campaign.image.desktopUrl || ''}
          altText={campaign.image.altText}
        />
      </button>
      {displayModal && (
        <DynamicModal
          url={campaign.link.url}
          onClose={() => setDisplayModal(false)}
        />
      )}
    </>
  );
};

import Script from 'next/script';
import { useEffect, useState } from 'react';

import { getAppSetting } from '@/domains/core/settings/appSettings';
import { waitForAndGetConsents } from '@/domains/legal/GDPR/utils/waitForAndGetConsents';

export const AppsFlyerSmartBanner = () => {
  const [hasUserAcceptedCookies, setHasUserAcceptedCookies] = useState(false);
  useEffect(() => {
    waitForAndGetConsents().then((consents) => {
      setHasUserAcceptedCookies(consents.includes('personalisedAdvertising'));
    });
  }, []);
  const appsFlyerSmartBannerKey = getAppSetting('APPSFLYER_SMART_BANNER_KEY');

  return hasUserAcceptedCookies ? (
    <Script
      id="appsFlyer-banner"
      data-testid="appsFlyer-banner"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: '${appsFlyerSmartBannerKey}'}})
          AF('banners', 'showBanner')`,
      }}
    />
  ) : null;
};

AppsFlyerSmartBanner.displayName = 'AppsFlyerSmartBanner';

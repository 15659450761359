import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '@/core/tamagoshi/Modal/Modal';
import { ModalContent } from '@/core/tamagoshi/Modal/ModalContent';
import { ModalHeader } from '@/core/tamagoshi/Modal/ModalHeader';
import { isB2B } from '@/domains/core/settings/utils';
import { useTranslationsContactNewsletterModalSuccess } from '@/domains/retention/Footer/hooks/useTranslations';
import { useSuccessModalVisible } from '@/domains/retention/NewsletterModal/hooks/useSuccessModalVisibility';
import { hideModalSuccess } from '@/domains/retention/NewsletterModal/Newsletter.actions';

import styles from './NewsletterSuccess.module.scss';

export const NewsletterSuccess: FunctionComponent = () => {
  const showModalNewsletterSuccess = useSuccessModalVisible();
  const translations = useTranslationsContactNewsletterModalSuccess();
  const dispatch = useDispatch();
  const onCloseNewsletterSuccessModal = () => {
    dispatch(hideModalSuccess());
  };

  if (!showModalNewsletterSuccess) {
    return null;
  }

  return (
    <Modal
      onClose={onCloseNewsletterSuccessModal}
      className={styles.modal}
      trapFocus={false}
    >
      <ModalHeader className={styles.header}>
        <span className={styles.title}>{translations.title}</span>
      </ModalHeader>
      <ModalContent
        className={classnames(styles.content, {
          [styles.b2b]: isB2B(),
          [styles.b2c]: !isB2B(),
        })}
      >
        <div className={styles.body}>{translations.subtitle}</div>
      </ModalContent>
    </Modal>
  );
};

NewsletterSuccess.displayName = 'NewsletterSuccess';

import {
  WishListMiniPopupEventIDs,
  WishListMiniPopupMessageTypes,
} from '../components/WishListIconWithMiniPopup/constants';

export const OVERLAY_NONE = '';
export const OVERLAY_MENU = 'overlay-menu';
export const OVERLAY_SEARCH = 'overlay-search';

export const initialState: HeaderLeonidasState = {
  fetching: false,
  ui: {
    connectButtonVisible: false,
    menuVisible: false,
    promosVisible: false,
    suggestionsVisible: false,
    displayEmptySearch: false,
    overlay: OVERLAY_NONE,
    focusedSuggestionPosition: 0, // no element focused
    wishlistMiniPopup: {
      // added for feature flag WISHLIST_DESIGN_REVAMP
      visible: false,
      imageUrl: '',
      message: '',
      effectEventId: WishListMiniPopupEventIDs.CONSUMED,
      messageType: WishListMiniPopupMessageTypes.NORMAL,
    },
  },
};

export interface HeaderLeonidasState {
  fetching: boolean;
  error?: string;
  ui: HeaderUiState;
}

export interface HeaderUiState {
  connectButtonVisible: boolean;
  wishlistMiniPopup: WishListMiniPopupState; // related to featureflag

  menuVisible: boolean;
  selectedFamily?: number;
  selectedCategory?: number;

  promosVisible: boolean;

  overlay: string;
  pageYOffset?: number;

  focusedSuggestionPosition: number;
  suggestionsVisible: boolean;
  displayEmptySearch: boolean;
  search?: {
    input?: string;
  };
}

export interface WishListMiniPopupState {
  visible?: boolean;
  imageUrl?: string;
  message: string;
  messageType?: WishListMiniPopupMessageTypes;
  actionText?: string;
  effectEventId?: WishListMiniPopupEventIDs;
}

import type { ReactNode } from 'react';

interface PreHeaderBarProps {
  children?: ReactNode;
}
export const PreHeaderBar = ({ children }: PreHeaderBarProps) => (
  <div
    className="relative flex h-[32px] cursor-pointer items-stretch
      whitespace-nowrap bg-accent-secondary text-foundation-primary"
    data-testid="PreHeaderBar"
  >
    {children}
  </div>
);

PreHeaderBar.displayName = 'PreHeaderBar';

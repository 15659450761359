import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const searchSuggestionsVisibilitySelector = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean =>
  state.productDiscovery?.headerLeonidas?.ui.suggestionsVisible || false;

export const useSearchSuggestionsVisibility = () =>
  useSelector(searchSuggestionsVisibilitySelector);

import { useSelector } from 'react-redux';

import type { SpartacuxShoppingCartState } from '../../ShoppingCartState';
import {
  cartOverviewState,
  type CartOverviewState,
} from '../interfaces/CartOverview';

export const useCartOverview = () =>
  useSelector<SpartacuxShoppingCartState, CartOverviewState>(
    (state: SpartacuxShoppingCartState) => {
      if (state.shoppingCart?.cartOverview) {
        const { itemsCount, productCount, contents } = state.shoppingCart
          ?.cartOverview ?? {
          itemsCount: undefined,
          productCount: undefined,
          contents: undefined,
        };
        return {
          ...cartOverviewState,
          itemsCount,
          productCount,
          contents,
        };
      }
      return cartOverviewState;
    },
  );

export const useCartItemCount = () =>
  useSelector<SpartacuxShoppingCartState, number>(
    (state: SpartacuxShoppingCartState) =>
      state.shoppingCart?.cartOverview
        ? state.shoppingCart?.cartOverview.itemsCount
        : cartOverviewState.itemsCount,
  );

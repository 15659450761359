import dynamic from 'next/dynamic';
import React, { type FunctionComponent, type PropsWithChildren } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import { useSelector } from 'react-redux';

import type {
  PageCustomData,
  PageProps,
  PageViewCustomData,
} from '@/core/page/interfaces/PageProps';
import { FlagsOverrideChecker } from '@/core/webTools/FlagsOverrideBanner/FlagsOverrideChecker';
import { isMobileAppUserAgentSelector } from '@/domains/core/client/client.selectors';
import { useUserTiming } from '@/domains/core/observability/utils/useUserTiming';
import {
  MetaTags,
  type MetaTagsProps,
} from '@/domains/core/page/components/MetaTags';
import { ProgressBar } from '@/domains/core/page/components/ProgressBar/ProgressBar';
import { usePageData } from '@/domains/core/page/hooks/usePageData';
import { DynamicLiveSupport } from '@/domains/digitalAdvice/LiveSupport/DynamicLiveSupport';
import { DynamicZendeskChat } from '@/domains/digitalAdvice/zendesk/DynamicZendeskChat';
import { AppsFlyerSmartBanner } from '@/domains/mobile/AppsFlyerSmartBanner/AppsFlyerSmartBanner';
import { AdvertiserInformationPopup } from '@/domains/productDiscovery/CommercialAnimation/components/AdvertiserInformation/AdvertiserInformationPopup';
import { HeaderLeonidas as Header } from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.default';
import { NewsletterSuccess } from '@/domains/retention/NewsletterModal/components/NewsletterSuccess/NewsletterSuccess';
import { NewsletterModalContainer } from '@/domains/retention/NewsletterModal/NewsletterModalContainer';
import { CartOverview } from '@/domains/shoppingCart/CartOverview/CartOverview';

import { BaseErrors } from './BaseErrors';
import { BasePageContent } from './BasePageContent.default';

const Footer = dynamic(
  () => import('@/domains/retention/Footer/Footer').then((mod) => mod.Footer),
  {
    ssr: true,
    loading: () => {
      try {
        const placeholder = document.getElementById('lazyFooter');
        return (
          <div
            suppressHydrationWarning
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: placeholder?.innerHTML || '' }}
          />
        );
      } catch {
        return null;
      }
    },
  },
);

const HumanSupportMain = dynamic(
  () =>
    import(
      '@/domains/proB2BAnimation/modules/HumanSupport/HumanSupportMain.default'
    ),
  { ssr: true },
);

export type BasePageProps<
  PageCustomDataType extends PageCustomData = PageCustomData,
  PageViewCustomDataType extends PageViewCustomData = PageViewCustomData,
> = PageProps<PageCustomDataType, PageViewCustomDataType> & MetaTagsProps;

function BasePageLayout({
  pageType,
  statusCode,
  children,
  header,
  footer,
}: {
  pageType: string;
  statusCode: number;
  children: React.ReactNode;
  header: React.ReactNode;
  footer: React.ReactNode;
}) {
  const isMobileApp = useSelector(isMobileAppUserAgentSelector);

  return (
    <>
      {!isMobileApp && header}
      <CartOverview pageType={pageType} />
      <BaseErrors statusCode={statusCode}>{children}</BaseErrors>
      {!isMobileApp && footer}
    </>
  );
}

/**
 * This base page component will manage displaying the default page layout and
 * providing page information to the datalayer.
 *
 * Others page component will exist, as checkout funnel one which uses specific
 * header and footer.
 */
export const BasePage: FunctionComponent<PropsWithChildren<BasePageProps>> = ({
  children,
  pageName,
  pageType,
  pageTitle,
  pageViewCustomData,
  pageCustomData,
  statusCode,
  ...metaTagsProps
}) => {
  const pageData = { pageName, pageType, pageViewCustomData, pageCustomData };
  useUserTiming(`${pageType} page rendering`);
  usePageData(pageData);

  return (
    <>
      <MetaTags
        pageName={pageName}
        pageTitle={pageTitle}
        pageType={pageType}
        {...metaTagsProps}
      />
      <AppsFlyerSmartBanner />
      <FlagsOverrideChecker />
      <ProgressBar />
      <BasePageLayout
        pageType={pageType}
        statusCode={statusCode}
        header={<Header pageType={pageType} />}
        footer={
          <>
            <LazyHydrate id="lazyFooter" whenVisible={{ rootMargin: '0px' }}>
              <Footer pageType={pageType} />
            </LazyHydrate>
            <DynamicLiveSupport
              pageCustomData={pageCustomData}
              pageName={pageName}
              pageType={pageType}
            />
            <DynamicZendeskChat
              pageCustomData={pageCustomData}
              pageName={pageName}
              pageType={pageType}
            />
            <HumanSupportMain pageType={pageType} />
          </>
        }
      >
        {children}
      </BasePageLayout>
      <BasePageContent />
      <NewsletterModalContainer />
      <NewsletterSuccess />
      <AdvertiserInformationPopup />
    </>
  );
};

import type { Reducer } from 'redux';

import type { Action } from '@/domains/core/applicationState/interfaces';

import {
  GET_CART_OVERVIEW_FAILED,
  GET_CART_OVERVIEW_REQUEST,
  GET_CART_OVERVIEW_SUCCESS,
} from './actions/GetCartOverview.actions';
import {
  cartOverviewState,
  type CartOverviewState,
} from './interfaces/CartOverview';

export const cartOverviewReducer: Reducer<CartOverviewState, Action> = (
  state = cartOverviewState,
  action,
) => {
  switch (action.type) {
    case GET_CART_OVERVIEW_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }
    case GET_CART_OVERVIEW_SUCCESS: {
      const { itemsCount, productCount, productsTotalPrice, contents, id } =
        action.cartOverview;
      return {
        ...state,
        itemsCount,
        productCount,
        productsTotalPrice,
        contents,
        id,
        fetching: false,
      };
    }
    case GET_CART_OVERVIEW_FAILED: {
      const { error } = action;
      return {
        ...state,
        error,
        fetching: false,
      };
    }
    default:
      return state;
  }
};

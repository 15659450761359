import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiIcon } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const AIIcon = makeTamagoshiIcon((props, ref) => (
  <Svg
    optimizationId="tama-eco"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      d="M12 18C12 17.8711 11.9031 17.7671 11.783 17.7671C9.81594 17.7671 8.21696 15.7337 8.21696 13.2329C8.21696 13.1039 8.1201 13 8 13C7.87989 13 7.78304 13.104 7.78304 13.2329C7.78304 15.7337 6.18236 17.7671 4.21696 17.7671C4.09685 17.7671 4 17.8711 4 18C4 18.1289 4.09686 18.2329 4.21696 18.2329C6.18236 18.2329 7.78304 20.2663 7.78304 22.7671C7.78304 22.8961 7.8799 23 8 23C8.12011 23 8.21696 22.896 8.21696 22.7671C8.21696 20.2663 9.81651 18.2329 11.783 18.2329C11.9026 18.2329 12 18.1289 12 18Z"
      fill="currentColor"
    />
    <path
      d="M20 8C20 7.81953 19.8547 7.674 19.6746 7.674C16.7239 7.674 14.3254 4.82724 14.3254 1.326C14.3254 1.14553 14.1801 1 14 1C13.8198 1 13.6746 1.14554 13.6746 1.326C13.6746 4.82724 11.2735 7.674 8.32544 7.674C8.14528 7.674 8 7.81954 8 8C8 8.18047 8.14529 8.326 8.32544 8.326C11.2735 8.326 13.6746 11.1728 13.6746 14.674C13.6746 14.8545 13.8199 15 14 15C14.1802 15 14.3254 14.8545 14.3254 14.674C14.3254 11.1728 16.7248 8.326 19.6746 8.326C19.8539 8.326 20 8.18046 20 8Z"
      fill="currentColor"
    />
  </Svg>
));

AIIcon.displayName = 'AIIcon';

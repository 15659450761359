import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { ArrowDown } from '@/core/tamagoshi/icons/ArrowDown';
import { useDynamicSaga } from '@/domains/core/applicationState/hooks/useDynamicSaga';
import { Link } from '@/domains/core/routing/components/Link';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { MenuCampaigns } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuCampaigns/MenuCampaigns';
import { MenuSubCategoryItemButton } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuSubCategoryItemButton/MenuSubCategoryItemButton';
import { getMenuCampaigns } from '@/domains/productDiscovery/HeaderLeonidas/Header.actions';
import {
  setMenuVisibility,
  setSelectedCategory,
} from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useMenuCampaigns } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useMenuCampaigns';
import { useSeeMoreLinks } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useSeeMoreLinks';
import { GTM_CLICK_ON_MENU_ITEM } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';
import { debounce } from '@/domains/productDiscovery/HeaderLeonidas/utils/debounce';
import { isMobile } from '@/domains/productDiscovery/HeaderLeonidas/utils/isMobile';
import type { MenuCategory } from '@/productDiscovery/HeaderLeonidas/interfaces/MenuTree.model';
import { rootSaga } from '@/productDiscovery/HeaderLeonidas/sagas/BannerCampaignsMenuCategory.client.saga';

import { leonidasMenuSeeMoreTitle } from '../../translations';

import styles from '../../Menu.module.scss';

interface Props {
  category: MenuCategory;
  family: MenuCategory;
  isVisible?: boolean;
  isMenuVisible: boolean;
}

export const MenuCategoryItem: FunctionComponent<Props> = ({
  category,
  isVisible = false,
  family,
  isMenuVisible,
}) => {
  useDynamicSaga('menuCampaignsSaga', rootSaga);
  const dispatch = useDispatch();
  const menuCampaigns = useMenuCampaigns(category.id);
  const { listRef, handleSeeMoreClick, seeMoreVisible } =
    useSeeMoreLinks(isVisible);

  const select = debounce(() => {
    dispatch(setSelectedCategory(category.id));

    if (!menuCampaigns && !isMobile()) {
      dispatch(getMenuCampaigns(category.id, family.id));
    }
  }, 20);

  const handleClickMenuSubCategoryItem = (subCategory: MenuCategory) => {
    dispatch(setMenuVisibility(false));

    const menuItem = {
      categoryName: family.name,
      subCategoryName: category.name,
      subSubCategoryName: subCategory.name,
      url: subCategory.url,
      level: 2,
    };

    Gtm.push(GTM_CLICK_ON_MENU_ITEM(menuItem));
  };

  return (
    <li
      className={styles.menuCategoryItem}
      data-expanded={isVisible ? 'true' : 'false'}
    >
      {isMenuVisible ? (
        <MenuSubCategoryItemButton
          category={category}
          family={family}
          select={select}
        />
      ) : (
        <Link href={category.url} className={styles.menuCategoryName}>
          {category.name}
        </Link>
      )}
      {isVisible && (
        <div className={styles.menuSubCategoriesLinks} ref={listRef}>
          <div className={styles.menuSubCategoryWrapper}>
            <Link href={category.url} className={styles.menuCategoryName}>
              {category.name}
            </Link>
            <ul className={styles.menuSubCategories}>
              {category.children?.map((subcategory) => (
                <li className={styles.menuSubCategoryItem} key={subcategory.id}>
                  <Link
                    href={subcategory.url}
                    className={styles.menuSubCategoryItemLink}
                    onClick={() => handleClickMenuSubCategoryItem(subcategory)}
                    data-testid="subsubcategory-item"
                  >
                    {subcategory.name}
                  </Link>
                </li>
              ))}
            </ul>
            <MenuCampaigns
              categoryId={category.id}
              categoryName={category.name}
            />
          </div>
          <button
            className={styles.seeMoreButton}
            aria-hidden={seeMoreVisible ? 'false' : 'true'}
            onClick={handleSeeMoreClick}
            type="button"
          >
            <span className={styles.seeMoreButtonText}>
              {leonidasMenuSeeMoreTitle}
              <ArrowDown className={styles.seeMoreButtonArrow} />
            </span>
          </button>
        </div>
      )}
    </li>
  );
};

MenuCategoryItem.displayName = 'MenuCategoryItem';

import type { Action } from 'redux';

import type { Offer as OfferInterface } from '@/domains/productDiscovery/ProductPage/modules/MainDetails/components/Seller/interfaces/OtherSellers.model';
import type {
  MsRecommendationProcessedResponse,
  MsRecommendationResponse,
} from '@/domains/productDiscovery/Recommendation/interfaces/msRecommendation';
import type { Recommendations } from '@/domains/productDiscovery/Recommendation/interfaces/productRecommendation';
import type { ProductRecommendationParams } from '@/domains/productDiscovery/Recommendation/modules/ProductRecommendation/interfaces/ProductRecommendation.model';
import type { Product } from '@/domains/shoppingCart/AddToCart/AddToCart.interfaces';

import type { ProductId } from '../interfaces/ProductId';

export const ADD_TO_CART_REQUEST = 'shoppingCart/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'shoppingCart/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILED = 'shoppingCart/ADD_TO_CART_FAILED';
export const SHOW_ADD_T0_CART_MODAL = 'shoppingCart/SHOW_ADD_T0_CART_MODAL';
export const HIDE_ADD_T0_CART_MODAL = 'shoppingCart/HIDE_ADD_T0_CART_MODAL';
export const SHOW_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL =
  'shoppingCart/SHOW_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL';
export const HIDE_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL =
  'shoppingCart/HIDE_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL';
export const A2C_INIT_PRODUCT_RECOMMENDATION =
  'shoppingCart/A2C_INIT_PRODUCT_RECOMMENDATION';
export const A2C_FETCH_RECOMMENDATION_CONFIG =
  'shoppingCart/A2C_FETCH_RECOMMENDATION_CONFIG';
export const A2C_FETCH_RECOMMENDATION_CONFIG_SUCCESS =
  'shoppingCart/A2C_FETCH_RECOMMENDATION_CONFIG_SUCCESS';
export const A2C_FETCH_RECOMMENDATION_CONFIG_FAILED =
  'shoppingCart/A2C_FETCH_RECOMMENDATION_CONFIG_FAILED';
export const A2C_FETCH_PRODUCTS_RECOMMENDATION =
  'shoppingCart/A2C_FETCH_PRODUCTS_RECOMMENDATION';
export const A2C_FETCH_PRODUCTS_RECOMMENDATION_SUCCESS =
  'shoppingCart/A2C_FETCH_PRODUCTS_RECOMMENDATION_SUCCESS';
export const A2C_FETCH_PRODUCTS_RECOMMENDATION_FAILED =
  'shoppingCart/A2C_FETCH_PRODUCTS_RECOMMENDATION_FAILED';
export const A2C_FETCH_RECOMMENDATION = 'shoppingCart/A2C_FETCH_RECOMMENDATION';
export const A2C_INIT_CONFIG_PROCESSING =
  'shoppingCart/A2C_INIT_CONFIG_PROCESSING';
export const A2C_CONFIG_PROCESSING_SUCCESS =
  'shoppingCart/A2C_CONFIG_PROCESSING_SUCCESS';
export const A2C_CONFIG_PROCESSING_FAILED =
  'shoppingCart/A2C_CONFIG_PROCESSING_FAILED';

// ACTION: SHOW_ADD_TO_CART_MODAL
interface ShowAddToCartModalPayload {
  showConfirmationMessage?: boolean;
}
export interface ShowAddToCartModalAction {
  type: typeof SHOW_ADD_T0_CART_MODAL;
  payload: ShowAddToCartModalPayload;
}

export const showAddToCartModal = (
  payload: ShowAddToCartModalPayload,
): ShowAddToCartModalAction => ({
  type: SHOW_ADD_T0_CART_MODAL,
  payload,
});

// ACTION: HIDE_ADD_TO_CART_MODAL
export interface HideAddToCartModalAction {
  type: typeof HIDE_ADD_T0_CART_MODAL;
}

// ACTION: SHOW_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL
export type ShowCrossPlatformPurchaseBlockedModalAction = Action<
  typeof SHOW_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL
>;

export const showCrossPlatformPurchaseBlockedModal =
  (): ShowCrossPlatformPurchaseBlockedModalAction => ({
    type: SHOW_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL,
  });

// ACTION: HIDE_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL
export type HideCrossPlatformPurchaseBlockedModalAction = Action<
  typeof HIDE_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL
>;

export const hideCrossPlatformPurchaseBlockedModal =
  (): HideCrossPlatformPurchaseBlockedModalAction => ({
    type: HIDE_CROSS_PLATFORM_PURCHASE_BLOCKED_MODAL,
  });

export interface PlaylistReconciliation {
  playlistFingerprint?: string;
  playlistReconciliation?: string;
  recoEngineName?: string;
  blockClicked?: string;
  playlistName?: {
    titleLabel?: string;
    blockClicked?: string;
  };
  playlistPosition?: number;
}

// ACTION: ADD_TO_CART_REQUEST
export interface AddToCartPayload {
  products: Product[];
  offer?: OfferInterface;
  showConfirmationMessage?: boolean;
  playlistReconciliation?: PlaylistReconciliation;
  displayAddToCartCustomSummary?: boolean;
}

export interface AddToCartRequestAction {
  type: typeof ADD_TO_CART_REQUEST;
  payload: AddToCartPayload;
}

// SUCCESS
interface AddToCartSuccessPayload {
  playlistReconciliation: {
    playlistFingerprint: string;
    playlistReconciliation: string;
    playlistName: { titleLabel?: string; blockClicked?: string };
  };
  products: Product[];
  savedProductIds: ProductId[];
  showConfirmationMessage?: boolean;
  productsTotalPrice?: number;
}
export interface AddToCartSuccessAction {
  type: typeof ADD_TO_CART_SUCCESS;
  payload: AddToCartSuccessPayload;
}

// FAILED
interface AddToCartFailedPayload {
  savedProductIds: ProductId[];
}
export interface AddToCartFailedAction {
  type: typeof ADD_TO_CART_FAILED;
  errors: any;
  payload?: AddToCartFailedPayload;
}

// ACTION: A2C_INIT_PRODUCT_RECOMMENDATION
export interface A2CInitRecommendation {
  type: typeof A2C_INIT_PRODUCT_RECOMMENDATION;
  fetchParams: ProductRecommendationParams;
}

export const initRecommendation = (
  fetchParams: ProductRecommendationParams,
): A2CInitRecommendation => ({
  type: A2C_INIT_PRODUCT_RECOMMENDATION,
  fetchParams,
});

// ACTION: A2C_FETCH_RECOMMENDATION_CONFIG
export interface A2CFetchRecommendationConfig {
  type: typeof A2C_FETCH_RECOMMENDATION_CONFIG;
}

export const a2cFetchRecommendationConfig =
  (): A2CFetchRecommendationConfig => ({
    type: A2C_FETCH_RECOMMENDATION_CONFIG,
  });

// SUCCESS
export interface A2CFetchRecommendationConfigSuccess {
  type: typeof A2C_FETCH_RECOMMENDATION_CONFIG_SUCCESS;
  config: MsRecommendationProcessedResponse;
}

export const a2cFetchRecommendationConfigSuccess = (
  config: MsRecommendationProcessedResponse,
): A2CFetchRecommendationConfigSuccess => ({
  type: A2C_FETCH_RECOMMENDATION_CONFIG_SUCCESS,
  config,
});

// FAILED
export interface A2CFetchRecommendationConfigFailed {
  type: typeof A2C_FETCH_RECOMMENDATION_CONFIG_FAILED;
  error: Error;
}

export const a2cFetchRecommendationConfigFailed = (
  error: Error,
): A2CFetchRecommendationConfigFailed => ({
  type: A2C_FETCH_RECOMMENDATION_CONFIG_FAILED,
  error,
});

// ACTION: A2C_FETCH_PRODUCTS_RECOMMENDATION
export interface A2CFetchProductRecommendation {
  type: typeof A2C_FETCH_PRODUCTS_RECOMMENDATION;
}

export const a2cFetchProductsRecommendation =
  (): A2CFetchProductRecommendation => ({
    type: A2C_FETCH_PRODUCTS_RECOMMENDATION,
  });

// SUCCESS
export interface A2CFetchProductRecommendationSuccess {
  type: typeof A2C_FETCH_PRODUCTS_RECOMMENDATION_SUCCESS;
  recommendations: Recommendations;
}

export const a2cFetchProductsRecommendationSuccess = (
  recommendations: Recommendations,
): A2CFetchProductRecommendationSuccess => ({
  type: A2C_FETCH_PRODUCTS_RECOMMENDATION_SUCCESS,
  recommendations,
});

// FAILED
export interface A2CFetchProductRecommendationFailed {
  type: typeof A2C_FETCH_PRODUCTS_RECOMMENDATION_FAILED;
  error: Error;
}

export const a2cFetchProductsRecommendationFailed = (
  error: Error,
): A2CFetchProductRecommendationFailed => ({
  type: A2C_FETCH_PRODUCTS_RECOMMENDATION_FAILED,
  error,
});

// ACTION: A2C_INIT_CONFIG_PROCESSING
export interface A2CInitConfigProcessing {
  type: typeof A2C_INIT_CONFIG_PROCESSING;
  rawRecommendation: MsRecommendationResponse;
}

export const a2cInitConfigProcessing = (
  rawRecommendation: MsRecommendationResponse,
): A2CInitConfigProcessing => ({
  type: A2C_INIT_CONFIG_PROCESSING,
  rawRecommendation,
});

// SUCCESS
export interface A2CConfigProcessingSuccess {
  type: typeof A2C_CONFIG_PROCESSING_SUCCESS;
  config: MsRecommendationProcessedResponse;
}

export const a2cSetConfigProcessingSuccess = (
  config: MsRecommendationProcessedResponse,
): A2CConfigProcessingSuccess => ({
  type: A2C_CONFIG_PROCESSING_SUCCESS,
  config,
});

// FAILED
export interface A2CConfigProcessingFailed {
  type: typeof A2C_CONFIG_PROCESSING_FAILED;
  error: Error;
}

export const a2cSetConfigProcessingFailed = (
  error: Error,
): A2CConfigProcessingFailed => ({
  type: A2C_CONFIG_PROCESSING_FAILED,
  error,
});

// ACTION: FETCH_RECOMMENDATION
export interface A2CFetchRecommendation {
  type: typeof A2C_FETCH_RECOMMENDATION;
  config: MsRecommendationProcessedResponse;
}

export const a2cFetchRecommendation = (
  config: MsRecommendationProcessedResponse,
): A2CFetchRecommendation => ({
  type: A2C_FETCH_RECOMMENDATION,
  config,
});

export type AddToCartReducerAction =
  | AddToCartRequestAction
  | AddToCartSuccessAction
  | AddToCartFailedAction
  | ShowAddToCartModalAction
  | HideAddToCartModalAction
  | ShowCrossPlatformPurchaseBlockedModalAction
  | HideCrossPlatformPurchaseBlockedModalAction
  | A2CInitRecommendation
  | A2CFetchRecommendationConfig
  | A2CFetchRecommendationConfigSuccess
  | A2CFetchRecommendationConfigFailed
  | A2CFetchProductRecommendation
  | A2CFetchProductRecommendationSuccess
  | A2CFetchProductRecommendationFailed
  | A2CInitConfigProcessing
  | A2CConfigProcessingSuccess
  | A2CConfigProcessingFailed
  | A2CFetchRecommendation;

export const addToCart = (
  payload: AddToCartPayload,
): AddToCartRequestAction => ({
  type: ADD_TO_CART_REQUEST,
  payload,
});

export const hideAddToCartModal = () => ({
  type: HIDE_ADD_T0_CART_MODAL,
});

import dynamic from 'next/dynamic';
import { PropsWithChildren, type FunctionComponent } from 'react';
import { twJoin } from 'tailwind-merge';
import { ErrorBoundary } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/ErrorBoundary/ErrorBoundary';
import { IsolatedInitHook } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/components/IsolatedInitHookProps/IsolatedInitHookProps';
import { Suggestions } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/Suggestions';
import { useSearchSuggestionsVisibility } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchSuggestionsVisibility';
import { SearchForm } from './components/SearchForm/SearchForm';
const DynamicSimilarityProducts = dynamic(() => import('@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/modules/emptySearchResults/components/SimilarityProducts/SimilarityProducts').then(mod => mod.SimilarityProducts));
interface Props {
  className?: string;
}
export const Search: FunctionComponent<Props> = ({
  className
}) => {
  // As part of the INP issue we have decided to stop tracking the search bar
  // const { handleOnFocus } = useSearchFocus({
  //   ref,
  //   availableSuggestions,
  //   setSendTracking,
  // });

  return <ErrorBoundary>
      <IsolatedInitHook />
      <div className={className}>
        <ResultContainer>
          <SearchForm />
          <DynamicSimilarityProducts />

          <Suggestions />
        </ResultContainer>
      </div>
    </ErrorBoundary>;
};
Search.displayName = 'Search';
function ResultContainer({
  children
}: PropsWithChildren) {
  const suggestionsVisible = useSearchSuggestionsVisibility();
  return <div className={twJoin(`relative mx-auto min-h-[44px] desktop:w-full desktop:rounded-[28px]
        desktop:p-xs`, suggestionsVisible && `z-50 desktop:fixed desktop:left-[46%] desktop:max-w-[830px]
          desktop:translate-x-[-50%] desktop:bg-foundation-inverted desktop:p-0`)} tabIndex={0} role="button" id="searchBarContainer">
      {children}
    </div>;
}
import classnames from 'classnames';
import { useCallback, type FC } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Menu as IconMenu } from '@/core/tamagoshi/icons/Menu';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { setMenuVisibility } from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useMenuVisibility } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useMenuVisibility';
import { GTM_CLICK_ON_MENU_BUTTON } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';

import { leonidasMenuButton } from './translations';

import styles from './MenuButton.module.scss';

interface MenuButtonProps {
  className?: string;
}

export const MenuButton: FC<MenuButtonProps> = React.memo(
  ({ className, ...otherProps }) => {
    const dispatch = useDispatch();
    const isMenuVisible = useMenuVisibility();

    const handleOnClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (!isMenuVisible) {
          Gtm.push(GTM_CLICK_ON_MENU_BUTTON());
        }
        dispatch(setMenuVisibility(!isMenuVisible));
      },
      [dispatch, isMenuVisible],
    );

    const dataMenuVisible = Boolean(isMenuVisible).toString();

    return (
      <button
        title={leonidasMenuButton}
        type="button"
        className={classnames(styles.menuButton, className)}
        data-menu-visible={dataMenuVisible}
        data-testid="MM-menu"
        onClick={handleOnClick}
        {...otherProps}
      >
        <IconMenu height={24} width={24} />
        <span>{leonidasMenuButton}</span>
      </button>
    );
  },
);

MenuButton.displayName = 'MenuButton';

import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const RoundFrance: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconFlagRoundFrance"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="11" fill="#FAFAFA" />
    <path
      d="M23.0001 12C23.0001 7.2704 20.015 3.23843 15.8262 1.6842V22.3159C20.015 20.7616 23.0001 16.7296 23.0001 12Z"
      fill="#D80027"
    />
    <path
      d="M1 12C1 16.7296 3.98513 20.7616 8.17393 22.3158V1.6842C3.98513 3.23843 1 7.2704 1 12Z"
      fill="#2E56B1"
    />
  </Svg>
);

RoundFrance.displayName = 'RoundFrance';

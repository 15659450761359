import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { ProductSuggestionStatus } from '../../productSuggestions/interfaces/model';

export const productSuggestionsStatusSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): ProductSuggestionStatus | undefined =>
  state.productDiscovery?.searchBar?.autoComplete?.products?.status;

export const useProductSuggestionsStatus = () =>
  useSelector(productSuggestionsStatusSelector);

import { translate } from '@/domains/core/localization/translate';
export const {
  headerSearchB2C,
  headerSearchB2B,
  headerSearchCommon,
  headerSearchAI
} = {
  headerSearchB2C: translate({
    "label": "Hacer una b\xFAsqueda",
    "placeholder": "Buscar un producto o una marca",
    "placeholderMobile": "Buscar en ManoMano"
  }),
  headerSearchB2B: translate({
    "label": "B\xFAsqueda de productos y marcas.",
    "placeholder": "Busca un producto o una marca",
    "placeholderMobile": "Busca en ManoMano"
  }),
  headerSearchCommon: translate({
    "delete": "Limpiar",
    "magnifyingGlass": "Buscar"
  }),
  headerSearchAI: translate({
    "placeholder": "Que recherchez-vous ?"
  })
};
import { Round as ArrowLeft } from '@manomano-internal/tamagoshi-icons/esm/material/ArrowBackIos';
import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import { leonidasMenuBackText } from './translations';

import styles from './MenuBackButton.module.scss';

interface Props {
  onClick(): void;
  className?: string;
}

export const MenuBackButton: FunctionComponent<Props> = ({
  onClick,
  className,
}) => (
  <button
    title={leonidasMenuBackText}
    type="button"
    className={classnames(styles.menuBackButton, className)}
    onClick={onClick}
  >
    <ArrowLeft />
  </button>
);

MenuBackButton.displayName = 'MenuBackButton';

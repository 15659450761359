export const DEFAULT_ATTRIBUTES_TO_RETRIEVE = [
  'model_id',
  'brand_id',
  'brand_name',
  'prices',
  'title',
  'image_fullpath',
  'brand_image_fullpath',
  'indexable',
  'url',
  'article_id',
  'is_competitive',
  'min_quantity',
  'commercial_animation',
  'commercial_operation_ids',
  'models_count',
  'seller_id',
  'seller_name',
  'categories.last_id',
  'categories.last',
  'is_mmf',
  'is_b2b',
];

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const searchFocusedSuggestionPositionSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): number =>
  state.productDiscovery?.headerLeonidas?.ui.focusedSuggestionPosition || 0;

export const useSearchFocusedSuggestionPosition = () =>
  useSelector(searchFocusedSuggestionPositionSelector);

import { getMarket, isB2B } from '@/core/settings/utils';
import { GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE } from '@/domains/productDiscovery/HeaderLeonidas/constants';
import type { SearchBarTrackingModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/data.model';
import type { TrackingSearchQuery } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/tracking';
import { cleanHtmlTags } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/utils/cleanHtmlTags';

export const GTM_ON_SEARCH_QUERY = (tracking: TrackingSearchQuery) => {
  const {
    searchQuery,
    isRecentSearch,
    isEmptystate,
    itemsCount,
    autocompleteItemLabel,
    autocompleteItemType,
    autocompleteItemPosition,
    hasUsedAutocomplete,
    pageType,
    hasQueryCategory,
  } = tracking;
  const label = autocompleteItemLabel && cleanHtmlTags(autocompleteItemLabel);
  const querySuggestionPrefix = hasQueryCategory
    ? 'categorized_'
    : 'uncategorized_';
  const type =
    autocompleteItemType &&
    `${autocompleteItemType === 'query' ? querySuggestionPrefix : ''}${autocompleteItemType}_suggestion`;
  const position = autocompleteItemPosition;

  return {
    event: 'interaction_search query result',
    e_category: 'navigation',
    e_action: 'Search query result',
    event_trigger: `header ${getMarket()} standard`,
    amp_event_name: 'Search query result',
    is_redirected_from_search: tracking.isRedirectedFromSearch || false,
    search_query: searchQuery,
    search_keywords: searchQuery.split(' '),
    page_type: pageType,
    has_used_autocomplete: hasUsedAutocomplete,

    // Autocomplete
    autocomplete_item_label: (hasUsedAutocomplete && label) || undefined,
    autocomplete_item_type: (hasUsedAutocomplete && type) || undefined,
    autocomplete_item_position: (hasUsedAutocomplete && position) || undefined,
    autocomplete_items_count: (hasUsedAutocomplete && itemsCount) || undefined,

    // Empty state
    emptystate_item_label: (isEmptystate && label) || undefined,
    emptystate_item_type: (isEmptystate && type) || undefined,
    emptystate_item_position: (isEmptystate && position) || undefined,
    emptystate_items_count: (isEmptystate && itemsCount) || undefined,

    // Search history
    recent_search_item_label: (isRecentSearch && label) || undefined,
    recent_search_item_position: (isRecentSearch && position) || undefined,
    is_recent_search: isRecentSearch || false,
  };
};

export const GTM_CLICK_ON_SEARCH_BAR = ({
  searchValue,
  suggestions,
  emptySearchSuggestions,
}: SearchBarTrackingModel = {}) => {
  const recentSearchItemsCount = suggestions?.histories?.length || 0;
  const emptystateItemsCount =
    (!searchValue && suggestions && Object.values(suggestions).flat().length) ||
    0;

  const hasHistoryProductSuggestions =
    emptySearchSuggestions?.topProducts?.type === 'history';

  return {
    event: 'interaction_click on search bar',
    event_trigger: `header ${getMarket()} standard`,
    amp_event_name: 'Click on search bar',
    e_category: 'navigation',
    e_action: 'Click on search bar',
    e_label: `header ${getMarket()} standard`,
    interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
    has_recent_search: !!recentSearchItemsCount,
    recent_search_items_count: recentSearchItemsCount,
    emptystate_items_count: emptystateItemsCount,
    has_product_history: hasHistoryProductSuggestions,
    product_history_count: hasHistoryProductSuggestions
      ? emptySearchSuggestions?.topProducts?.items?.length
      : 0,
  };
};

export const GTM_SEARCH_BAR_RESULTS = ({
  searchValue,
  suggestions,
  hasAFavoriteProduct,
  emptySearchSuggestions,
}: SearchBarTrackingModel) => {
  const recentSearchItemsCount = suggestions?.histories?.length || 0;
  const emptystateItemsCount =
    (!searchValue && suggestions && Object.values(suggestions).flat().length) ||
    0;

  const labelText = searchValue ? 'user types' : 'empty state';
  const label = isB2B() ? labelText : `header ${getMarket()} standard`;

  const hasHistoryProductSuggestions =
    emptySearchSuggestions?.topProducts?.type === 'history';

  return {
    event: 'interaction_search bar results',
    event_trigger: label,
    amp_event_name: 'Search bar results',
    e_category: 'navigation',
    e_action: 'Search bar results',
    e_label: label,
    history_suggestion: suggestions?.histories
      ?.map((sugg) => cleanHtmlTags(sugg.name))
      .join(','),
    query_suggestion: suggestions?.queries
      ?.map((sugg) => cleanHtmlTags(sugg.name))
      .join(','),
    category_suggestion: suggestions?.categories
      ?.map((sugg) => cleanHtmlTags(sugg.name))
      .join(','),
    brand_suggestion: suggestions?.brands
      ?.map((sugg) => cleanHtmlTags(sugg.name))
      .join(','),
    interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
    has_recent_search: !!recentSearchItemsCount,
    recent_search_items_count: recentSearchItemsCount,
    emptystate_items_count: emptystateItemsCount,
    has_a_favorite_product: Boolean(hasAFavoriteProduct),
    has_product_history: hasHistoryProductSuggestions,
    product_history_count: hasHistoryProductSuggestions
      ? emptySearchSuggestions?.topProducts?.items?.length
      : 0,
  };
};

import { SubscribeSource } from '@/domains/retention/UserProfiling/interfaces/Channel';

export const HIDE_NEWSLETTER_MODAL =
  'retention/Newsletter/HIDE_NEWSLETTER_MODAL';
export const HIDE_NEWSLETTER_SUCCESS_MODAL =
  'retention/Newsletter/HIDE_NEWSLETTER_SUCCESS_MODAL';
export const SHOW_NEWSLETTER_MODAL =
  'retention/Newsletter/SHOW_NEWSLETTER_MODAL';
export const SHOW_NEWSLETTER_SUCCESS_MODAL =
  'retention/Newsletter/SHOW_NEWSLETTER_SUCCESS_MODAL';
export const SHOW_INVALID_EMAIL_ERROR =
  'retention/Newsletter/SHOW_INVALID_EMAIL_ERROR';

export const CHANGE_EMAIL_ADDRESS = 'retention/Newsletter/CHANGE_EMAIL_ADDRESS';

export const SUBSCRIBE_NEWSLETTER = 'retention/Newsletter/SUBSCRIBE_NEWSLETTER';
export const SUBSCRIBE_NEWSLETTER_SUCCESS =
  'retention/Newsletter/SUBSCRIBE_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_NEWSLETTER_FAILED =
  'retention/Newsletter/SUBSCRIBE_NEWSLETTER_FAILED';

export interface HideModal {
  type: typeof HIDE_NEWSLETTER_MODAL;
}

export interface HideModalSuccess {
  type: typeof HIDE_NEWSLETTER_SUCCESS_MODAL;
}

export interface ShowModal {
  type: typeof SHOW_NEWSLETTER_MODAL;
}

export interface ShowSuccessModal {
  type: typeof SHOW_NEWSLETTER_SUCCESS_MODAL;
}

export interface ShowError {
  type: typeof SHOW_INVALID_EMAIL_ERROR;
  error: 'invalid' | 'empty';
}

export interface ChangeAddressEmail {
  type: typeof CHANGE_EMAIL_ADDRESS;
  emailAddress: string;
}

export interface SubscribeNewsLetter {
  type: typeof SUBSCRIBE_NEWSLETTER;
  emailAddress: string;
  fromNewsletter: boolean;
  source: SubscribeSource;
}

export interface SubscribeNewsLetterSuccess {
  type: typeof SUBSCRIBE_NEWSLETTER_SUCCESS;
}

export interface SubscribeNewsLetterFailed {
  type: typeof SUBSCRIBE_NEWSLETTER_FAILED;
  error: string;
}

export type Action =
  | HideModal
  | HideModalSuccess
  | ShowError
  | ShowModal
  | ShowSuccessModal
  | SubscribeNewsLetter
  | SubscribeNewsLetterSuccess
  | SubscribeNewsLetterFailed
  | ChangeAddressEmail;

export const showModal = (): ShowModal => ({
  type: SHOW_NEWSLETTER_MODAL,
});

export const hideModal = (): HideModal => ({
  type: HIDE_NEWSLETTER_MODAL,
});

export const hideModalSuccess = (): HideModalSuccess => ({
  type: HIDE_NEWSLETTER_SUCCESS_MODAL,
});

export const changeAddressEmail = (
  emailAddress: string,
): ChangeAddressEmail => ({
  type: CHANGE_EMAIL_ADDRESS,
  emailAddress,
});

export const subscribeNewsletter = (
  emailAddress: string,
  fromNewsletter: boolean,
  source: SubscribeSource,
): SubscribeNewsLetter => ({
  type: SUBSCRIBE_NEWSLETTER,
  emailAddress,
  fromNewsletter,
  source,
});

import { memo, type FunctionComponent } from 'react';

import { Image } from '@/domains/core/Image/Image';

import styles from './HeaderBanner.module.scss';

const SIZES = {
  large: {
    ldpi: {
      width: 204,
      height: 34,
    },
    hdpi: {
      width: 408,
      height: 68,
    },
  },
};

interface Props {
  desktopUrl: string;
  altText: string;
}

const HeaderBannerImg: FunctionComponent<Props> = ({ desktopUrl, altText }) => (
  <Image
    width={SIZES.large.ldpi.width}
    height={SIZES.large.ldpi.height}
    alt={altText || ''}
    data-testid="headerBanner"
    src={`${desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover`}
    srcSet={`${desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover 1x, ${desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover 2x`}
    className={styles.img}
    loading="eager"
  >
    <source
      srcSet={`${desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover&format=webp 1x, ${desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover&format=webp 2x`}
      type="image/webp"
      data-testid="headerBannerDesktop"
    />
  </Image>
);

export default memo(
  HeaderBannerImg,
  (prevProps, nextProps) => prevProps.desktopUrl === nextProps.desktopUrl,
);

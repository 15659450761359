import { useSelector } from 'react-redux';

import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';

export const menuCampaignsSelector = (
  state: SpartacuxProductDiscoveryDomainState,
  categoryId: number,
): BannerCampaign[] | null =>
  state.productDiscovery?.header?.menu?.campaigns
    ? state.productDiscovery?.header?.menu?.campaigns[categoryId]
    : null;

export const useMenuCampaigns = (categoryId: number) =>
  useSelector((state: SpartacuxProductDiscoveryDomainState) =>
    menuCampaignsSelector(state, categoryId),
  );

import { useCallback, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { ArrowBack } from '@/core/tamagoshi/icons/ArrowBack';
import { Link } from '@/domains/core/routing/components/Link';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { MenuCategoryItem } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuCategoryItem/MenuCategoryItem';
import { leonidasMenuSeeAll } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuFamilyItem/translations';
import { MenuFamilyItemButton } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuFamilyItemButton/MenuFamilyItemButton';
import {
  setMenuVisibility,
  setSelectedCategory,
  setSelectedFamily,
} from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useSelectedCategory } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useMenuVisibility';
import {
  GTM_CLICK_ON_MENU_ITEM,
  GTM_CLICK_ON_SEE_ALL_CATEGORIES,
} from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';
import { debounce } from '@/domains/productDiscovery/HeaderLeonidas/utils/debounce';
import type { MenuCategory } from '@/productDiscovery/HeaderLeonidas/interfaces/MenuTree.model';

import styles from '../../Menu.module.scss';

interface Props {
  family: MenuCategory;
  isMenuVisible?: boolean;
  isInDOM?: boolean;
  isVisible?: boolean;
  className?: string;
}

export const MenuFamilyItem: FunctionComponent<Props> = ({
  family,
  isMenuVisible = false,
  isVisible = false,
  isInDOM = false,
}) => {
  const dispatch = useDispatch();
  const selectedCategory = useSelectedCategory();

  const select = debounce(() => {
    const menuItem = {
      categoryName: family.name,
      subCategoryName: '',
      subSubCategoryName: '',
      url: family.url,
      level: 0,
    };
    dispatch(setSelectedFamily(family.id));
    Gtm.push(GTM_CLICK_ON_MENU_ITEM(menuItem));
  }, 20);

  const handleClickSeeAllCategories = () => {
    dispatch(setMenuVisibility(false));
    Gtm.push(GTM_CLICK_ON_SEE_ALL_CATEGORIES(family));
  };

  const handleClickPrevious = () => {
    dispatch(setSelectedFamily(null));
    dispatch(setSelectedCategory(null));
  };

  const handleOver = useCallback(() => {
    dispatch(setSelectedCategory(null));
  }, [dispatch]);

  return (
    <li
      className={styles.menuFamilyItem}
      data-expanded={isVisible ? 'true' : 'false'}
    >
      {isMenuVisible && (
        <MenuFamilyItemButton family={family} select={select} />
      )}
      <div
        className={styles.menuCategories}
        aria-hidden={isVisible ? 'false' : 'true'}
      >
        <div className={styles.menuFamilyBanner} onMouseEnter={handleOver}>
          {isMenuVisible && (
            <button
              className={styles.menuFamilyBannerPrevious}
              onClick={handleClickPrevious}
              data-family-id={family.id}
              data-test-id="previous-item"
              title={`previous-${family.name}`}
              type="button"
            >
              <span className={styles.menuFamilyBannerTitleName}>
                <ArrowBack className={styles.menuFamilyBannerTitleNameArrow} />
                {family.name}
              </span>
            </button>
          )}
          <Link
            className={styles.menuFamilySeeAllLink}
            onClick={handleClickSeeAllCategories}
            href={family.url}
          >
            <span>{isMenuVisible ? leonidasMenuSeeAll : family.name}</span>
          </Link>
        </div>
        <ul className={styles.menuCategoryList}>
          {(isInDOM || isVisible) &&
            family.children?.map((category) => (
              <MenuCategoryItem
                category={category}
                family={family}
                isVisible={category.id === selectedCategory}
                isMenuVisible={isMenuVisible}
                key={category.id}
              />
            ))}
        </ul>
      </div>
    </li>
  );
};

MenuFamilyItem.displayName = 'MenuFamilyItem';

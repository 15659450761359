import classnames from 'classnames';
import React, { type FunctionComponent } from 'react';

import { Skeleton as CustomSkeleton } from '@/core/tamagoshi/Skeleton/Skeleton';
import { Skeleton as ProductsSkeleton } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/Skeleton/Skeleton';

import styles from './SuggestionsSkeleton.module.scss';

interface Props {
  className?: string;
  withProducts?: boolean;
}

export const SuggestionsSkeleton: FunctionComponent<Props> = React.memo(
  ({ className, withProducts = true }) => {
    const rootClassnames = classnames(styles.root, className);

    return (
      <>
        <li className={rootClassnames} data-testid="suggestions-skeleton">
          <CustomSkeleton
            className={classnames(styles.suggestions, {
              [styles.emptyState]: withProducts,
            })}
          />
        </li>
        {withProducts && (
          <li className={styles.productsSuggestions}>
            <CustomSkeleton className={styles.titleSkeleton} />
            <ProductsSkeleton
              className={styles.productsSkeleton}
              withAddToCartButton={false}
            />
          </li>
        )}
      </>
    );
  },
);

SuggestionsSkeleton.displayName = 'SuggestionsSkeleton';

import { queryData } from '@/domains/core/graphqlClient/queryClient';
import { getPlatform, isB2B } from '@/domains/core/settings/utils';
import type { UserSegments } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { MenuCategoryCampaignsDocument } from '@/productDiscovery/CommercialAnimation/services/graphql/BannerCampaigns/generated/bannerCampaigns.query.generated';

export const fetchBannerCampaignsMenuCategoryGql = (
  userSegments: UserSegments,
  categoryId: number,
  familyId: number,
) =>
  queryData(MenuCategoryCampaignsDocument, {
    platform: getPlatform().toUpperCase(),
    market: isB2B() ? 'B2B' : 'B2C',
    userSegments: userSegments || {},
    categoryIds: categoryId && familyId ? [categoryId, familyId] : [],
  }).then((result: any) => result.bannerCampaigns);

import { useSelector } from 'react-redux';

import { searchInputSelector } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/selectors/searchInput';

import { useQuery } from './useQuery';

export const useSearchInput = () => {
  const searchInput = useSelector(searchInputSelector);
  const pageQuery = useQuery();
  return searchInput ?? pageQuery;
};

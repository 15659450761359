import { all, call, put, takeLatest } from 'typed-redux-saga';

import { waitForAndGetFlagValuesSaga } from '@/core/flags/sagas/waitForAndGetFlagValues.saga';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { abTestAndFeatureFlagValidated } from '@/domains/productDiscovery/CommercialAnimation/utils/abTestAndFeatureFlagValidated';
import { getUserSegments } from '@/domains/productDiscovery/CommercialAnimation/utils/getUserSegments';
import { fetchBannerCampaignsMenuCategoryGql } from '@/productDiscovery/HeaderLeonidas/services/fetchBannerCampaignMenuCategory';

import {
  GET_MENU_CAMPAIGNS,
  GET_MENU_CAMPAIGNS_FAILED,
  GET_MENU_CAMPAIGNS_SUCCESS,
  type GetMenuCampaigns,
} from '../Header.actions';

function* bannerCampaignsMenuCategorySaga(
  action: GetMenuCampaigns,
): Generator<any, any, any> {
  try {
    const userSegments = yield* call(getUserSegments);

    const { flags, headerMenuCampaigns } = yield* all({
      flags: call(waitForAndGetFlagValuesSaga),
      headerMenuCampaigns: call(
        fetchBannerCampaignsMenuCategoryGql,
        userSegments,
        action.categoryId,
        action.familyId,
      ),
    });

    const validMenuCampaigns =
      headerMenuCampaigns?.length > 0
        ? headerMenuCampaigns.filter((campaign: BannerCampaign) =>
            abTestAndFeatureFlagValidated(campaign, flags),
          )
        : [];

    yield put({
      type: GET_MENU_CAMPAIGNS_SUCCESS,
      categoryId: action.categoryId,
      campaigns: validMenuCampaigns,
    });
  } catch (error) {
    yield put({
      type: GET_MENU_CAMPAIGNS_FAILED,
      error: error.message,
    });
  }
}

export function* rootSaga(): Generator {
  yield takeLatest(GET_MENU_CAMPAIGNS, bannerCampaignsMenuCategorySaga);
}

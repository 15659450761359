import type { FunctionComponent } from 'react';
import { twJoin } from 'tailwind-merge';

import { Span } from '@/core/tamagoshiTailwind/components/Typography/Typography';
import { ChevronDownIcon } from '@/core/tamagoshiTailwind/icons/system/ChevronDownIcon';
import type { PreHeaderModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';
import { Countdown } from '@/productDiscovery/HeaderLeonidas/components/PreHeader/components/Countdown/Countdown';

interface Props {
  preHeader: PreHeaderModel;
  onClick: () => void;
  className?: string;
}

export const PreHeaderItem: FunctionComponent<Props> = ({
  preHeader,
  onClick,
  className,
}) => {
  return (
    <button
      className={twJoin(
        `cursor-inherit hide-scrollbar mx-auto flex appearance-none
        overflow-auto border-none bg-transparent px-m text-center desktop:px-l
        wide:px-xl`,
        className,
      )}
      onClick={onClick}
      type="button"
      data-testid="preHeader-button"
    >
      <div
        className="m-auto flex items-center"
        data-testid="preHeader-messages"
      >
        {/* eslint-disable react/no-danger */}
        {preHeader.message ? (
          <Span
            scale="body2"
            dangerouslySetInnerHTML={{ __html: preHeader.message }}
          />
        ) : null}
        {preHeader.countdownEndAt && (
          <Countdown countdownEndAt={preHeader.countdownEndAt} />
        )}
        <ChevronDownIcon
          className="ml-xs mt-xs desktop:ml-s"
          data-testid="chevron-down"
        />
      </div>
    </button>
  );
};

PreHeaderItem.displayName = 'RotatingPreHeaderItem';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { SUGGESTION_TYPE } from '../components/Suggestions/constants';
import { AlgoliaQueryDetails } from '../interfaces/algolia';
import type {
  AutoCompleteSuggestions,
  EmptySearchSuggestions,
  Suggestions,
} from '../interfaces/suggestions';
import { selectCurrentSuggestions } from './useCurrentSuggestions';

const suggestionKeys: Record<
  string,
  keyof AutoCompleteSuggestions | keyof EmptySearchSuggestions
> = {
  [SUGGESTION_TYPE.QUERY]: 'queries',
  [SUGGESTION_TYPE.CATEGORY]: 'categories',
  [SUGGESTION_TYPE.BRAND]: 'brands',
  [SUGGESTION_TYPE.PRODUCTS]: 'products',
  [SUGGESTION_TYPE.TOP_SALE_PRODUCTS]: 'topProducts',
};

export const selectSuggestionsTracking = createSelector(
  [selectCurrentSuggestions],
  (currentSuggestions) => {
    if (!currentSuggestions) return undefined;

    const trackingDetails = Object.entries(suggestionKeys).reduce<
      Record<string, AlgoliaQueryDetails | undefined>
    >((acc, [suggestionType, key]) => {
      const details =
        currentSuggestions[key as keyof Suggestions]?.queryDetails;
      acc[suggestionType] = details;
      return acc;
    }, {});

    return Object.keys(trackingDetails).length > 0
      ? trackingDetails
      : undefined;
  },
);

export const useSuggestionsTracking = () =>
  useSelector(selectSuggestionsTracking);

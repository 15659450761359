import { getPlatform, isFrancePlatform } from '@/domains/core/settings/utils';
import { Suggestion } from '@/productDiscovery/HeaderLeonidas/interfaces/Header.search.model';

import searchAIQueries from '../data/searchAIQueries.json';

export const useSearchAIQueries = (): Suggestion[] => {
  // NOTE Display Search AI module only for FR platform
  const allowPlatform = isFrancePlatform();
  const platform = getPlatform().toUpperCase();

  if (allowPlatform) {
    return searchAIQueries[
      platform as keyof typeof searchAIQueries
    ] as Suggestion[];
  }

  return [];
};

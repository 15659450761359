import { useRouter } from 'next/router';
import type React from 'react';
import { useRef, useState, type ChangeEvent } from 'react';
import { twJoin } from 'tailwind-merge';

import { isB2B } from '@/core/settings/utils';

import { headerSearchB2B, headerSearchB2C } from '../SearchForm/translations';
import { SearchFormControls } from '../SearchFormControls/SearchFormControls';

export const SearchFallback = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const { push } = useRouter();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setValue(target.value);

  const handleResetSearch = () => setValue('');

  const handleSubmit = async (
    event: React.SyntheticEvent | React.KeyboardEvent,
  ) => {
    event.preventDefault();
    if (value !== '') {
      const { createRedirectionURL } = await import(
        '@/productDiscovery/HeaderLeonidas/modules/searchBar/utils/createRedirectionURL/createRedirectionURL'
      );
      const { url } = createRedirectionURL(value);
      push(url);
    }
  };

  const translations = isB2B() ? headerSearchB2B : headerSearchB2C;

  return (
    <form
      className={twJoin(
        `relative col-[1/-1] mx-m my-xs block w-[calc(100vw-32px)]
        [grid-area:search] desktop:m-xs desktop:my-auto desktop:w-full`,
        isB2B() && 'm-auto',
      )}
      onSubmit={handleSubmit}
      data-testid="search-bar-fallback"
      action="."
    >
      <input
        className="relative flex h-[44px] w-full flex-1 appearance-none truncate
          rounded-[22px] bg-foundation-inverted pl-m pr-[44px]
          placeholder:text-foundation-secondary focus:outline-none
          desktop:border desktop:border-solid"
        placeholder={translations.placeholder}
        aria-label={translations.label}
        type="search"
        ref={inputRef}
        value={value}
        onChange={handleChange}
      />
      <SearchFormControls hasValue={value !== ''} onReset={handleResetSearch} />
    </form>
  );
};

SearchFallback.displayName = 'SearchFallback';

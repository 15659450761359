import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';

export const menuVisibilitySelector = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean | undefined =>
  state.productDiscovery?.headerLeonidas?.ui.menuVisible;

export const useMenuVisibility = () => useSelector(menuVisibilitySelector);

export const selectedFamilySelector = (
  state: SpartacuxProductDiscoveryDomainState,
): number | undefined =>
  state.productDiscovery?.headerLeonidas?.ui.selectedFamily;

export const useSelectedFamily = () => useSelector(selectedFamilySelector);

export const selectedCategorySelector = (
  state: SpartacuxProductDiscoveryDomainState,
): number | undefined =>
  state.productDiscovery?.headerLeonidas?.ui.selectedCategory;

export const useSelectedCategory = () => useSelector(selectedCategorySelector);

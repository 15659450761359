import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiIcon } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const SearchIcon = makeTamagoshiIcon((props, ref) => (
  <Svg
    optimizationId="tama-search"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4.1C7.18926 4.1 4.10002 7.18924 4.10002 11C4.10002 14.8108 7.18926 17.9 11 17.9C14.8108 17.9 17.9 14.8108 17.9 11C17.9 7.18924 14.8108 4.1 11 4.1ZM1.90002 11C1.90002 5.97421 5.97423 1.9 11 1.9C16.0258 1.9 20.1 5.97421 20.1 11C20.1 16.0258 16.0258 20.1 11 20.1C5.97423 20.1 1.90002 16.0258 1.90002 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8722 15.8722C16.3018 15.4426 16.9983 15.4426 17.4279 15.8722L21.7779 20.2222C22.2074 20.6518 22.2074 21.3482 21.7779 21.7778C21.3483 22.2074 20.6518 22.2074 20.2222 21.7778L15.8722 17.4278C15.4427 16.9982 15.4427 16.3018 15.8722 15.8722Z"
      fill="currentColor"
    />
  </Svg>
));

SearchIcon.displayName = 'SearchIcon';

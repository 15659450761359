import { Component, type ReactNode } from 'react';

import { loadSentry } from '@/domains/core/observability/loadSentry';
import logger from '@/domains/core/observability/logger';

import { SearchFallback } from '../Search/components/SearchFallback/SearchFallback';

interface Props {
  container?: string;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    loadSentry()
      .then((Sentry) => {
        Sentry.withScope((scope) => {
          scope.setTag('service', 'Spartacux');
          scope.setTag('domain', 'productDiscovery/HeaderLeonidas/SearchBar');
          Sentry.captureException(error);
        });
      })
      .catch(() => logger.error(error));
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <SearchFallback />;
    }
    return children;
  }
}

import { testPixel } from '@/sellerAdsManagement/adBlockers/testPixel';

const testPixels = (): Promise<boolean> => {
  const p1 = testPixel(1);
  const p2 = testPixel(2);

  return Promise.all([p1, p2])
    .then((values) => values[0] && !values[1])
    .catch(() => false);
};

export const detectUnblocked = (() => {
  let userIsUnblocked = false;
  let hasMemo = false;

  return async (force = false) => {
    if (!hasMemo || force) {
      userIsUnblocked = await testPixels();
      hasMemo = true;
    }

    return userIsUnblocked;
  };
})();

export const nameToSlug = (string: string, delimiter = '-') =>
  string
    .toString()
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\s\w-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, delimiter) // swap any length of whitespace, underscore, hyphen characters with the delimiter
    .replace(/^-+|-+$/g, ''); // remove leading, trailing -

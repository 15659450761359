import { translate } from '@/core/localization/translate';
export const {
  footerLegalCompanyInformation,
  footerLegalCompanyAddress,
  footerLegalCompanyDetailsDescription,
  footerLegalCompanyDetailsTitle,
  footerLegalConsumptionInformation,
  footerLegalConsumptionInformationB2B,
  footerLegalConsumptionInformationHref,
  footerLegalConsumptionInformationHrefB2B,
  footerLegalManageCookies,
  footerLegalMentions,
  footerLegalMentionsHref,
  footerLegalMentionsHrefB2B,
  footerLegalProtection,
  footerLegalProtectionHref,
  footerLegalReports,
  footerLegalReportsHref,
  footerLegalTerms,
  footerLegalTermsHref,
  footerLegalTermsHrefB2B,
  footerLegalTrustCenter,
  footerLegalTrustCenterHref,
  footerSitemap,
  footerSitemapHref
} = translate({
  "footerLegalCompanyInformation": "footer.legal.company.information",
  "footerLegalCompanyAddress": "footer.company.address",
  "footerLegalCompanyDetailsDescription": "footer.company.details.description",
  "footerLegalCompanyDetailsTitle": "footer.company.details.title",
  "footerLegalConsumptionInformation": "Informacion consumidores",
  "footerLegalConsumptionInformationB2B": "Bewertungen auf der Plattform",
  "footerLegalConsumptionInformationHref": "https://cdn.manomano.com/legal/consumer-information/es.pdf",
  "footerLegalConsumptionInformationHrefB2B": "https://cdn.manomano.com/legal/consumer-information/es.pdf",
  "footerLegalManageCookies": "Configurar mis cookies",
  "footerLegalMentions": "Menciones legales",
  "footerLegalMentionsHref": "https://cdn.manomano.com/legal/legal-notice/es.pdf",
  "footerLegalMentionsHrefB2B": "https://cdn.manomano.com/legal/legal-notice/es.pdf",
  "footerLegalProtection": "Pol\xEDtica de protecci\xF3n de datos",
  "footerLegalProtectionHref": "https://cdn.manomano.com/legal/data-protection/es.pdf",
  "footerLegalTrustCenter": "Trust Center",
  "footerLegalTrustCenterHref": "https://trust.manomano.com/",
  "footerLegalReports": "Informes",
  "footerLegalReportsHref": "/lp/informes-de-transparencia-es-3227",
  "footerLegalTerms": "Condiciones generales de uso",
  "footerLegalTermsHref": "https://cdn.manomano.com/legal/terms-of-service/es.pdf",
  "footerLegalTermsHrefB2B": "https://cdn.manomano.com/legal/terms-of-service/es.pdf",
  "footerSitemap": "Plan del sitio",
  "footerSitemapHref": "/plan-del-sitio"
});
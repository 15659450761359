import { useMemo, type FunctionComponent } from 'react';

import { routes } from '@/domains/core/routing/routes/routes.default';
import { createURL } from '@/domains/core/routing/URLParser';
import { NavigationButton } from '@/domains/core/tamagoshiv2/components/Button/NavigationButton';
import { useSearchInput } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchInput';
import { nameToSlug } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/utils/nameToSlug';

export interface SeeMoreButtonProps {
  className?: string;
  onClick?: () => void;
  label: string;
}

export const SeeMoreButton: FunctionComponent<SeeMoreButtonProps> = ({
  className,
  onClick,
  label,
}) => {
  const inputValue = useSearchInput();

  const url = useMemo(
    () =>
      createURL(routes.search, {
        query: nameToSlug(inputValue, '+'),
      }),
    [inputValue],
  );

  return (
    <NavigationButton
      href={url}
      className={className}
      onClick={onClick}
      content={label}
      width="fillContainer"
    />
  );
};

SeeMoreButton.displayName = 'SeeMoreButton';

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { EmptySearchSuggestions } from '../interfaces/suggestions';

export const suggestionsEmptySearchSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): EmptySearchSuggestions | undefined =>
  state.productDiscovery?.searchBar?.emptySearch;

export const useSuggestionsEmptySearch = () =>
  useSelector(suggestionsEmptySearchSelector);

import { takeLatest } from 'typed-redux-saga';

import { ADD_TO_CART_SUCCESS } from '@/domains/shoppingCart/AddToCart/AddToCart.actions';
import { cartOverviewUpdate } from '@/shoppingCart/CartOverview/sagas/CartOverviewUpdate.saga';
import { ASIDE_CART_RETRIEVAL_SUCCESS } from '@/shoppingCart/CartPage/AsideCart/actions/AsideCartRetrieval.actions';
import { MOVE_ITEM_TO_MAIN_SUCCESS } from '@/shoppingCart/CartPage/AsideCart/actions/MoveItemToMain.actions';
import { MAIN_CART_ITEM_ADDITION_SUCCESS } from '@/shoppingCart/CartPage/MainCart/actions/MainCartItemAddition.actions';
import { MAIN_CART_ITEM_DELETION_SUCCESS } from '@/shoppingCart/CartPage/MainCart/actions/MainCartItemDeletion.actions';
import { MAIN_CART_ITEM_EDITION_SUCCESS } from '@/shoppingCart/CartPage/MainCart/actions/MainCartItemEdition.actions';
import { MAIN_CART_RETRIEVAL_SUCCESS } from '@/shoppingCart/CartPage/MainCart/actions/MainCartRetrieval.actions.default';
import { MOVE_ITEM_TO_ASIDE_SUCCESS } from '@/shoppingCart/CartPage/MainCart/actions/MoveItemToAside.actions';

export function* watchCartOverviewUpdate() {
  yield* takeLatest(
    [
      ADD_TO_CART_SUCCESS,
      MAIN_CART_ITEM_ADDITION_SUCCESS,
      MAIN_CART_ITEM_DELETION_SUCCESS,
      MAIN_CART_ITEM_EDITION_SUCCESS,
      ASIDE_CART_RETRIEVAL_SUCCESS,
      MAIN_CART_RETRIEVAL_SUCCESS,
      MOVE_ITEM_TO_ASIDE_SUCCESS,
      MOVE_ITEM_TO_MAIN_SUCCESS,
    ],
    cartOverviewUpdate,
  );
}

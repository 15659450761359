import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { pageTypeSelector } from '@/domains/core/page/page.selectors';
import { getMarket, getPlatform } from '@/domains/core/settings/utils';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';
import { useDetail } from '@/domains/productDiscovery/ProductPage/hooks/useDetail';

const allowedPageTypes = [
  'my_orders',
  'order_selection',
  'product',
  'shipment_details',
  'website_use',
  'cart',
  'category_listing',
  'search',
  'model_listing',
] as const;
type PageType = (typeof allowedPageTypes)[number];

function isPageAllowed(
  pageType: PageType,
  customerValue: string,
  price: number,
) {
  const market = getMarket();
  const platform = getPlatform();
  switch (pageType) {
    case 'product':
      return (
        market === 'b2b' ||
        ['es', 'gb', 'de'].includes(platform) ||
        price > 30 ||
        customerValue === 'HIGH' ||
        customerValue === 'VERY_HIGH'
      );
    case 'website_use':
      return platform === 'de' ? false : new Date().getUTCDay() !== 0;
    case 'model_listing':
    case 'cart':
    case 'search':
    case 'category_listing':
      return true;
    case 'my_orders':
    case 'shipment_details':
    case 'order_selection':
      return platform !== 'de';
    default:
      pageType satisfies never;
  }
  return false;
}
export const useDisplayZendeskChat = (): boolean => {
  const [isChatActivated] = useBooleanFlags(['zendeskBotIntegration']);
  const { customer_value } = useAuth();
  const { price } = useDetail();
  const pageType = useSelector(pageTypeSelector) as PageType;
  const displayChat =
    isChatActivated &&
    isPageAllowed(
      pageType,
      customer_value || '',
      price?.primaryPrice?.amountWithVat ?? 0,
    );

  useEffect(() => {
    if (displayChat) {
      window.zE?.('messenger', 'show');
    } else {
      window.zE?.('messenger', 'hide');
    }
  }, [displayChat]);

  return displayChat;
};

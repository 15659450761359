import type { FunctionComponent } from 'react';

import { CrossIcon } from '@/core/tamagoshiTailwind/icons/system/CrossIcon';

import { removeText } from './translations';

interface Props {
  onClick: () => void;
  dataTestId?: string;
}

export const RemoveButton: FunctionComponent<Props> = ({
  onClick,
  dataTestId,
}) => (
  <button
    title={removeText}
    type="button"
    onClick={onClick}
    data-testid={dataTestId}
    className="p-xs pb-s"
  >
    <CrossIcon className="size-m hover:text-accent-primary" />
  </button>
);

RemoveButton.displayName = 'RemoveButton';

import { useRouter } from 'next/router';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type FunctionComponent,
} from 'react';
import LazyHydrate from 'react-lazy-hydration';
import { useDispatch, useSelector } from 'react-redux';

import { isB2B } from '@/domains/core/settings/utils';
import { useCategoryHubBreadcrumb } from '@/domains/productDiscovery/CategoryHub/hooks/categoryHubBreadcrumb';
import { MenuBackButton } from '@/domains/productDiscovery/HeaderLeonidas/b2c/components/MenuBackButton/MenuBackButton';
import { AdviceMenuItem } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/AdviceMenuItem/AdviceMenuItem';
import { MenuFamilyItem } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuFamilyItem/MenuFamilyItem';
import { MenuSeparator } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuSeparator/MenuSeparator';
import { MenuCloseButton } from '@/domains/productDiscovery/HeaderLeonidas/components/MenuCloseButton/MenuCloseButton';
import {
  setMenuVisibility,
  setSelectedCategory,
  setSelectedFamily,
} from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useLazyHeader } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useLazyHeader';
import {
  useMenuVisibility,
  useSelectedCategory,
  useSelectedFamily,
} from '@/domains/productDiscovery/HeaderLeonidas/hooks/useMenuVisibility';
import { useOnClickOutside } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useOnClickOutside';
import { useGetOverlay } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useOverlay';
import { OVERLAY_MENU } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.model';
import { selectMenuCategories } from '@/domains/productDiscovery/HeaderLeonidas/selectors/selectors';
import { extractFamilyID } from '@/domains/productDiscovery/HeaderLeonidas/utils/extractFamilyID';
import { isMobile } from '@/domains/productDiscovery/HeaderLeonidas/utils/isMobile';
import { useCategoryMetadata } from '@/domains/productDiscovery/Listings/pages/Category/hooks/category';
import { useMassLandingMetadata } from '@/domains/productDiscovery/Listings/pages/MassLanding/hooks/massLanding';
import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';
import { useDetail } from '@/domains/productDiscovery/ProductPage/hooks/useDetail';
import type { MenuCategory } from '@/productDiscovery/HeaderLeonidas/interfaces/MenuTree.model';

import {
  leonidasMenuExploreCatalogue,
  leonidasMenuLinksTitle,
  leonidasMenuProductsTitle,
} from './translations';

import styles from './Menu.module.scss';

export const Menu: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isMenuVisible = useMenuVisibility();
  const selectedFamily = useSelectedFamily();
  const selectedCategory = useSelectedCategory();
  const menuFamiliesRef = useRef(null);
  const getOverlay = useGetOverlay();

  const [promise, setPromise] = useState(new Promise(() => {}));

  const categories = useSelector<
    SpartacuxProductDiscoveryDomainState,
    MenuCategory[] | undefined
  >(selectMenuCategories);

  useLazyHeader(isMenuVisible || false);

  useEffect(() => {
    if (isMenuVisible) {
      setPromise(Promise.resolve());
    }
  }, [isMenuVisible]);

  const hideMenu = useCallback(() => {
    if (getOverlay() === OVERLAY_MENU && !isMobile()) {
      dispatch(setMenuVisibility(false));
    }
  }, [dispatch, getOverlay]);

  useOnClickOutside(menuFamiliesRef, hideMenu, isMenuVisible === true);

  /* Menu DOM Siloing */

  const router = useRouter();
  const productMetadata = useDetail();
  const categoryHubBreadcrumb = useCategoryHubBreadcrumb();
  const categoryMetadata = useCategoryMetadata();
  const massLandingMetadata = useMassLandingMetadata();

  const isFamilyExpandedInDOM = useCallback(
    (family: any) => {
      if (router.route === '/homepage-b2c') {
        return !!family.children;
      }

      if (router.route === '/product') {
        return productMetadata?.breadcrumbs?.length > 0
          ? productMetadata?.breadcrumbs?.[0].id === Number(family.id)
          : false;
      }

      if (router.route === '/category-hub') {
        return categoryHubBreadcrumb && categoryHubBreadcrumb?.length > 0
          ? categoryHubBreadcrumb?.[1].id === Number(family.id)
          : false;
      }

      if (router.route === '/category') {
        return categoryMetadata?.breadcrumb &&
          categoryMetadata?.breadcrumb?.length > 0
          ? categoryMetadata?.breadcrumb?.[1].id === Number(family.id)
          : false;
      }

      if (router.route === '/mass-landing') {
        if (
          massLandingMetadata?.breadcrumb &&
          massLandingMetadata?.breadcrumb?.length > 0
        ) {
          const familyID = extractFamilyID(
            massLandingMetadata?.breadcrumb?.[1].href,
          );
          return Number(familyID) === Number(family.id);
        }
        return false;
      }

      return false;
    },
    [
      router.route,
      productMetadata?.breadcrumbs,
      categoryHubBreadcrumb,
      categoryMetadata?.breadcrumb,
      massLandingMetadata?.breadcrumb,
    ],
  );

  /**
   * Close menu on ESC
   */
  useEffect(() => {
    if (window) {
      const listen = (e: KeyboardEvent) => {
        if (isMenuVisible && e.key === 'Escape') {
          dispatch(setMenuVisibility(false));
        }
      };
      window.addEventListener('keyup', listen);

      return () => window.removeEventListener('keyup', listen);
    }
  }, [isMenuVisible, dispatch]);

  return (
    <LazyHydrate
      className={styles.container}
      data-visible={isMenuVisible ? 'true' : 'false'}
      data-family-expanded={selectedFamily ? 'true' : 'false'}
      data-category-expanded={selectedCategory ? 'true' : 'false'}
      promise={promise}
      style={{}}
    >
      <>
        {isMenuVisible && (
          <div className={styles.header}>
            {!!selectedFamily && (
              <MenuBackButton
                onClick={() => {
                  dispatch(setSelectedFamily(null));
                  dispatch(setSelectedCategory(null));
                }}
                className={styles.headerBackButton}
              />
            )}
            <span className={styles.headerTitle}>
              {leonidasMenuExploreCatalogue}
            </span>
            <MenuCloseButton
              onClick={() => dispatch(setMenuVisibility(false))}
              className={styles.headerCloseButton}
            />
          </div>
        )}
        <nav className={styles.body}>
          <ul className={styles.menuFamilies} ref={menuFamiliesRef}>
            {!isB2B() && (
              <>
                <MenuSeparator className={styles.linksTitle}>
                  {leonidasMenuLinksTitle}
                </MenuSeparator>
                <AdviceMenuItem />
              </>
            )}
            {categories && !isB2B() && (
              <MenuSeparator className={styles.categoriesTitle}>
                {leonidasMenuProductsTitle}
              </MenuSeparator>
            )}
            {categories?.map((family) => (
              <MenuFamilyItem
                family={family}
                isMenuVisible={!!isMenuVisible}
                isInDOM={isFamilyExpandedInDOM(family)}
                isVisible={selectedFamily === family.id}
                key={family.id}
              />
            ))}
          </ul>
        </nav>
      </>
    </LazyHydrate>
  );
};

Menu.displayName = 'Menu';

// Enum of string ids for new Feature Flags
export enum StringFeatureFlags {
  RERANKING_SEARCH = 'relevanceRerankingSearch',
}

// Enum of string ids for new AB Tests Flags
export enum StringABTests {
  AUTOSELECT_BEST_CATEGORY = 'autoselectBestCategory',
  DS_RESULTS_ONLY = 'dsResultsOnly',
  SEARCH_AI = 'searchAI',
  HVC_RERANKING = 'hvcReranking',
}

import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { MessageFormatterReturnType } from '@/domains/core/localization/translate';
import styles from './Title.module.scss';
interface TitleProps {
  className?: string;
  label: string;
  link?: MessageFormatterReturnType;
}
export const Title: FunctionComponent<TitleProps> = ({
  className,
  link,
  label
}) => {
  const rootClassnames = classnames(styles.root, {
    [styles.withLink]: !!link
  }, className);
  return <div className={rootClassnames}>
      <span className={styles.title}>{label}</span>
      {link}
    </div>;
};
Title.displayName = 'Title';
import { SafeStorage } from '@/domains/core/data/utils/SafeStorage/SafeStorage';
import {
  LOCAL_STORAGE_NEWSLETTER_DISPLAYED_TIMESTAMP,
  LOCAL_STORAGE_NEWSLETTER_SUBSCRIBE_KEY,
  NUMBER_OF_DAYS_BEFORE_DISPLAYING_AGAIN_NEWSLETTER_MODAL,
  NUMBER_OF_PAGES_VIEWED_TO_DISPLAY_NEWSLETTER_MODAL,
  SESSION_STORAGE_NEWSLETTER_NB_PAGES_VIEWED_KEY,
} from '@/domains/retention/NewsletterModal/constants';

export const automaticModalTrigger = () => {
  const safeSessionStorage = SafeStorage.getSessionStorage();
  const isThirdPageCurrentlyViewInThisSession =
    parseInt(
      safeSessionStorage.getItem(
        SESSION_STORAGE_NEWSLETTER_NB_PAGES_VIEWED_KEY,
      ) || '0',
      10,
    ) >= NUMBER_OF_PAGES_VIEWED_TO_DISPLAY_NEWSLETTER_MODAL;

  const safeLocalStorage = SafeStorage.getLocalStorage();
  const timestamp = safeLocalStorage.getItem(
    LOCAL_STORAGE_NEWSLETTER_DISPLAYED_TIMESTAMP,
  );
  const isModalNotSeenInLastThirtyDays = timestamp
    ? Date.now() - parseInt(timestamp, 10) >
      NUMBER_OF_DAYS_BEFORE_DISPLAYING_AGAIN_NEWSLETTER_MODAL
    : true;

  const hasUserNotAlreadySubscribed =
    safeLocalStorage.getItem(LOCAL_STORAGE_NEWSLETTER_SUBSCRIBE_KEY) === null;

  return (
    isThirdPageCurrentlyViewInThisSession &&
    isModalNotSeenInLastThirtyDays &&
    hasUserNotAlreadySubscribed
  );
};

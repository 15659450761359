import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import { Cross } from '@/core/tamagoshi/icons/Cross';

import { leonidasMenuCloseText } from './translations';

import styles from './MenuCloseButton.module.scss';

interface Props {
  onClick(): void;
  className?: string;
}

export const MenuCloseButton: FunctionComponent<Props> = ({
  onClick,
  className,
}) => (
  <button
    title={leonidasMenuCloseText}
    type="button"
    className={classnames(styles.menuCloseButton, className)}
    onClick={onClick}
  >
    <Cross />
  </button>
);

MenuCloseButton.displayName = 'MenuCloseButton';

export const LOCAL_STORAGE_NEWSLETTER_SUBSCRIBE_KEY = 'newsletter_subscribed';
export const LOCAL_STORAGE_NEWSLETTER_DISPLAYED_TIMESTAMP =
  'newsletter_displayed_timestamp';
export const SESSION_STORAGE_NEWSLETTER_NB_PAGES_VIEWED_KEY =
  'newsletter_nb_pages_viewed';
export const NUMBER_OF_PAGES_VIEWED_TO_DISPLAY_NEWSLETTER_MODAL = 3;
const THIRTY_DAYS = 30 * 24 * 3600 * 1000;
export const NUMBER_OF_DAYS_BEFORE_DISPLAYING_AGAIN_NEWSLETTER_MODAL =
  THIRTY_DAYS;
export const FF_CUSTOMER_ACTIVATION_INCENTIVE_NEWSLETTER_COUPON_MODAL = 1335;

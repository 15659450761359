export enum SUGGESTION_TYPE {
  HISTORY = 'history',
  QUERY = 'query',
  BRAND = 'brand',
  CATEGORY = 'category',
  PRODUCTS = 'products',
  TOP_SALE_PRODUCTS = 'topSaleProducts',
}

export const SUGGESTIONS_WITH_QUERY_CATEGORY_INDEXES = [1, 2, 3];

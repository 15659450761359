import type { ComponentType, FunctionComponent, ReactElement } from 'react';

type ImageAs<P> = ComponentType<JSX.IntrinsicElements['img'] & Omit<P, 'as'>>;

export type ImageAsImageProps = ImageAs<ImageProps>;

export interface ImageProps {
  src: string;
  width: number | string;
  height: number | string;
  alt: string;
  as?: ImageAsImageProps;
  className?: string;
  loading?: 'eager' | 'lazy';
  children?: ReactElement | ReactElement[];
}

export const Image: FunctionComponent<ImageProps> = ({
  className,
  as: Component = 'img',
  src,
  width,
  height,
  alt,
  loading = 'eager',
  ...otherProps
}) => {
  const { children, ...propsWithoutChildren } = otherProps;

  return (
    <Component
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
      data-src={loading === 'lazy' ? src : undefined}
      {...propsWithoutChildren}
    />
  );
};

Image.displayName = 'Image';

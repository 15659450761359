// Algolia suggestions indexes
export const AlgoliaQueriesIndexes = {
  emptySearch: {
    QUERY_SUGGESTIONS_INDEX: 0,
    CATEGORY_SUGGESTIONS_INDEX: 1,
    BRAND_SUGGESTIONS_INDEX: 2,
    TOP_SALE_PRODUCTS_SUGGESTIONS_INDEX: 3,
  },
  autoComplete: {
    QUERY_SUGGESTIONS_INDEX: 0,
    CATEGORY_SUGGESTIONS_INDEX: 1,
    BRAND_SUGGESTIONS_INDEX: 2,
  },
};

// Rules for displaying suggestions
export const EMPTYSEARCH_NB_QUERIES_WITH_HISTORY = 4;
export const EMPTYSEARCH_NB_QUERIES_WITHOUT_HISTORY = 5;
export const EMPTYSEARCH_NB_SUGGESTIONS_WITH_HISTORY = 3;
export const EMPTYSEARCH_NB_SUGGESTIONS_WITHOUT_HISTORY = 4;
export const AUTOCOMPLETE_NB_SUGGESTIONS_QUERIES = 12;
export const AUTOCOMPLETE_NB_SUGGESTIONS_CATEGORIES = 3;
export const AUTOCOMPLETE_NB_SUGGESTIONS_BRANDS = 7;

// Products suggestion
export const PRODUCTS_IN_SEARCHBAR_SLIDER = 6;
export const PRODUCTS_IN_SEARCHBAR_SLIDER_WITH_SIMILARITY = 3;
export const MIN_LAST_SEEN_PRODUCTS_WITH_SIMILARITY = 3;
export const NB_LAST_SEEN_PRODUCTS_WITH_SIMILARITY = 6;

export const PRODUCTS_IN_SEARCHBAR_RECO_ENGINE = 'SimilarityML_v2';

// Number of top suggestions to fetch from Algolia (FF 596)
export const NB_RANDOM_QUERIES_TO_FETCH = 50;

import interactionResponse from 'await-interaction-response';
import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { useOverlay } from '../../hooks/useOverlay';
import { useScrollFreezeWhenOverlay } from '../../hooks/useScrollFreezeWhenOverlay';
import {
  OVERLAY_NONE,
  OVERLAY_SEARCH,
} from '../../interfaces/HeaderLeonidas.model';
import { setSearchSuggestionsVisibility } from '../../modules/searchBar/HeaderLeonidas.searchBar.actions';

import styles from './Overlay.module.scss';

interface OverlayProps {
  className?: string;
}

export const Overlay: FunctionComponent<OverlayProps> = ({ className }) => {
  useScrollFreezeWhenOverlay();
  const overlay = useOverlay();
  const dispatch = useDispatch();

  const hideOverlay = async () => {
    if (overlay === OVERLAY_SEARCH) {
      await interactionResponse();
      dispatch(setSearchSuggestionsVisibility(false));
    }
  };

  const onTouchStart = () => {
    hideOverlay();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document?.activeElement?.blur();
  };

  const rootClassnames = classnames(styles.overlay, className);
  return (
    (overlay !== OVERLAY_NONE && (
      <div
        className={rootClassnames}
        role="presentation"
        data-overlay={overlay}
        onClick={hideOverlay}
        onTouchStart={onTouchStart}
      />
    )) ||
    null
  );
};

Overlay.displayName = 'Overlay';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLazyReducer } from '@/domains/core/applicationState/hooks/useLazyReducer';
import { useLazySaga } from '@/domains/core/applicationState/hooks/useLazySaga';
import {
  getAutoCompleteSuggestions,
  getEmptySearchSuggestions,
} from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.actions';
import { useSearchInput } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchInput';
import { useSearchSuggestionsVisibility } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchSuggestionsVisibility';

const headerSearchSaga = () =>
  import(
    '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.saga'
  ).then((mod) => mod.headerSearchSaga);
const headerSearchReducer = () =>
  import(
    '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.reducer'
  ).then((mod) => mod.default);
const productsSearchSuggestion = () =>
  import(
    '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/modules/searchResults/saga'
  ).then((mod) => mod.default);

/**
 * This component is a trick to isolate the init hook from the rest of the
 * component to not rerender Search component.
 *
 * By isolating the init hook, we can make sure that the saga and reducer are
 * injected before dispatching the first action.
 * @returns null
 */
export function IsolatedInitHook() {
  const suggestionsVisible = useSearchSuggestionsVisibility();
  const [isIdle, setIdle] = useState(false);
  const inputValue = useSearchInput();

  useEffect(() => {
    requestIdleCallback(() => setIdle(true), { timeout: 1000 });
  }, []);

  const suggestions = useLazySaga(
    'productDiscovery.productsSuggestion',
    productsSearchSuggestion,
    {
      condition: isIdle,
    },
  );

  const reducer = useLazyReducer(
    'productDiscovery.searchBar',
    headerSearchReducer,
    {
      condition: isIdle,
    },
  );
  const searchBar = useLazySaga(
    'productDiscovery.searchBar',
    headerSearchSaga,
    {
      condition: isIdle,
    },
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      suggestions.sagaInjected &&
      reducer.reducerInjected &&
      searchBar.sagaInjected
    ) {
      if (suggestionsVisible) {
        if (inputValue) {
          dispatch(getAutoCompleteSuggestions(inputValue));
        } else {
          dispatch(getEmptySearchSuggestions());
        }
      }
    }
  }, [
    dispatch,
    suggestions.sagaInjected,
    reducer.reducerInjected,
    searchBar.sagaInjected,
    inputValue,
    suggestionsVisible,
  ]);

  return null;
}

import type { FunctionComponent } from 'react';

import type { ProductSuggestionType } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/model';

import type { Product } from '../../../../interfaces/product';
import { HighlightedProducts } from '../HighlightedProducts/HighlightedProducts';

export interface MultipleProductsListsWrapperProps {
  className?: string;
  tag?: React.ComponentType | keyof JSX.IntrinsicElements;
  productsList: {
    products: Product[];
    engineName?: string;
    type?: ProductSuggestionType;
  }[];
}

export const MultipleProductsListsWrapper: FunctionComponent<
  MultipleProductsListsWrapperProps
> = ({ className, tag: Tag = 'div', productsList }) => (
  <Tag className={className}>
    {productsList.map((list, index) => (
      <HighlightedProducts
        products={list.products}
        engineName={list.engineName}
        type={list.type}
        position={index}
        // eslint-disable-next-line react/no-array-index-key
        key={`${list.type || 'products-list'}-${index}`}
      />
    ))}
  </Tag>
);

MultipleProductsListsWrapper.displayName = 'MultipleProductsListsWrapper';

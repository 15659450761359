/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { routes } from '@/domains/core/routing/routes/routes.default';
import { isB2C } from '@/domains/core/settings/utils';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';
import { B2BSuspectedPro } from '@/domains/customerManagement/auth/model';
import { isRouteAllowed } from '@/domains/proB2BAnimation/utils/isRouteAllowed';
import { isSameDateThanLastClose } from '@/domains/proB2BAnimation/utils/isSameDateThanLastClose';
import { deleteQueryParams } from '@/domains/proB2BAnimation/utils/route.utils';
import { isLocalStorageAvailable } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/utils';

import { suspectedOpenedStorageKey, suspectedStorageKey } from '../constants';

const notAllowedRoutes = [
  routes.cart,
  routes.firstOrder,
  routes.deliveryCort.replace(':customerOrderId', '\\d+'),
  routes.payment.replace(':customerOrderId', '\\d+'),
];

const suspectedProRefererIds = [
  '691507',
  '705926',
  '704040',
  '691930',
  '700808',
  '715877',
];

const useSuspectedProModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { asPath, query } = useRouter();
  const { referer_id: refererId } = query ?? {};
  const { userId, b2b_suspected_pro } = useAuth();

  const isSuspectedProUser =
    userId &&
    b2b_suspected_pro === B2BSuspectedPro.LEAD &&
    !isSameDateThanLastClose(suspectedStorageKey);

  const isSuspectedProRefererId =
    b2b_suspected_pro !== B2BSuspectedPro.REFUSED &&
    !(
      isLocalStorageAvailable() &&
      localStorage.getItem(suspectedOpenedStorageKey)
    ) &&
    refererId &&
    suspectedProRefererIds.includes(String(refererId)) &&
    isB2C();

  useEffect(() => {
    const url = deleteQueryParams(asPath);
    const isAllowed = isRouteAllowed(url, notAllowedRoutes);

    if (isAllowed && (isSuspectedProUser || isSuspectedProRefererId)) {
      setShowModal(true);
    }
  }, [
    userId,
    b2b_suspected_pro,
    asPath,
    isSuspectedProUser,
    isSuspectedProRefererId,
  ]);

  const closeModal = () => {
    setShowModal(false);
  };

  return {
    showModal,
    closeModal,
    isSuspectedProRefererId: !!isSuspectedProRefererId,
  };
};

export default useSuspectedProModal;

import type { FunctionComponent } from 'react';

import { PreHeaderBar } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/components/PreHeaderBar/PreHeaderBar';
import { useInitPreHeaders } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/useInitPreHeaders';
import { PreHeaders } from '@/productDiscovery/HeaderLeonidas/components/PreHeader/components/PreHeaders/PreHeaders';

export const PreHeader: FunctionComponent = () => {
  const preHeader = useInitPreHeaders();

  if (!preHeader?.fetched) {
    return <PreHeaderBar />;
  }

  const preHeaders = preHeader?.data || [];

  return (
    <>
      <PreHeaderBar>
        <PreHeaders preHeaders={preHeaders} />
      </PreHeaderBar>
    </>
  );
};

PreHeader.displayName = 'PreHeader';

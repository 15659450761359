import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const InfoOutline: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconsSystemInfoOutline" viewBox="0 0 24 24" {...props}>
    <path
      d="M11 7h2v2h-2V7zm1 4c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1v-4c0-.55.45-1 1-1zm0-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      fill="currentColor"
    />
  </Svg>
);

InfoOutline.displayName = 'InfoOutline';

import type { MultipleQueriesQuery } from '@algolia/client-search';

export const buildPlainMultipleQueriesQuery = (
  query: string,
  indexName: string,
  overrideParams: Partial<MultipleQueriesQuery['params']> = {},
): MultipleQueriesQuery => ({
  params: {
    hitsPerPage: 5,
    restrictSearchableAttributes: ['query'],
    attributesToRetrieve: ['*'],
    attributesToHighlight: [],
    clickAnalytics: true,
    ...overrideParams,
  },
  query,
  indexName,
});

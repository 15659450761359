import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type RefObject,
} from 'react';

import { isMobile } from '@/domains/productDiscovery/HeaderLeonidas/utils/isMobile';

export interface UseSeeMoreLinks {
  seeMoreVisible: boolean;
  listRef: RefObject<HTMLDivElement>;
  handleSeeMoreClick: () => void;
}

export const useSeeMoreLinks = (isVisible: boolean): UseSeeMoreLinks => {
  const listRef = useRef<HTMLDivElement>(null);
  const [alreadySaw, setAlreadySaw] = useState(false);
  const [seeMoreVisible, setSeeMoreVisible] = useState(false);

  const handleSeeMoreClick = () => {
    setSeeMoreVisible(false);
    setAlreadySaw(true);
  };

  const handleScroll = useCallback(
    (div: HTMLDivElement) => {
      if (div.scrollTop !== 0 && !alreadySaw) {
        setSeeMoreVisible(false);
        setAlreadySaw(true);
      }
    },
    [alreadySaw],
  );

  useEffect(() => {
    if (!isMobile()) {
      const div: HTMLDivElement | null = listRef.current;
      if (div && isVisible) {
        if (!alreadySaw) {
          setSeeMoreVisible(div.scrollHeight > div.clientHeight);
        }
        div.addEventListener('scroll', () => handleScroll(div));
      }

      return () => {
        if (div) div.removeEventListener('scroll', () => {});
      };
    }
  }, [handleScroll, isVisible, alreadySaw]);

  return {
    listRef,
    seeMoreVisible,
    handleSeeMoreClick,
  };
};

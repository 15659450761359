import classnames from 'classnames';
import Router from 'next/router';
import NProgress from 'nprogress';
import { useEffect, useRef, type FunctionComponent } from 'react';

import { IS_B2B } from '@/domains/core/settings/constants';

import styles from './ProgressBar.module.scss';

export const ProgressBar: FunctionComponent = () => {
  const timer = useRef<number | null>(null);

  const barClassNames = classnames(styles.bar, { [styles.b2b]: IS_B2B });
  const pegClassNames = classnames(styles.peg, { [styles.b2b]: IS_B2B });

  const onRouteChangeStart = (): void => {
    NProgress.set(0.3);
    NProgress.start();
  };

  const onRouteChangeEnd = (): void => {
    window.clearTimeout(timer.current as number);
    timer.current = window.setTimeout(() => {
      NProgress.done(true);
    }, 200);
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeEnd);
    Router.events.on('routeChangeError', onRouteChangeEnd);
    NProgress.configure({
      showSpinner: false,
      template: `<div class="${barClassNames}" role="bar"><div class="${pegClassNames}"></div></div>`,
    });

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeEnd);
      Router.events.off('routeChangeError', onRouteChangeEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

import classnames from 'classnames';
import type React from 'react';
import { useCallback, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { Link } from '@/domains/core/routing/components/Link';
import {
  setMenuVisibility,
  setSelectedCategory,
  setSelectedFamily,
} from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';

import styles from '../../Menu.module.scss';

interface Props extends React.PropsWithChildren {
  className?: string;
  linkClassName?: string;
  href: string;
  onClick?: () => void;
}

export const MenuItemLink: FunctionComponent<Props> = ({
  className,
  linkClassName,
  href,
  onClick,
  children,
}) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setMenuVisibility(false));

    if (onClick) {
      onClick();
    }
  }, [dispatch, onClick]);

  const handleOver = useCallback(() => {
    dispatch(setSelectedFamily(null));
    dispatch(setSelectedCategory(null));
  }, [dispatch]);

  return (
    <li className={classnames(styles.menuItem, className)}>
      <Link
        href={href}
        className={classnames(
          styles.menuFamilyItemLink,
          styles.menuItemLink,
          linkClassName,
        )}
        onClick={handleClick}
        onMouseEnter={handleOver}
        data-testid="menuItem"
      >
        {children}
      </Link>
    </li>
  );
};

MenuItemLink.displayName = 'MenuItemLink';

import { useDispatch } from 'react-redux';

import { setFocusedSuggestionPosition } from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useSearchFocusedSuggestionPosition } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchFocusedSuggestionPosition';

export const useKeyDown = (values: any[]) => {
  const dispatch = useDispatch();
  const focusedSuggestedPosition = useSearchFocusedSuggestionPosition();

  const changeFocusPosition = (position: number) => {
    dispatch(setFocusedSuggestionPosition(position));
  };

  const handleKeyDown = async (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        changeFocusPosition(
          focusedSuggestedPosition - 1 > 0 ? focusedSuggestedPosition - 1 : 0,
        );

        break;
      case 'ArrowDown':
        changeFocusPosition(
          focusedSuggestedPosition + 1 <= values.length
            ? focusedSuggestedPosition + 1
            : 1,
        );
        break;
      case 'Escape': {
        const { setSearchSuggestionsVisibility } = await import(
          '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.actions'
        );
        dispatch(setSearchSuggestionsVisibility(false));
        break;
      }
      default:
        changeFocusPosition(0);
    }
  };

  return handleKeyDown;
};

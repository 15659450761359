import { SearchResponse } from '@algolia/client-search';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageTypeSelector } from '@/core/page/page.selectors';
import { useVisitorId } from '@/core/tracking/hooks/useVisitorId';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { useAuth } from '@/customerManagement/auth/hooks/useAuth';
import { AlgoliaSearchInsights } from '@/productDiscovery/algolia/searchInsights/AlgoliaSearchInsights';
import { useAlgoliaCTRTrackingEvent } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useAlgoliaCTRTrackingEvent/useAlgoliaCTRTrackingEvent';
import { useDisplayEmptySearch } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useDisplayEmptySearch';
import { useSearchInput } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchInput';
import { useSuggestionsTracking } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSuggestionsTracking';
import { SuggestionRedirection } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/algolia';
import type { SuggestionItemData } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/suggestions';
import {
  createSuggestionsRedirectionsClientInstance,
  searchMultiple,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/services/algolia/proxy';
import { buildSuggestionsRedirectionsSearchParameters } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/services/algolia/searchParameters';
import { GTM_ON_SEARCH_QUERY } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/services/tracking/gtmEvents.search';
import { createRedirectionURL } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/utils/createRedirectionURL/createRedirectionURL';
import { SuggestionsRedirectionsSearchResults } from '@/productDiscovery/Listings/pages/Search/interfaces/algolia';

import {
  setSearchInput,
  setSearchSuggestionsVisibility,
} from '../../../HeaderLeonidas.searchBar.actions';
import { SUGGESTION_TYPE } from '../constants';

const getRedirectionURL = async (
  userToken: string,
  suggestionLabel: string,
) => {
  const client = await createSuggestionsRedirectionsClientInstance();

  try {
    const redirections = await searchMultiple(
      client,
      buildSuggestionsRedirectionsSearchParameters(suggestionLabel),
      userToken,
    );

    const [{ userData: categoryUserData }, _] =
      redirections as SearchResponse<SuggestionsRedirectionsSearchResults>[];

    const categoryRedirection: SuggestionRedirection | undefined =
      categoryUserData?.[0];

    return createRedirectionURL(suggestionLabel, {
      category: categoryRedirection,
    }).url;
  } catch (error) {
    // Fallback : Redirect to search page
    return createRedirectionURL(suggestionLabel).url;
  }
};

export const useSuggestionClick = ({ values }: { values: any[] }) => {
  const query = useSearchInput() || '';
  const suggestionsTrackingData = useSuggestionsTracking();
  const displayEmptySearch = useDisplayEmptySearch();
  const { sendAlgoliaSuggestionCTRTrackingEvent } =
    useAlgoliaCTRTrackingEvent();
  const pageType = useSelector(pageTypeSelector);
  const dispatch = useDispatch();

  const { userId } = useAuth();
  const { visitorId } = useVisitorId();
  const { push } = useRouter();

  const handleSuggestionClick = useCallback(
    async (itemClickedData: SuggestionItemData) => {
      const {
        label,
        type,
        position,
        inHistory,
        objectID,
        hasQueryCategory = false,
        event,
      } = itemClickedData;
      const suggestionLabel = label.replace(/<[^>]+>/g, '');

      dispatch(setSearchInput(suggestionLabel));
      dispatch(setSearchSuggestionsVisibility(false));

      // Save in search history
      if (!!label && type === SUGGESTION_TYPE.QUERY) {
        const { saveInSearchHistory } = await import(
          '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/utils/searchHistory/save'
        );
        saveInSearchHistory(label);
      }

      Gtm.push(
        GTM_ON_SEARCH_QUERY({
          hasUsedAutocomplete: !displayEmptySearch,
          searchQuery: query,
          isRedirectedFromSearch:
            type !== SUGGESTION_TYPE.QUERY && type !== SUGGESTION_TYPE.HISTORY,
          pageType,
          autocompleteItemLabel: label,
          autocompleteItemPosition: position,
          autocompleteItemType: type,
          itemsCount: values.length,
          isRecentSearch: inHistory,
          isEmptystate: displayEmptySearch,
          hasQueryCategory,
        }),
      );

      // Tracking - Algolia search insights
      if (suggestionsTrackingData && type !== SUGGESTION_TYPE.HISTORY) {
        sendAlgoliaSuggestionCTRTrackingEvent({
          suggestionName: label,
          position,
          objectID,
          queryDetails: {
            queryID: suggestionsTrackingData[type]?.queryID as string,
            index: suggestionsTrackingData[type]?.index as string,
          },
        });
      }

      // Save in search history
      if (!!label && type === SUGGESTION_TYPE.QUERY) {
        const { saveInSearchHistory } = await import(
          '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/utils/searchHistory/save'
        );
        saveInSearchHistory(label);
      }

      // Category Redirection
      if (
        (type === SUGGESTION_TYPE.QUERY && !hasQueryCategory) ||
        type === SUGGESTION_TYPE.HISTORY
      ) {
        event.preventDefault();

        const userToken = userId
          ? await AlgoliaSearchInsights.calculateUserIdHash(String(userId))
          : visitorId;

        const redirectionURL = await getRedirectionURL(
          userToken,
          suggestionLabel,
        );

        push(redirectionURL, undefined, {
          shallow: false,
        });
      }
    },
    [
      query,
      suggestionsTrackingData,
      displayEmptySearch,
      pageType,
      userId,
      visitorId,
      push,
      dispatch,
      values.length,
      sendAlgoliaSuggestionCTRTrackingEvent,
    ],
  );

  return handleSuggestionClick;
};

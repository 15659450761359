import { useEffect, type FC } from 'react';
import { useDispatch } from 'react-redux';

import { useDynamicReducer } from '@/domains/core/applicationState/hooks/useDynamicReducer';
import { useDynamicSaga } from '@/domains/core/applicationState/hooks/useDynamicSaga';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';
import { logIntoSentry } from '@/domains/shoppingCart/common/utils/logIntoSentry';

import {
  cleanCartOverview,
  getCartOverviewManager,
} from './actions/GetCartOverview.actions';
import {
  CART_OVERVIEW_SAGA_KEY,
  CART_PAGE,
  ORDER_RECAP,
} from './CartOverview.constants';
import { cartOverviewReducer } from './CartOverview.reducer';
import { rootCartOverview } from './CartOverview.root.saga';

let initialized = false;
interface Props {
  pageType: string;
}
export const CartOverview: FC<Props> = ({ pageType }) => {
  useDynamicReducer('shoppingCart.cartOverview', cartOverviewReducer);
  useDynamicSaga(CART_OVERVIEW_SAGA_KEY, rootCartOverview);

  const dispatch = useDispatch();
  const { isAuthenticating, isAuthenticated } = useAuth();

  useEffect(() => {
    if (initialized) {
      logIntoSentry(
        new Error(
          'CartOverview module have already been initialized, this should never happen. Please use the module only once',
        ),
        'cartOverview',
      );
    } else {
      initialized = true;
    }
    return () => {
      initialized = false;
    };
  }, []);

  useEffect(() => {
    const shouldFetch = pageType !== CART_PAGE;
    const shouldClean = pageType === ORDER_RECAP;
    if (!isAuthenticating) {
      if (shouldClean) {
        dispatch(cleanCartOverview());
      }

      if (shouldFetch && !shouldClean) {
        dispatch(getCartOverviewManager());
      }
    }
  }, [dispatch, isAuthenticating, isAuthenticated, pageType]);

  return null;
};

CartOverview.displayName = 'CartOverview';

import { isB2B } from '@/domains/core/settings/utils';
import type {
  CartTrackingModel,
  MenuItemModel,
} from '@/domains/productDiscovery/HeaderLeonidas/interfaces/data.model';
import type { QuickAccessLinkModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';
import type { MenuCategory } from '@/productDiscovery/HeaderLeonidas/interfaces/MenuTree.model';

import { GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE } from '../../constants';

export const GTM_CLICK_ON_MM_LOGO = () => ({
  event: 'interaction_click on MM logo',
  e_category: 'navigation',
  e_action: 'Click on MM logo',
  e_label: 'header b2c',
  amp_event_name: 'Click on MM logo',
  event_trigger: 'header b2c',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_A_LINK = (link: string, text: string) => ({
  event: 'interaction_click on a link',
  e_category: 'navigation',
  e_action: 'Click on a link',
  e_label: 'header b2c standard',
  amp_event_name: 'Click on a link',
  event_trigger: 'header b2c standard',
  redirection_link: link,
  click_element: text,
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_LOGIN_ACCESS = () => ({
  event: 'interaction_click on login access',
  event_trigger: 'header b2c',
  amp_event_name: 'Click on login access',
  e_category: 'navigation',
  e_action: 'Click on login access',
  e_label: 'header b2c',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_MARKET_LOGIN = (market: string) => ({
  event: 'interaction_click on login access',
  block_clicked: market,
  amp_event_name: 'Click on login access',
  event_trigger: 'header b2c',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_ACCOUNT_ACCESS = () => ({
  event: 'interaction_click on access account',
  e_category: 'navigation',
  e_action: 'Click on access account',
  e_label: 'header b2c',
  amp_event_name: 'Access account',
  event_trigger: 'header b2c',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_CART = (cartTrackingModel: CartTrackingModel) => ({
  event: 'interaction_click on cart icon',
  e_category: 'navigation',
  e_action: 'Click on cart icon',
  e_label: isB2B() ? 'header b2b' : 'header b2c standard',
  amp_event_name: 'Click on cart icon',
  event_trigger: isB2B() ? 'header b2b' : 'header b2c standard',
  cart_size: cartTrackingModel.cartSize,
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_PRO_BUTTON = (
  pageType?: string,
  pageName?: string,
) => ({
  event: 'interaction_click on b2b access',
  amp_event_name: 'Click on b2b access',
  event_trigger: 'header b2c',
  page_type: pageType || undefined,
  page_name: pageName || undefined,
});

export const GTM_CLICK_ON_MENU_BUTTON = () => ({
  event: 'interaction_open navigation menu',
  e_category: 'navigation',
  e_action: 'Open navigation menu',
  e_label: isB2B() ? 'header b2b' : 'header b2c',
  amp_event_name: 'Open navigation menu',
  event_trigger: isB2B() ? 'header b2b' : 'header b2c standard',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_MENU_ITEM = (menuItem: MenuItemModel) => ({
  event: 'interaction_click on menu',
  event_trigger: 'menu',
  amp_event_name: 'Click on menu',
  e_category: 'navigation',
  e_action: 'Click on menu',
  e_label: 'menu',
  navmenu_item_level: menuItem.level,
  navmenu_category_name: menuItem.categoryName,
  navmenu_subcategory_name: menuItem.subCategoryName,
  navmenu_subsubcategory_name: menuItem.subSubCategoryName,
  navmenu_item_destination: menuItem.url,
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_SEE_ALL_CATEGORIES = (category: MenuCategory) => ({
  event: 'interaction_click on see all categories',
  event_trigger: 'menu',
  amp_event_name: 'Click on see all categories',
  e_category: 'navigation',
  e_action: 'Click on see all categories',
  e_label: 'menu',
  category_name: category.name,
  category_id: Number(category.id),
  redirection_link: category.url,
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_QUICK_ACCESS = (
  quickAccessLink: QuickAccessLinkModel,
  index: number,
) => ({
  event: 'interaction_click on navmenu item',
  event_trigger: 'quickaccess',
  amp_event_name: 'Click on quickaccess',
  e_category: 'navigation',
  e_action: 'Click on quickaccess',
  e_label: 'quickaccess',
  navmenu_item_level: 0,
  navmenu_item_position: index,
  navmenu_item_name: quickAccessLink.title,
  navmenu_item_destination: quickAccessLink.url,
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { AlgoliaQueryDetails } from '../interfaces/algolia';

export const productSuggestionsQueryDetailsSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): AlgoliaQueryDetails | undefined =>
  state.productDiscovery?.searchBar?.autoComplete?.products?.queryDetails;

export const useProductSuggestionsQueryDetails = () =>
  useSelector(productSuggestionsQueryDetailsSelector);

import { type ComponentProps, type MouseEventHandler } from 'react';
import { twJoin } from 'tailwind-merge';

import { Image } from '@/core/Image/Image';
import { Span } from '@/core/tamagoshiTailwind/components/Typography/Typography';
import { CrossIcon } from '@/core/tamagoshiTailwind/icons/system/CrossIcon';
import { TamagoshiIconComponent } from '@/core/tamagoshiTailwind/interfaces';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';

type BaseProps = {
  /** Whether the chip IS disabled and not clickable */
  disabled?: boolean;
  /** Whether the chip APPEARS disabled but remains clickable */
  unavailable?: boolean;
  /** Function to call when the chip is clicked */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Whether the chip is selected */
  selected: boolean;
  /** The text to display inside the chip component */
  text: string;
};

/** Props for the chip component when a color is specified */
type ColorProps = {
  /** The background color of the chip eg: #FF0000 */
  color: string;
  icon?: never;
  image?: never;
  removable?: never;
};

/** Props for the chip component when an icon can be specified */
type IconProps = {
  color?: never;
  /** Icon displayed on the left side of the chip */
  icon?: TamagoshiIconComponent;
  image?: never;
  /** Whether a cross icon is displayed at the right when the chip is selected */
  removable?: true;
};

/** Props for the chip component when an image is specified */
type ImageProps = {
  color?: never;
  icon?: never;
  /** Image to display inside of the chip */
  image: Pick<
    ComponentProps<typeof Image>,
    'alt' | 'fetchPriority' | 'loading' | 'src'
  >;
  removable?: never;
};

type Props = BaseProps & (ColorProps | IconProps | ImageProps);

export const Chip = ({
  color,
  disabled,
  unavailable = false,
  icon: Icon,
  image,
  onClick,
  removable,
  selected,
  text,
}: Props) => {
  const children = (
    <>
      {color && (
        <div
          className="size-[24px] rounded-full border border-foundation-primary"
          role="status"
          style={{ backgroundColor: color }}
        />
      )}
      {image && (
        <Image
          className="rounded-tag border border-foundation-primary"
          height={56}
          width={56}
          {...image}
        />
      )}
      {Icon && <Icon className="size-[16px]" />}
      <Span
        className={twJoin(
          'text-ellipsis',
          image
            ? 'line-clamp-2 text-left'
            : 'overflow-hidden whitespace-nowrap',
        )}
        scale="body2"
        weight="semibold"
      >
        {text}
      </Span>
      {removable && selected && !disabled && <CrossIcon className="h-m w-m" />}
    </>
  );

  const props = {
    children,
    className: twMerge(
      // Base styles
      !disabled && 'hover:cursor-pointer',
      `inline-flex h-[40px] flex-shrink-0 items-center gap-s rounded-full border
      border-foundation-primary bg-foundation-inverted px-m py-s
      hover:border-foundation-secondary`,
      // Selected
      selected && 'border-foundation-tertiary hover:bg-foundation-primary',
      // Disabled/unavailable
      (disabled || unavailable) && [
        // NOTE This applies opacity of the whole chip. It's possible we want an
        //      overlay instead so that the chip remains fully opaque.
        'bg-foundation-disabled text-foundation-disabled opacity-50',
        !selected && 'border-foundation-secondary',
      ],
      color && 'pl-s',
      image && 'h-[72px] rounded-card px-s',
    ),
    'aria-disabled': disabled,
    disabled,
    onClick: disabled ? undefined : onClick,
  };

  return <button {...props} />;
};

Chip.displayName = 'Chip';

import { IS_B2B } from '@/domains/core/settings/constants';
import { useIsFetchingMarketingCampaigns } from '@/domains/productDiscovery/CommercialAnimation/hooks/useIsFetchingMarketingCampaigns';
import { useMarketingCampaigns } from '@/domains/productDiscovery/CommercialAnimation/hooks/useMarketingCampaigns';
import { useMarketingCampaignsError } from '@/domains/productDiscovery/CommercialAnimation/hooks/useMarketingCampaignsError';

export const useDynamicMarketingCampaigns = () => {
  const isFetching = useIsFetchingMarketingCampaigns();
  const marketingCampaigns = useMarketingCampaigns();
  const error = useMarketingCampaignsError();

  if (!IS_B2B) {
    return { marketingCampaigns: [], isFetching: false, error: undefined };
  }
  return { marketingCampaigns, isFetching, error };
};

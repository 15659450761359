import type React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { twJoin } from 'tailwind-merge';

import { isB2B } from '@/core/settings/utils';
import { Slider } from '@/core/tamagoshi/Carousel/components/Slider/Slider';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';
import { useQuickAccessLinksData } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useQuickAccessLinksData';
import { QuickAccessLinksArrows } from '@/productDiscovery/HeaderLeonidas/components/QuickAccessLinks/QuickAccessLinksArrows';
import {
  ariaLabelNext,
  ariaLabelPrevious,
} from '@/productDiscovery/HeaderLeonidas/components/QuickAccessLinks/translation';

import promosTranslations from '../../b2b/components/Promos/translations';
import { usePromosVisibility } from '../../b2b/hooks/usePromosVisibility';
import { setPromosVisibility } from '../../b2b/store/HeaderLeonidas.actions';
import { useDynamicMarketingCampaigns } from '../../hooks/useDynamicMarketingCampaigns';
import { QuickAccessLinksItem } from './QuickAccessLinksItem';

import styles from './QuickAccessLinks.module.scss';

interface QuickAccessLinksProps {
  className?: string;
}

export const QuickAccessLinks: React.FC<QuickAccessLinksProps> = ({
  className,
}) => {
  const {
    fetchingQuickAccessLinks,
    leftVisible,
    rightVisible,
    quickAccessLinks,
    onControlClick,
    sliderRef,
    onScrollChange,
    handleClick,
  } = useQuickAccessLinksData();
  const {
    isFetching: fetchingMarketingCampaigns,
    marketingCampaigns,
    error,
  } = useDynamicMarketingCampaigns();
  const isPromosVisible = usePromosVisibility();
  const dispatch = useDispatch();

  const handlePromosClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch(setPromosVisibility(!isPromosVisible));
    },
    [dispatch, isPromosVisible],
  );

  if (!quickAccessLinks && !marketingCampaigns?.length) {
    return null;
  }

  if (
    fetchingQuickAccessLinks ||
    fetchingMarketingCampaigns ||
    (marketingCampaigns === undefined && !error)
  ) {
    return null;
  }

  return (
    <nav
      className={twMerge(
        `group relative flex h-[34px] w-full pt-xs before:opacity-0
        after:opacity-0 desktop:w-auto desktop:pt-0`,
        styles.navbar,
        leftVisible && 'before:opacity-100',
        rightVisible && 'after:opacity-100',
        className,
      )}
      data-testid="quick-access-links"
    >
      <QuickAccessLinksArrows
        onLeftClick={() => onControlClick('left')}
        onRightClick={() => onControlClick('right')}
        titleLeft={ariaLabelPrevious}
        titleRight={ariaLabelNext}
        disabledLeft={!leftVisible}
        disabledRight={!rightVisible}
      />
      <Slider
        ref={sliderRef}
        onScrollChange={onScrollChange}
        className={twJoin(
          `hide-scrollbar desktop:scroll-p-none relative flex w-full scroll-p-m
          flex-row flex-nowrap items-stretch justify-start gap-s
          overflow-y-hidden overflow-x-scroll after:block after:shrink-0
          after:grow-0 after:basis-s desktop:after:hidden
          [&>div:first-child]:ml-m desktop:[&>div:first-child]:ml-0`,
        )}
      >
        {isB2B() && !!marketingCampaigns?.length && (
          <QuickAccessLinksItem
            link={{
              title: promosTranslations.label,
              url: '',
              className: 'promo',
            }}
            key="promos"
            handleClick={handlePromosClick}
          />
        )}
        {quickAccessLinks?.map((link, index) => (
          <QuickAccessLinksItem
            link={link}
            key={link.url}
            handleClick={() =>
              handleClick({ url: link.url, title: link.title, index })
            }
          />
        ))}
      </Slider>
    </nav>
  );
};

QuickAccessLinks.displayName = 'QuickAccessLinks';

import { routes } from '@/domains/core/routing/routes/routes.default';
import { createURL } from '@/domains/core/routing/URLParser';
import type { SuggestionRedirections } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/suggestions';
import { customEncodeURIComponent } from '@/domains/productDiscovery/Listings/services/querystring';

export const createRedirectionURL = (
  query?: string,
  redirections?: SuggestionRedirections,
) => {
  let url: string;
  let isRedirected = true;
  if (redirections?.category) {
    const { slug: category, id } = redirections.category;
    url = createURL(routes.category, { category, id });
  } else if (redirections?.brand) {
    const { slug: brand, id } = redirections.brand;
    url = createURL(routes.brand, { brand, id });
  } else {
    isRedirected = false;
    url = createURL(
      routes.search,
      {
        query: customEncodeURIComponent(query ?? ''),
      },
      redirections?.bestCategory && {
        catId: redirections.bestCategory.categoryId,
        category_names: redirections.bestCategory.categoryName,
        autoselect: 'true',
      },
    );
  }
  return { url, isRedirected };
};

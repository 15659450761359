import { formatCurrency } from '@/domains/core/localization/currency';
import { CURRENCY } from '@/domains/core/settings/constants';
import type { MiniProductCardTranslations } from '@/domains/core/tamagoshi/MiniProductCard/interfaces';
import { translations as productCardTranslations } from '@/domains/productDiscovery/ProductCard/translations';

export const getMiniProductCardTranslations = (
  couponAmount?: string | undefined,
): MiniProductCardTranslations => ({
  tags: {
    blackFriday: productCardTranslations.tags.blackFriday,
    sales: productCardTranslations.tags.sales,
    manomanoDays: productCardTranslations.tags.manomanoDays,
    promo: productCardTranslations.tags.promo,
    coupon: {
      // Not used right now
      label: couponAmount
        ? `${formatCurrency(couponAmount, CURRENCY, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })} ${productCardTranslations.tags.coupon?.label}`
        : '',
    },
    sponsored: productCardTranslations.tags.sponsored,
  },
});

import type { ActionCreator } from 'redux';

import type { ActionWithPayload } from '@/domains/core/applicationState/interfaces';

export const SET_PROMOS_VISIBILITY =
  'productDiscovery/HeaderLeonidas/SET_PROMOS_VISIBILITY';

export const INIT_HEADER_LEONIDAS = 'productDiscovery/HeaderLeonidas/INIT';

export type SetPromosVisibilityAction = ActionWithPayload<
  { visible: boolean },
  typeof SET_PROMOS_VISIBILITY
>;

export const setPromosVisibility: ActionCreator<SetPromosVisibilityAction> = (
  visible: boolean,
) => ({
  payload: { visible },
  type: SET_PROMOS_VISIBILITY,
});

export interface InitHeaderLeonidasAction {
  type: typeof INIT_HEADER_LEONIDAS;
}

export const initHeaderLeonidas = (): InitHeaderLeonidasAction => ({
  type: INIT_HEADER_LEONIDAS,
});

export type Action = SetPromosVisibilityAction;

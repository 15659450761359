import { useCallback, useRef, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { ArrowRight } from '@/core/tamagoshi/icons/ArrowRight';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { setSelectedCategory } from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useSelectedCategory } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useMenuVisibility';
import { GTM_CLICK_ON_MENU_ITEM } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';
import { isMobile } from '@/domains/productDiscovery/HeaderLeonidas/utils/isMobile';
import type { MenuCategory } from '@/productDiscovery/HeaderLeonidas/interfaces/MenuTree.model';

import styles from '../../Menu.module.scss';

interface Props {
  category: MenuCategory;
  family: MenuCategory;
  select: () => any;
}

export const MenuSubCategoryItemButton: FunctionComponent<Props> = ({
  category,
  family,
  select,
}) => {
  const dispatch = useDispatch();

  const overTimeout = useRef<any>();
  const selectedCategory = useSelectedCategory();

  const mouseOver = useCallback(() => {
    if (isMobile()) {
      return;
    }

    overTimeout.current = setTimeout(select, 200);
  }, [select]);

  const mouseOut = useCallback(() => {
    if (isMobile()) {
      return;
    }

    if (overTimeout.current) {
      clearTimeout(overTimeout.current);
    }
  }, []);

  const onClick = () => {
    if (selectedCategory && isMobile()) {
      dispatch(setSelectedCategory(null));

      const menuItem = {
        categoryName: family.name,
        subCategoryName: category.name,
        subSubCategoryName: '',
        url: category.url,
        level: 1,
      };

      Gtm.push(GTM_CLICK_ON_MENU_ITEM(menuItem));
    } else {
      select();
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      onFocus={select}
      className={styles.menuFamilyItemLink}
      onMouseEnter={mouseOver}
      onMouseLeave={mouseOut}
      data-testid="subcategory-item"
    >
      <span>{category.name}</span>
      <ArrowRight className={styles.menuCategoryItemLinkArrow} />
    </button>
  );
};

MenuSubCategoryItemButton.displayName = 'MenuSubCategoryItemButton';

import { useDispatch } from 'react-redux';

export const useRemoveAllSuggestionsHistory = () => {
  const dispatch = useDispatch();

  return async () => {
    const { removeAllSearchHistory } = await import(
      '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/utils/searchHistory/removeAllSearchHistory'
    );

    if (removeAllSearchHistory()) {
      const { removeAllSuggestionsHistory } = await import(
        '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.actions'
      );
      return dispatch(removeAllSuggestionsHistory());
    }
  };
};

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const searchDisplayEmptySearchSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean =>
  state.productDiscovery?.headerLeonidas?.ui.displayEmptySearch || false;

export const useDisplayEmptySearch = () =>
  useSelector(searchDisplayEmptySearchSelector);

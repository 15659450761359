import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import type {
  AvailableSuggestions,
  Suggestions,
} from '../interfaces/suggestions';
import { selectCurrentSuggestions } from './useCurrentSuggestions';

const keys: (keyof AvailableSuggestions)[] = [
  'histories',
  'queries',
  'categories',
  'brands',
  'products',
  'topProducts',
  'similarityProducts',
];

export const selectAvailableSuggestions = createSelector(
  [selectCurrentSuggestions],
  (currentSuggestions) => {
    if (!currentSuggestions) return undefined;

    const availableSuggestions: AvailableSuggestions =
      keys.reduce<AvailableSuggestions>((acc, key) => {
        const items = currentSuggestions[key as keyof Suggestions]?.items;
        if (items?.length) {
          acc[key as keyof Suggestions] = items;
        }
        return acc;
      }, {});

    return Object.keys(availableSuggestions).length > 0
      ? availableSuggestions
      : undefined;
  },
);

export const useAvailableSuggestions = (): AvailableSuggestions | undefined =>
  useSelector(selectAvailableSuggestions);

import { useDispatch } from 'react-redux';

export const useRemoveHistorySuggestion = () => {
  const dispatch = useDispatch();

  return async (value: string) => {
    const { removeInSearchHistory } = await import(
      '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/utils/searchHistory/remove'
    );

    if (removeInSearchHistory(value)) {
      const { updateSuggestionsHistory } = await import(
        '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.actions'
      );
      return dispatch(updateSuggestionsHistory());
    }
  };
};

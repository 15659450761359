import logger from '@/core/observability/logger';

const BUCKET = '@TrackingId/'; // Same bucket name as manomano-sf for compatibility
const PROVIDER = 'Mabaya'; // Same provider name as manomano-sf for compatibility
const EXPIRY_TIME = 60 * 24 * 14; // 14 days - Maximum number (in minutes) of days between the click on the sponsored product and the act of purchase

const getOrCreateMabayaUserId = async (): Promise<string | null> => {
  try {
    const { v4: uuidv4 } = await import('uuid');
    const lscache = await import('lscache');

    lscache.setBucket(BUCKET);

    const existingUuid = lscache.get(PROVIDER);
    if (existingUuid) {
      lscache.resetBucket();
      return existingUuid;
    }

    const newUuid = uuidv4();
    lscache.set(PROVIDER, newUuid, EXPIRY_TIME);
    lscache.resetBucket();

    return newUuid;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default getOrCreateMabayaUserId;

import { useSelector } from 'react-redux';

import { advertiserInformationSelector } from '@/domains/productDiscovery/CommercialAnimation/commercialAnimation.selectors';
import type { AdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/interfaces/AdvertiserInformation';
import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const useAdvertiserInformation = () =>
  useSelector<
    SpartacuxProductDiscoveryDomainState,
    AdvertiserInformation | undefined
  >(advertiserInformationSelector);

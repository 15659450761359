import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';

import type { SuspectedProModalProps } from '@/domains/proB2BAnimation/modules/ProModal/SuspectedProModal';

import useSuspectedProModal from './hooks/useSuspectedProModal';

const SuspectedProModal = dynamic<SuspectedProModalProps>(
  () => import('@/domains/proB2BAnimation/modules/ProModal/SuspectedProModal'),
  { ssr: false },
);

const SuspectedProModalContainer: FunctionComponent = () => {
  const { showModal, closeModal, isSuspectedProRefererId } =
    useSuspectedProModal();

  return showModal ? (
    <SuspectedProModal
      isSuspectedProRefererId={isSuspectedProRefererId}
      closeModal={closeModal}
    />
  ) : null;
};

SuspectedProModalContainer.displayName = 'SuspectedProModalContainer';

export default SuspectedProModalContainer;

/* eslint-disable camelcase */
import type { AxiosError } from 'axios';

import { isHttpClientError } from '@/domains/core/httpClient/utils/isHttpClientError';

/**
 * Use this method to verify if the error format match manomano's API error standarts
 * All API errors should return like this
 * {
 *    data:{
 *      error: {
 *        app_code: '',
 *        message: ''
 *      }
 *   }
 * }
 */

interface MicroServiceError extends AxiosError {
  error?: {
    app_code: string;
    message: string;
  };
}

export const isMicroServiceError = (
  error: unknown,
): error is MicroServiceError =>
  isHttpClientError(error) &&
  typeof error?.response?.data === 'object' &&
  typeof error?.response?.data?.error === 'object';

import Cookies from 'js-cookie';

import { Cookies as CookiesNames } from '@/domains/core/httpClient/cookies';
import { getCookieDomain } from '@/domains/core/httpClient/getCookieDomain';

export const deleteB2BCookie = () => {
  Cookies.remove(CookiesNames.MM_B2B, {
    path: '/',
    domain: getCookieDomain(),
    sameSite: 'Lax',
  });
};

export const getB2BCookieValue = () => Cookies.get(CookiesNames.MM_B2B);

import { httpClient } from '@/core/httpClient/httpClient';
import { getAppSetting } from '@/core/settings/appSettings';
import type { Platform } from '@/core/settings/interfaces/Platform';
import { getPlatform, isB2B } from '@/core/settings/utils';
import { getDevice } from '@/productDiscovery/Listings/sponsoredProducts/utils/getDevice';
import type {
  SponsoredProductTrackingClick,
  SponsoredProductTrackingConversion,
  SponsoredProductTrackingDevice,
  SponsoredProductTrackingImpression,
  SponsoredProductTrackingVisit,
} from '@/productDiscovery/SponsoredProducts/interfaces/SponsoredProductTracking';
import getOrCreateMabayaUserId from '@/productDiscovery/SponsoredProducts/utils/getOrCreateMabayaUserId/getOrCreateMabayaUserId';
import { detectUnblocked } from '@/sellerAdsManagement/adBlockers/adBlockPlus';

export interface PrivateTrackingSpec {
  // eslint-disable-next-line camelcase
  user_cookie: string;
  platform: Platform;
  device: SponsoredProductTrackingDevice;
}

export interface ImpressionsTrackingPayload {
  // eslint-disable-next-line camelcase
  impressions_list: SponsoredProductTrackingImpression[];
}

export type ImpressionsTrackingSpec = Omit<
  SponsoredProductTrackingImpression,
  keyof PrivateTrackingSpec
>;

export type ClickTrackingPayload = SponsoredProductTrackingClick;

export type ClickTrackingSpec = Omit<
  ClickTrackingPayload,
  keyof PrivateTrackingSpec
>;

type VisitTrackingPayload = SponsoredProductTrackingVisit;

export type VisitTrackingSpec = Omit<
  VisitTrackingPayload,
  keyof PrivateTrackingSpec
>;

type ConversionTrackingPayload = SponsoredProductTrackingConversion;

export type ConversionTrackingSpec = Omit<
  ConversionTrackingPayload,
  keyof PrivateTrackingSpec
>;

export class SPTrackingApi {
  private static get BASE_PATH() {
    return `/api/v1/sp-tracking/${isB2B() ? 'b2b/' : ''}`;
  }

  public static get IMPRESSIONS_PATH() {
    return `${this.BASE_PATH}impressions`;
  }

  public static get CLICKS_PATH() {
    return `${this.BASE_PATH}click`;
  }

  public static get CONVERSION_PATH() {
    return `${this.BASE_PATH}conversion`;
  }

  public static get VISIT_PATH() {
    return `${this.BASE_PATH}visit`;
  }

  private static fillPrivateApi<T>(
    data: T,
    mabayaUserId: string,
    unblocked: boolean,
  ): T & PrivateTrackingSpec {
    const payload: PrivateTrackingSpec & T = {
      ...data,
      user_cookie: mabayaUserId,
      unblocked,
      platform: getPlatform(),
      device: getDevice(),
    };

    return payload;
  }

  private static async sharedRequest<T>(
    url: string,
    payload: T,
  ): Promise<void> {
    await httpClient.post<void>(url, payload, {
      baseURL: getAppSetting('MS_API_URL'),
    });
  }

  public static async trackImpression(
    data: ImpressionsTrackingSpec[],
  ): Promise<void> {
    const [mabayaUserId, unblocked] = await Promise.all([
      getOrCreateMabayaUserId(),
      detectUnblocked(),
    ]);
    if (!mabayaUserId) return;

    const payload: ImpressionsTrackingPayload = {
      impressions_list: data.map((element) =>
        this.fillPrivateApi(element, mabayaUserId, unblocked),
      ),
    };

    return this.sharedRequest(this.IMPRESSIONS_PATH, payload);
  }

  public static async trackClick(data: ClickTrackingSpec): Promise<void> {
    const [mabayaUserId, unblocked] = await Promise.all([
      getOrCreateMabayaUserId(),
      detectUnblocked(),
    ]);
    if (!mabayaUserId) return;

    const payload: ClickTrackingPayload = this.fillPrivateApi(
      data,
      mabayaUserId,
      unblocked,
    );

    return this.sharedRequest(this.CLICKS_PATH, payload);
  }

  public static async trackVisit(data: VisitTrackingSpec): Promise<void> {
    const [mabayaUserId, unblocked] = await Promise.all([
      getOrCreateMabayaUserId(),
      detectUnblocked(),
    ]);
    if (!mabayaUserId) return;

    const payload: VisitTrackingPayload = this.fillPrivateApi(
      data,
      mabayaUserId,
      unblocked,
    );

    return this.sharedRequest(this.VISIT_PATH, payload);
  }

  public static async trackConversion(
    data: ConversionTrackingSpec,
  ): Promise<void> {
    const [mabayaUserId, unblocked] = await Promise.all([
      getOrCreateMabayaUserId(),
      detectUnblocked(),
    ]);
    if (!mabayaUserId) return;

    const payload: ConversionTrackingPayload = this.fillPrivateApi(
      data,
      mabayaUserId,
      unblocked,
    );

    return this.sharedRequest(this.CONVERSION_PATH, payload);
  }
}

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const fetchingSuggestionsSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean | undefined =>
  state.productDiscovery?.searchBar?.suggestions.isFetching;

export const useFetchingSuggestions = () =>
  useSelector(fetchingSuggestionsSelector);

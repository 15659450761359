import type { FunctionComponent } from 'react';

import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { leonidasLinkAdvice } from '@/domains/productDiscovery/HeaderLeonidas/b2c/modules/translations';
import { MenuItemLink } from '@/domains/productDiscovery/HeaderLeonidas/components/Menu/components/MenuItemLink/MenuItemLink';
import { GTM_CLICK_ON_A_LINK } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';
import { footerSitemapAdviceHref } from '@/domains/retention/Footer/translations';

import styles from './AdviceMenuItem.module.scss';

interface Props {
  className?: string;
}

export const AdviceMenuItem: FunctionComponent<Props> = ({ className }) => (
  <MenuItemLink
    href={footerSitemapAdviceHref}
    className={className || ''}
    linkClassName={styles.adviceLink}
    onClick={() =>
      Gtm.push(GTM_CLICK_ON_A_LINK(footerSitemapAdviceHref, leonidasLinkAdvice))
    }
  >
    <span>{leonidasLinkAdvice}</span>
  </MenuItemLink>
);

AdviceMenuItem.displayName = 'AdviceMenuItem';

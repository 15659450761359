import BlindnessProModalContainer from '@/domains/proB2BAnimation/modules/ProModal/BlindnessProModalContainer';
import SuspectedProModalContainer from '@/domains/proB2BAnimation/modules/ProModal/SuspectedProModalContainer';

export function BasePageContent() {
  return (
    <>
      {/* NOTE None of them can overlap with the other */}
      {/* If the user have the mm_b2b cookie it will be Blindness but never Suspected and vice versa */}
      <BlindnessProModalContainer />
      <SuspectedProModalContainer />
    </>
  );
}

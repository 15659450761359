import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';

import { isSameDateThanLastClose } from '@/domains/proB2BAnimation/utils/isSameDateThanLastClose';
import { getB2BCookieValue } from '@/domains/proB2BAnimation/utils/manageB2BCookie';

const BlindnessProModal = dynamic(
  () => import('@/domains/proB2BAnimation/modules/ProModal/BlindnessProModal'),
  { ssr: false },
);

export const blindnessStampKey = 'blindness_last_close';

const BlindnessProModalContainer: FunctionComponent = () => {
  const haveB2BCookie = getB2BCookieValue();

  /*TODO: this was a call to 'useFeatureFlags' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
  const [ffBlindnessModal] = [true];

  const shouldShowBlindnessModal =
    ffBlindnessModal &&
    haveB2BCookie &&
    !isSameDateThanLastClose(blindnessStampKey);

  return shouldShowBlindnessModal ? <BlindnessProModal /> : null;
};

BlindnessProModalContainer.displayName = 'BlindnessProModalContainer';

export default BlindnessProModalContainer;

export const initialState = {
  error: null,
  isModalNewsletterVisible: false,
  isModalSuccessVisible: false,
  emailAddress: '',
};

export interface Newsletter {
  error: string | null;
  isModalNewsletterVisible: boolean;
  isModalSuccessVisible: boolean;
  emailAddress: string;
}

import { call, put, takeLatest } from 'redux-saga/effects';

import { FlagValuesMap } from '@/core/flags/flags.state';
import { waitForAndGetFlagValuesSaga } from '@/core/flags/sagas/waitForAndGetFlagValues.saga';
import logger from '@/domains/core/observability/logger';
import type {
  BannerCampaign,
  UserSegments,
} from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { abTestAndFeatureFlagValidated } from '@/domains/productDiscovery/CommercialAnimation/utils/abTestAndFeatureFlagValidated';
import { getUserSegments } from '@/domains/productDiscovery/CommercialAnimation/utils/getUserSegments';
import {
  FETCH_HEADER_BANNERS_FAILED,
  FETCH_HEADER_BANNERS_SUCCESS,
  FETCHING_HEADER_BANNERS,
  INIT_HEADER_BANNERS,
} from '@/domains/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBanners.actions';
import { fetchHeaderBannersFromCampaignManagerGql } from '@/domains/productDiscovery/HeaderLeonidas/components/HeaderBanner/services/fetch';

function* getHeaderBanner(): Generator<any, any, any> {
  try {
    yield put({
      type: FETCHING_HEADER_BANNERS,
    });

    const userSegments: UserSegments = yield call(getUserSegments);
    const flags: FlagValuesMap = yield call(waitForAndGetFlagValuesSaga);

    const headerBanners: BannerCampaign[] = yield call(
      fetchHeaderBannersFromCampaignManagerGql,
      userSegments,
    );

    const validHeaderBanners =
      headerBanners?.length > 0
        ? headerBanners.filter(
            (headerBanner: BannerCampaign) =>
              headerBanner.link &&
              headerBanner.link.url &&
              abTestAndFeatureFlagValidated(headerBanner, flags),
          )
        : [];

    yield put({
      type: FETCH_HEADER_BANNERS_SUCCESS,
      campaigns: validHeaderBanners,
    });
  } catch (error) {
    logger.error(error);
    yield put({
      type: FETCH_HEADER_BANNERS_FAILED,
      error: error.message,
    });
  }
}

export function* rootSaga(): Generator {
  yield takeLatest(INIT_HEADER_BANNERS, getHeaderBanner);
}

import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import type { ActionWithPayload } from '@/domains/core/applicationState/interfaces';
import { useOnClickOutside } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useOnClickOutside';

import { usePromosVisibility } from '../../hooks/usePromosVisibility';
import {
  setPromosVisibility,
  type SET_PROMOS_VISIBILITY,
} from '../../store/HeaderLeonidas.actions';

interface UsePromos {
  onCloseButton: () => ActionWithPayload<
    { visible: boolean; pageYOffset?: number },
    typeof SET_PROMOS_VISIBILITY
  >;
  isPromosVisible: boolean | undefined;
  promosRef: React.MutableRefObject<null>;
}

export const usePromos = (): UsePromos => {
  const dispatch = useDispatch();
  const isPromosVisible = usePromosVisibility();
  const promosRef = useRef(null);
  const onCloseButton = () => dispatch(setPromosVisibility(false));
  useOnClickOutside(promosRef, onCloseButton, isPromosVisible);
  return { isPromosVisible, onCloseButton, promosRef };
};

import type { FunctionComponent } from 'react';

import { Button } from '@/core/tamagoshiTailwind/components/Button/Button';
import { ChevronLeftIcon } from '@/core/tamagoshiTailwind/icons/system/ChevronLeftIcon';
import { CrossIcon } from '@/core/tamagoshiTailwind/icons/system/CrossIcon';
import { useMatchMedia } from '@/core/ui/hooks/useMatchMedia';

interface Props {
  onClick: () => void;
}

export const CloseSuggestionsButton: FunctionComponent<Props> = ({
  onClick,
}) => {
  const isDesktop = useMatchMedia('desktop');
  const Icon = isDesktop ? CrossIcon : ChevronLeftIcon;

  return (
    <Button
      className="size-[30px] rounded-full border-0 p-0 desktop:absolute
        desktop:right-[-40px] desktop:top-0"
      onClick={onClick}
      icon={Icon}
      variant="tertiary"
      accessibilityLabel="close-searchbar"
    />
  );
};

CloseSuggestionsButton.displayName = 'CloseSuggestionsButton';

import {
  SEARCH_HISTORY_LIMIT,
  SEARCH_HISTORY_SEPARATOR,
  SEARCH_HISTORY_STORAGE_KEY,
} from './constants';
import { isLocalStorageAvailable } from './utils/localStorage';
import { queryCleaner } from './utils/queryCleaner';

export const saveInSearchHistory = (query: string): boolean => {
  const searchQuery = queryCleaner(query);

  if (isLocalStorageAvailable() && searchQuery) {
    const storage = localStorage.getItem(SEARCH_HISTORY_STORAGE_KEY);

    if (!storage) {
      localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, searchQuery);
    } else {
      let entries = storage.split(SEARCH_HISTORY_SEPARATOR);

      if (!entries.includes(searchQuery)) {
        if (entries.length >= SEARCH_HISTORY_LIMIT) {
          entries.pop();
        }
      } else {
        entries = entries.filter((entry) => entry !== searchQuery);
      }

      entries.unshift(searchQuery);
      localStorage.setItem(
        SEARCH_HISTORY_STORAGE_KEY,
        entries.join(SEARCH_HISTORY_SEPARATOR),
      );
    }
    return true;
  }
  return false;
};

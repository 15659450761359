import { useEffect, useState, type FunctionComponent } from 'react';

import { Typography } from '@/core/tamagoshiTailwind/components/Typography/Typography';
import {
  countdownDays,
  countdownDaysLeft,
  countdownHours,
  countdownMinutes,
  countdownSeconds,
  countdownTimeLeft,
} from '@/productDiscovery/HeaderLeonidas/components/PreHeader/components/Countdown/translations';

const MAX_DAYS_TO_DISPLAY_COUNTDOWN = 6;

interface Props {
  countdownEndAt: string;
  displayedInModal?: boolean;
}

export const Countdown: FunctionComponent<Props> = ({
  countdownEndAt,
  displayedInModal,
}) => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const countdownTimestamp = new Date(countdownEndAt).getTime();

  if (countdownTimestamp < now) {
    return null;
  }

  // get total seconds between the times
  let delta = Math.abs(countdownTimestamp - now) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  if (days > MAX_DAYS_TO_DISPLAY_COUNTDOWN) {
    return null;
  }

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  const hoursFormatted = String(hours).padStart(2, '0');
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  const minutesFormatted = String(minutes).padStart(2, '0');
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = Math.floor(delta % 60);
  const secondsFormatted = String(seconds).padStart(2, '0');

  if (displayedInModal) {
    return days > 0 ? (
      <div className="mx-auto grid grid-cols-3 gap-m text-center">
        <div>
          <Typography scale="title1" as="h4" data-testid="countdown-days-value">
            {String(days).padStart(2, '0')}
          </Typography>
          <Typography scale="body1" data-testid="countdown-days-label">
            {countdownDays}
          </Typography>
        </div>
        <div>
          <Typography
            scale="title1"
            as="h4"
            data-testid="countdown-hours-value"
          >
            {String(hours).padStart(2, '0')}
          </Typography>
          <Typography scale="body1" data-testid="countdown-hours-label">
            {countdownHours}
          </Typography>
        </div>
        <div>
          <Typography
            scale="title1"
            as="h4"
            data-testid="countdown-minutes-value"
          >
            {String(minutes).padStart(2, '0')}
          </Typography>
          <Typography scale="body1" data-testid="countdown-minutes-label">
            {countdownMinutes}
          </Typography>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-3 gap-m text-center">
        <div>
          <Typography
            scale="title1"
            as="h4"
            data-testid="countdown-hours-value"
          >
            {String(hours).padStart(2, '0')}
          </Typography>
          <Typography scale="body1" data-testid="countdown-hours-label">
            {countdownHours}
          </Typography>
        </div>
        <div>
          <Typography
            scale="title1"
            as="h4"
            data-testid="countdown-minutes-value"
          >
            {String(minutes).padStart(2, '0')}
          </Typography>
          <Typography scale="body1" data-testid="countdown-minutes-label">
            {countdownMinutes}
          </Typography>
        </div>
        <div>
          <Typography
            scale="title1"
            as="h4"
            data-testid="countdown-secondes-value"
          >
            {String(seconds).padStart(2, '0')}
          </Typography>
          <Typography scale="body1" data-testid="countdown-secondes-label">
            {countdownSeconds}
          </Typography>
        </div>
      </div>
    );
  }

  return days > 0 ? (
    <span className="ml-s">{countdownDaysLeft.format({ days })}</span>
  ) : (
    <span className="ml-s">
      {countdownTimeLeft.format({
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
      })}
    </span>
  );
};

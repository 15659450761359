import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApplicationMounted } from '@/domains/core/applicationState/hooks/useApplicationMounted';
import { useLazySaga } from '@/domains/core/applicationState/hooks/useLazySaga';
import { GET_HEADER_MENU } from '@/domains/productDiscovery/HeaderLeonidas/Header.actions';
import { selectMenuCategories } from '@/domains/productDiscovery/HeaderLeonidas/selectors/selectors';
import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';
import type { MenuCategory } from '@/productDiscovery/HeaderLeonidas/interfaces/MenuTree.model';

const MenuSaga = () =>
  import('@/productDiscovery/HeaderLeonidas/sagas/Menu.client.saga').then(
    (mod) => mod.rootSaga,
  );

export const useLazyHeader = (condition: boolean) => {
  const categories = useSelector<
    SpartacuxProductDiscoveryDomainState,
    MenuCategory[] | undefined
  >(selectMenuCategories);
  const dispatch = useDispatch();
  const { isClient } = useApplicationMounted();
  const { sagaInjected } = useLazySaga(
    'productDiscovery.header_categories',
    MenuSaga,
    {
      condition: isClient && condition,
    },
  );

  const [hasCategories, setHasCategories] = useState(false);

  useEffect(() => {
    const isMenuFilledWithCategories = () => {
      if (!categories) return false;
      return categories.reduce(
        (acc, cat) => acc || (cat.children ? cat.children?.length > 0 : false),
        false,
      );
    };
    if (sagaInjected && !isMenuFilledWithCategories() && condition) {
      dispatch({
        type: GET_HEADER_MENU,
      });
    } else if (isMenuFilledWithCategories() && !hasCategories) {
      setHasCategories(true);
    }
  }, [sagaInjected, dispatch, categories, hasCategories, condition]);

  return { isReady: hasCategories };
};

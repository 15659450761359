import { useSelector, useStore } from 'react-redux';

import { OVERLAY_NONE } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.model';

import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';

export const overlaySelector = (
  state: SpartacuxProductDiscoveryDomainState,
): string | undefined =>
  state.productDiscovery?.headerLeonidas?.ui.overlay || OVERLAY_NONE;

export const useOverlay = () => useSelector(overlaySelector);

export const useGetOverlay = () => {
  const store = useStore();

  return () =>
    overlaySelector(store.getState() as SpartacuxProductDiscoveryDomainState);
};

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import type { PageState } from '@/core/page/page.state';

import {
  SET_PAGE_DATA,
  SET_PAGE_REFERER,
  SET_PAGE_STATUS_CODE,
} from '../page.actions';

/**
 * Allows to put some information relative to the current page into Redux store
 * in order to use in tracking.
 */
export function usePageData({
  pageName,
  pageType,
  pageViewCustomData = {},
  pageCustomData = {},
}: PageState): void {
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    if (router) {
      const listener = () => {
        // Has to be done before page rendering
        dispatch({ type: SET_PAGE_STATUS_CODE, statusCode: undefined });
        dispatch({ type: SET_PAGE_REFERER, referer: window.location.href });
      };

      const htmlTag = document.documentElement;
      const listenerStart = () =>
        htmlTag.setAttribute('data-smoothscroll', 'disabled');
      const listenerComplete = () =>
        htmlTag.removeAttribute('data-smoothscroll');
      router.events.on('beforeHistoryChange', listener);
      router.events.on('routeChangeStart', listenerStart);
      router.events.on('routeChangeComplete', listenerComplete);

      return () => {
        router.events.off('beforeHistoryChange', listener);
        router.events.off('routeChangeStart', listenerStart);
        router.events.off('routeChangeComplete', listenerComplete);
      };
    }
  }, [dispatch, router]);

  const pageViewCustomDataAsString = JSON.stringify(pageViewCustomData);
  const pageCustomDataAsString = JSON.stringify(pageCustomData);

  useEffect(() => {
    const pageViewCustomDataAsObject = JSON.parse(pageViewCustomDataAsString);
    const pageCustomDataAsObject = JSON.parse(pageCustomDataAsString);
    dispatch({
      type: SET_PAGE_DATA,
      pageName,
      pageType,
      pageViewCustomData: pageViewCustomDataAsObject,
      pageCustomData: pageCustomDataAsObject,
    });
  }, [
    dispatch,
    pageName,
    pageType,
    pageViewCustomDataAsString,
    pageCustomDataAsString,
  ]);
}

import type { CartOverview } from '@/domains/shoppingCart/CartOverview/interfaces/CartOverview';

import type { ApiCartOverview, ApiCartOverviewContent } from '../interfaces';

export const formatForCartOverview = (
  apiCartOverview: ApiCartOverview | ApiCartOverviewContent | undefined,
): CartOverview => {
  const formattedForCartOverview: CartOverview = {
    contents: {},
    productCount: 0,
    itemsCount: 0,
    id: 0,
    productsTotalPrice: 0,
  };
  const items = apiCartOverview?.products;
  if (!apiCartOverview || !items || items.length === 0) {
    return formattedForCartOverview;
  }

  formattedForCartOverview.contents = items
    .map(({ quantity, id }) => ({ [id]: quantity }))
    .reduce((a, b) => ({ ...a, ...b }));

  formattedForCartOverview.itemsCount = items
    .map(({ quantity }) => quantity)
    .reduce((a, b) => a + b);

  formattedForCartOverview.productCount = items.length;
  formattedForCartOverview.id = apiCartOverview.id;
  return formattedForCartOverview;
};

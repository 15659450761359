import classnames from 'classnames';
import type { ElementType, FunctionComponent, PropsWithChildren } from 'react';

import styles from './Tag.module.scss';

export type TagColor =
  | 'default'
  | 'yellow'
  | 'beige'
  | 'red'
  | 'darkBlue'
  | 'blue'
  | 'green'
  | 'lightGreen'
  | 'lightBlue'
  | 'lightGrey'
  | 'black'
  | 'orange'
  | 'pro';

export interface TagProps extends PropsWithChildren<unknown> {
  className?: string;
  as?: ElementType | string;
  color?: TagColor;
  border?: boolean;
  revamp?: boolean;
  b2b?: boolean;
}

export const Tag: FunctionComponent<TagProps> = ({
  className,
  as: Component = 'div',
  color = 'default',
  border,
  children,
  revamp,
  b2b,
}) => {
  const rootClassnames = classnames(
    styles.root,

    {
      [styles.default]: color === 'default',
      [styles.yellow]: color === 'yellow',
      [styles.beige]: color === 'beige',
      [styles.red]: color === 'red',
      [styles.darkBlue]: color === 'darkBlue',
      [styles.blue]: color === 'blue',
      [styles.green]: color === 'green',
      [styles.lightBlue]: color === 'lightBlue',
      [styles.lightGrey]: color === 'lightGrey',
      [styles.lightGreen]: color === 'lightGreen',
      [styles.black]: color === 'black',
      [styles.orange]: color === 'orange',
      [styles.borderColor]: border,
      [styles.revamp]: revamp,
      [styles.pro]: b2b,
    },
    className,
  );

  return <Component className={rootClassnames}>{children}</Component>;
};

Tag.displayName = 'Tag';

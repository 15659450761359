import classnames from 'classnames';
import { useMemo, type FunctionComponent } from 'react';

import { isB2B } from '@/core/settings/utils';
import { useSearchAIEnabled } from '@/domains/productDiscovery/Listings/modules/SearchAI/hooks/useSearchAIEnabled';
import { useSearchAIQueries } from '@/domains/productDiscovery/Listings/modules/SearchAI/hooks/useSearchAIQueries';
import { MultipleProductsListsWrapper } from '@/productDiscovery/HeaderLeonidas/modules/productSuggestions/modules/emptySearchResults/components/MultipleProductsListsWrapper/MultipleProductsListsWrapper';
import { SuggestionBlock } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/SuggestionBlock/SuggestionBlock';
import { useRemoveAllSuggestionsHistory } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/hooks/useRemoveAllSuggestionsHistory';
import { useRemoveHistorySuggestion } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/hooks/useRemoveHistorySuggestion';
import { useSuggestionClick } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/hooks/useSuggestionClick';
import {
  emptySearchB2B as B2BTranslations,
  emptySearchB2C as B2CTranslations,
  deleteHistory,
  searchAI as searchAITranslations,
  searchHistory as searchHistoryTranslations,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/translations';
import { calculateStartingPosition } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/utils/calculateStartingPosition';
import {
  EMPTYSEARCH_NB_QUERIES_WITH_HISTORY,
  EMPTYSEARCH_NB_SUGGESTIONS_WITH_HISTORY,
  PRODUCTS_IN_SEARCHBAR_RECO_ENGINE,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/constants';
import { useAvailableSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useAvailableSuggestions';
import { useFetchingSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useFetchingSuggestions';
import { useSuggestionsEmptySearch } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSuggestionsEmptySearch';
import { AvailableSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/suggestions';

import { SuggestionsSkeleton } from '../SuggestionsSkeleton/SuggestionsSkeleton';

import styles from '../../Suggestions.module.scss';

const translations = isB2B() ? B2BTranslations : B2CTranslations;

export const EmptySearchSuggestions: FunctionComponent = () => {
  const searchAIEnabled = useSearchAIEnabled();
  const onRemoveAllHistorySuggestions = useRemoveAllSuggestionsHistory();
  const availableSuggestions = useAvailableSuggestions();
  const {
    histories,
    queries,
    topProducts,
    similarityProducts,
    categories,
    brands,
  } = availableSuggestions || {};
  const onRemoveHistorySuggestion = useRemoveHistorySuggestion();

  const values = availableSuggestions
    ? Object.values(availableSuggestions).flat()
    : [];

  const handleSuggestionClick = useSuggestionClick({
    values,
  });
  const searchAIQueries = useSearchAIQueries();

  // NOTE Temporary conditional values for SearchAI A/BTest
  const queriesSuggestions = searchAIEnabled ? searchAIQueries : queries;
  const titleQueriesSuggestions = searchAIEnabled
    ? searchAITranslations.title
    : translations.searchQueryTitle;

  return (
    <>
      <DisplaySuggestionsSkeleton />

      {histories && histories.length > 0 && (
        <SuggestionBlock
          suggestions={histories}
          title={
            searchAIEnabled
              ? searchAITranslations.history
              : searchHistoryTranslations.title
          }
          onClick={handleSuggestionClick}
          onRemove={onRemoveHistorySuggestion}
          startingPosition={calculateStartingPosition()}
          onActionLinkClick={onRemoveAllHistorySuggestions}
          actionLinkTitle={deleteHistory.title}
        />
      )}

      {queriesSuggestions && queriesSuggestions.length > 0 && (
        <SuggestionBlock
          suggestions={
            histories?.length
              ? queriesSuggestions.slice(0, EMPTYSEARCH_NB_QUERIES_WITH_HISTORY)
              : queriesSuggestions
          }
          title={titleQueriesSuggestions}
          onClick={handleSuggestionClick}
          onRemove={onRemoveHistorySuggestion}
          startingPosition={calculateStartingPosition(histories?.length)}
        />
      )}

      <ProductList
        topProducts={topProducts}
        similarityProducts={similarityProducts}
      />

      {categories && categories.length > 0 && (
        <SuggestionBlock
          suggestions={
            histories?.length
              ? categories.slice(0, EMPTYSEARCH_NB_SUGGESTIONS_WITH_HISTORY)
              : categories
          }
          title={translations.searchCategoryTitle}
          onClick={handleSuggestionClick}
          onRemove={onRemoveHistorySuggestion}
          startingPosition={calculateStartingPosition(
            histories?.length,
            queries?.length,
          )}
        />
      )}

      {brands && brands.length > 0 && (
        <SuggestionBlock
          suggestions={
            histories?.length
              ? brands.slice(0, EMPTYSEARCH_NB_SUGGESTIONS_WITH_HISTORY)
              : brands
          }
          title={translations.searchBrandTitle}
          onClick={handleSuggestionClick}
          onRemove={onRemoveHistorySuggestion}
          startingPosition={calculateStartingPosition(
            histories?.length,
            queries?.length,
            categories?.length,
          )}
        />
      )}
    </>
  );
};

EmptySearchSuggestions.displayName = 'EmptySearchSuggestions';

function DisplaySuggestionsSkeleton() {
  const emptySearchSuggestions = useSuggestionsEmptySearch();
  const isFetchingSuggestions = useFetchingSuggestions();

  const mustDisplaySkeleton =
    isFetchingSuggestions && !Object.keys(emptySearchSuggestions ?? {}).length;
  return <>{mustDisplaySkeleton && <SuggestionsSkeleton />}</>;
}

function ProductList({
  topProducts,
  similarityProducts,
}: AvailableSuggestions) {
  const emptySearchSuggestions = useSuggestionsEmptySearch();
  const products = useMemo(() => {
    const products = [];
    if (topProducts?.length) {
      products.push({
        products: topProducts,
        type: emptySearchSuggestions?.topProducts?.type,
      });
    }

    if (similarityProducts?.length) {
      products.push({
        products: similarityProducts,
        type: emptySearchSuggestions?.similarityProducts?.type,
        engineName: PRODUCTS_IN_SEARCHBAR_RECO_ENGINE,
      });
    }

    return products;
  }, [emptySearchSuggestions, similarityProducts, topProducts]);

  return (
    <>
      {products && products.length > 0 && (
        <MultipleProductsListsWrapper
          tag="li"
          className={classnames(
            styles.suggestionBlock,
            styles.productSuggestions,
            styles.multipleProductsLists,
          )}
          productsList={products}
        />
      )}
    </>
  );
}

import { twJoin } from 'tailwind-merge';

import { ChevronLeftIcon } from '@/core/tamagoshiTailwind/icons/system/ChevronLeftIcon';
import { ChevronRightIcon } from '@/core/tamagoshiTailwind/icons/system/ChevronRightIcon';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';

type Props = {
  disabledLeft?: boolean;
  disabledRight?: boolean;
  onLeftClick: () => void;
  onRightClick: () => void;
  titleLeft: string;
  titleRight: string;
};

const buttonClassName = twJoin(
  `hidden h-[24px] w-[24px] flex-shrink-0 items-center justify-center
  rounded-full border border-foundation-primary bg-foundation-inverted opacity-0
  transition-opacity duration-300 ease-out hover:border-foundation-tertiary
  hover:shadow-hover disabled:cursor-default disabled:border-foundation-disabled
  disabled:bg-foundation-disabled disabled:text-foundation-disabled
  group-hover:z-[11] group-hover:opacity-100 desktop:flex [&_svg]:h-[16px]
  [&_svg]:w-[16px]`,
);

export const QuickAccessLinksArrows = ({
  disabledLeft,
  disabledRight,
  onLeftClick,
  onRightClick,
  titleLeft,
  titleRight,
}: Props) => (
  <div className="absolute flex w-full justify-between px-xs">
    <button
      className={twMerge(
        buttonClassName,
        disabledLeft && 'group-hover:opacity-0',
      )}
      aria-label={titleLeft}
      disabled={disabledLeft}
      title={titleLeft}
      onClick={onLeftClick}
      data-testid="quick-access-links-control-left"
    >
      <ChevronLeftIcon />
    </button>
    <button
      className={twMerge(
        buttonClassName,
        disabledRight && 'group-hover:opacity-0',
      )}
      aria-label={titleRight}
      disabled={disabledRight}
      title={titleRight}
      onClick={onRightClick}
      data-testid="quick-access-links-control-right"
    >
      <ChevronRightIcon />
    </button>
  </div>
);

QuickAccessLinksArrows.displayName = 'QuickAccessLinksArrows';

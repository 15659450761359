import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';
import { pageTypeSelector } from '@/domains/core/page/page.selectors';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { PreHeaderModalProps } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/components/PreHeaderModal/PreHeaderModal';
import { GTM_CLICK_ON_PRE_HEADER } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/constants';
import type { PreHeaderModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';
import { PreHeaderItem } from '@/productDiscovery/HeaderLeonidas/components/PreHeader/components/PreHeaders/PreHeaderItem';

const DynamicPreHeaderModal = dynamic<PreHeaderModalProps>(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/components/PreHeaderModal/PreHeaderModal'
      )
    ).PreHeaderModal,
);

const DELAY_BEFORE_ANIMATION = 2500;
const MESSAGE_CHANGE_INTERVAL = 5000;

export interface RotatingPreHeadersProps {
  preHeaders: PreHeaderModel[];
}

export const PreHeaders = ({ preHeaders }: RotatingPreHeadersProps) => {
  const pageType = useSelector(pageTypeSelector);
  const [preHeaderDisplayed, setPreHeaderDisplayed] = useState(0);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [displayPreHeaderModal, setDisplayPreHeaderModal] = useState(false);

  useEffect(() => {
    const initTimeout = setTimeout(() => {
      setAnimationStarted(preHeaders.length > 1);
      // setAnimationStarted(true);
    }, DELAY_BEFORE_ANIMATION);

    return () => clearTimeout(initTimeout);
  }, [preHeaders?.length]);

  useEffect(() => {
    const changePreHeaderDisplayedTimeout = setInterval(() => {
      setPreHeaderDisplayed(
        (prevPreHeaderDisplayed) =>
          (prevPreHeaderDisplayed + 1) % (preHeaders?.length ?? 0),
      );
    }, MESSAGE_CHANGE_INTERVAL);

    return () => clearInterval(changePreHeaderDisplayedTimeout);
  }, [preHeaders?.length]);

  if (!preHeaders || !preHeaders.length) {
    return null;
  }

  const showModal = () => {
    Gtm.push(
      GTM_CLICK_ON_PRE_HEADER(
        preHeaders[preHeaderDisplayed].message,
        'multiple',
        pageType,
      ),
    );

    setDisplayPreHeaderModal(true);
  };
  const closeModal = () => {
    setDisplayPreHeaderModal(false);
  };

  return (
    <>
      <PreHeaderItem
        preHeader={preHeaders[preHeaderDisplayed]}
        className={twMerge(
          'w-full animate-blink',
          !animationStarted && 'opacity-100',
        )}
        onClick={showModal}
      />
      {displayPreHeaderModal && (
        <DynamicPreHeaderModal onClose={closeModal} preHeaders={preHeaders} />
      )}
    </>
  );
};

PreHeaders.displayName = 'PreHeaders';

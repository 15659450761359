import interactionResponse from 'await-interaction-response';
import { useRouter } from 'next/router';
import type React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FunctionComponent,
} from 'react';
import { useDispatch } from 'react-redux';

import { useLatestRef } from '@/core/data/hooks/useLatestRef';
import { usePageChange } from '@/core/routing/hooks/usePageChange';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { useSearchAIEnabled } from '@/domains/productDiscovery/Listings/modules/SearchAI/hooks/useSearchAIEnabled';
import { setFocusedSuggestionPosition } from '@/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { saveInSearchHistory } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/utils/searchHistory/save';
import {
  getAutoCompleteSuggestions,
  getEmptySearchSuggestions,
  resetAutoCompleteSuggestions,
  setSearchInput,
  setSearchSuggestionsVisibility,
  suggestionsFetchingStatus,
  updateEmptySearchSuggestions,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.actions';
import { useAvailableSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useAvailableSuggestions';
import { useFetchingSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useFetchingSuggestions';
import { useSearchInput } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchInput';
import { useSearchSuggestionsVisibility } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchSuggestionsVisibility';
import { useSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSuggestions';
import { useSuggestionsEmptySearch } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSuggestionsEmptySearch';
import {
  GTM_CLICK_ON_SEARCH_BAR,
  GTM_ON_SEARCH_QUERY,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/services/tracking/gtmEvents.search';
import { createRedirectionURL } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/utils/createRedirectionURL/createRedirectionURL';
import { debounce } from '@/productDiscovery/HeaderLeonidas/utils/debounce';
import { isMobile } from '@/productDiscovery/HeaderLeonidas/utils/isMobile';

import { CloseSuggestionsButton } from '../CloseSuggestionsButton/CloseSuggestionsButton';
import { SearchFormControls } from '../SearchFormControls/SearchFormControls';
import { SearchFormInput } from '../SearchFormInput/SearchFormInput';

export const SearchForm: FunctionComponent = () => {
  const searchAIEnabled = useSearchAIEnabled();
  const searchValue = useSearchInput();

  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const searchSuggestionsVisible = useSearchSuggestionsVisibility();
  const suggestions = useSuggestions();
  const availableSuggestions = useAvailableSuggestions();

  const { push } = useRouter();
  const isFetchingSuggestions = useFetchingSuggestions();
  const [waitForSuggestionsRedirection, setWaitForSuggestionsRedirection] =
    useState(false);
  const emptySearchSuggestions = useSuggestionsEmptySearch();
  const latestAvailableSuggestions = useLatestRef(availableSuggestions);

  usePageChange(() => {
    if (inputRef.current && inputRef.current.value !== searchValue) {
      inputRef.current.value = searchValue;
    }
  }, [searchValue]);

  // NOTE Unfocus the input field to avoid unintentional opening
  //      of the suggestions panel
  const unFocusInputField = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleOnFocus = async () => {
    if (searchValue) {
      dispatch(setSearchInput(searchValue));
      dispatch(getAutoCompleteSuggestions(searchValue));
    } else {
      dispatch(updateEmptySearchSuggestions());
    }
    if (!searchSuggestionsVisible) {
      await interactionResponse();
      dispatch(setSearchSuggestionsVisibility(true));
      dispatch(setFocusedSuggestionPosition(0));
      await interactionResponse();

      Gtm.push(
        GTM_CLICK_ON_SEARCH_BAR({
          searchValue,
          suggestions: availableSuggestions,
          emptySearchSuggestions,
        }),
      );
    }
  };

  const updateSuggestions = useCallback(
    async (value: string) => {
      if (value.length > 0) {
        dispatch(getAutoCompleteSuggestions(value));
      } else {
        dispatch(getEmptySearchSuggestions());

        if (isMobile() && !latestAvailableSuggestions.current) {
          await interactionResponse();
          dispatch(setSearchSuggestionsVisibility(false));
        }
      }
    },
    [dispatch, latestAvailableSuggestions],
  );

  const updateInputValue = useMemo(
    () =>
      debounce(
        async (value: string) => {
          dispatch(setSearchInput(value));
          await interactionResponse();
          updateSuggestions(value);
        },
        searchValue.length < 1 ? 0 : 500,
      ),
    [dispatch, updateSuggestions, searchValue],
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    if (!isFetchingSuggestions) {
      dispatch(suggestionsFetchingStatus(true));
    }
    await updateInputValue(event.target.value);
  };

  const doRedirection = useCallback(
    async ({ url, isRedirected }: { url: string; isRedirected: boolean }) => {
      dispatch(setSearchSuggestionsVisibility(false));

      push(url, undefined, {
        shallow: false,
      });

      await interactionResponse();
      Gtm.push(
        GTM_ON_SEARCH_QUERY({
          hasUsedAutocomplete: false,
          searchQuery: searchValue,
          isRedirectedFromSearch: isRedirected,
        }),
      );
    },
    [dispatch, searchValue, push],
  );

  /**
   * Handle direct submit (press enter and is not waiting for suggestions)
   */
  const handleSubmit = async (
    event: React.SyntheticEvent | React.KeyboardEvent,
  ) => {
    event.preventDefault();
    unFocusInputField();

    if (searchValue) {
      await interactionResponse();
      saveInSearchHistory(searchValue);

      if (!isFetchingSuggestions && searchValue === suggestions?.termQuery) {
        doRedirection(
          createRedirectionURL(searchValue, suggestions?.redirections),
        );
      } else {
        setWaitForSuggestionsRedirection(true);
      }
    }
  };

  const onSearchBarClose = async () => {
    dispatch(setSearchSuggestionsVisibility(false));
  };

  /**
   * Handle delayed submit
   */
  useEffect(() => {
    (async () => {
      if (
        waitForSuggestionsRedirection &&
        !isFetchingSuggestions &&
        !!searchValue &&
        searchValue === suggestions?.termQuery
      ) {
        setWaitForSuggestionsRedirection(false);
        doRedirection(
          createRedirectionURL(searchValue, suggestions?.redirections),
        );
      }
    })();
  }, [
    doRedirection,
    waitForSuggestionsRedirection,
    suggestions,
    searchValue,
    isFetchingSuggestions,
  ]);

  const handleResetSearch = async () => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.value = '';
    }
    dispatch(setSearchInput(''));
    await interactionResponse();
    dispatch(resetAutoCompleteSuggestions());
    await interactionResponse();
    dispatch(getEmptySearchSuggestions());

    if (isMobile() && !availableSuggestions) {
      dispatch(setSearchSuggestionsVisibility(false));
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      await interactionResponse();
      dispatch(setFocusedSuggestionPosition(1));
    } else if (event.key === 'Escape') {
      unFocusInputField();
      await interactionResponse();
      dispatch(setSearchSuggestionsVisibility(false));
    } else if (!searchSuggestionsVisible) {
      await interactionResponse();
      dispatch(setSearchSuggestionsVisibility(true));
    }
  };

  return (
    <div
      className={twMerge(
        // 17/03/2025: Due to conflicting style issues, do not use twJoin here until the end of the DS migration
        'relative box-border flex',
        searchSuggestionsVisible &&
          `fixed left-0 right-0 top-0 flex-row flex-nowrap items-center
          justify-start border-b border-solid border-foundation-secondary
          bg-foundation-inverted p-m desktop:items-start desktop:rounded-[28px]
          desktop:border-none desktop:p-s`,
      )}
    >
      {searchSuggestionsVisible && (
        <CloseSuggestionsButton onClick={onSearchBarClose} />
      )}
      <form
        className="relative block w-full"
        onSubmit={handleSubmit}
        data-testid="search-bar"
        action="."
      >
        <SearchFormInput
          suggestionsDisplayed={searchSuggestionsVisible}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleOnFocus}
          searchAI={searchAIEnabled}
          searchValue={searchValue}
          ref={inputRef}
          opened={searchSuggestionsVisible}
        />
        <SearchFormControls
          hasValue={searchValue !== ''}
          onReset={handleResetSearch}
          searchAI={searchAIEnabled}
        />
      </form>
    </div>
  );
};

SearchForm.displayName = 'SearchForm';

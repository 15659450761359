import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const ShoppingCartOutline: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconsSystemShoppingCartOutline"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M15.84 13H8.26l-1.12 2h11.2c.55 0 1.01.45 1.01 1s-.46 1-1.02 1H7.14a2 2 0 0 1-1.78-2.97l1.37-2.44L3.07 4H2.05c-.56 0-1.01-.45-1.01-1s.45-1 1.01-1h2.31l4.34 9h7.14l3.29-6.1c.26-.49.88-.68 1.38-.41a.99.99 0 0 1 .4 1.34l-3.29 6.14A2.03 2.03 0 0 1 15.84 13Zm-8.7 5c-1.12 0-2.02.9-2.02 2s.9 2 2.02 2 2.04-.9 2.04-2-.92-2-2.04-2Zm10.18 0c-1.12 0-2.03.9-2.03 2s.9 2 2.03 2c1.12 0 2.03-.9 2.03-2s-.91-2-2.03-2Z"
      fill="currentColor"
    />
  </Svg>
);

ShoppingCartOutline.displayName = 'ShoppingCartOutline';

import classnames from 'classnames';
import type { ComponentType, FunctionComponent } from 'react';

import type { Currency } from '@/core/tamagoshi/deprecated/PriceDeprecated/utils/currency';
import type { MerchandisingLabel } from '@/core/tamagoshi/MerchandisingTag/interfaces';

import { DetailedPrice } from '../DetailedPrice/DetailedPrice';
import { MerchandisingTag } from '../MerchandisingTag/MerchandisingTag';
import { Image, type ImageAsImageProps } from './components/Image';
import type { MiniProductCardTranslations } from './interfaces';

import styles from './MiniProductCard.module.scss';

export interface MiniProductCardProps {
  /**
   * Title displayed on the right
   */
  title: string;
  /**
   * Product image source path
   */
  imageSrc: string;
  /**
   * Main price
   */
  price: number;
  /**
   * The currency for euro or pound sterling
   */
  currency: Currency;
  /**
   * The anchor link to the page you want
   */
  href: string;
  /**
   * Root element className
   */
  className?: string;
  /**
   * retail price is the crossed out price
   */
  retailPrice?: number;
  /**
   * brand name text
   */
  brandName?: string;
  /**
   * brand image source url
   */
  brandImageSrc?: string;
  /**
   * If you want to change the default anchor ('a' tag) by your component you
   * can use this props
   */
  as?: ComponentType<
    Pick<MiniProductCardProps, 'href' | 'onClick' | 'className'> &
      React.PropsWithChildren
  >;
  /**
   * Change the orientation of the product card to vertical
   */
  vertical?: boolean;
  /**
   * If you want to change the default image component ('img' tag) by your
   * component you can use this props
   */
  imageAs?: ImageAsImageProps;
  /**
   * Indicates how the browser should load the image
   */
  imageLoading?: 'eager' | 'lazy';
  /**
   * Type of merchandising label to display
   */
  labelType?: MerchandisingLabel;
  /**
   * Used to intercept clicks on MiniProductCard (ie : tracking, action
   * dispatching…)
   */
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): unknown;
  /**
   * All translations of the component
   */
  translations?: MiniProductCardTranslations;
  /**
   * Used to intercept clicks on sponsoredLabel (ie : tracking, action
   * dispatching…)
   */
  onAdvertiserLabelClicked?(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): unknown;
}

export const MiniProductCard: FunctionComponent<MiniProductCardProps> = ({
  className,
  imageSrc,
  brandImageSrc,
  brandName,
  title,
  price,
  retailPrice,
  currency,
  vertical = false,
  imageAs,
  as: Component = 'a',
  imageLoading = 'eager',
  onClick,
  translations,
  href,
  labelType,
  onAdvertiserLabelClicked,
}) => {
  const rootClassnames = classnames(styles.root, className, {
    [styles.vertical]: vertical,
  });

  return (
    <Component className={rootClassnames} onClick={onClick} href={href}>
      <Image
        className={classnames(styles.image, vertical && styles.vertical)}
        src={imageSrc}
        alt={brandName || title}
        width={vertical ? 110 : 80}
        height={vertical ? 100 : 80}
        loading={imageLoading}
        as={imageAs}
      />
      {brandImageSrc && (
        <Image
          className={classnames(styles.brandImage, vertical && styles.vertical)}
          src={brandImageSrc}
          alt={`${brandName} image`}
          width={vertical ? 32 : 40}
          height={vertical ? 16 : 15}
          as={imageAs}
        />
      )}
      {vertical && (
        <>
          {labelType ? (
            <MerchandisingTag
              className={styles.tag}
              tag={labelType}
              translations={translations?.tags}
              onAdvertiserLabelClicked={onAdvertiserLabelClicked}
            />
          ) : (
            <div className={styles.separator} />
          )}
        </>
      )}
      <div className={styles.title}>{title}</div>
      <DetailedPrice
        className={classnames(styles.price, {
          [styles.promo]: labelType === 'promo',
        })}
        value={price}
        currency={currency}
        retailValue={retailPrice}
        placeholderDisabled
      />
    </Component>
  );
};

MiniProductCard.displayName = 'MiniProductCard';

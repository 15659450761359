import { useCallback, useRef, useState, type RefObject } from 'react';
import { useDispatch } from 'react-redux';

import type {
  ScrollState,
  SliderHandle,
} from '@/core/tamagoshi/Carousel/components/Slider/Slider';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { setMenuVisibility } from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useQuickAccessLinks } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useQuickAccessLinks';
import type { QuickAccessLinkModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';
import { GTM_CLICK_ON_QUICK_ACCESS } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';

interface VisibilityState {
  leftVisible: boolean;
  rightVisible: boolean;
  position: number;
  max: number;
}

type Direction = 'right' | 'left';

export interface ClickParams {
  title: string;
  url: string;
  index: number;
}

export interface UseQuickAccessLinks {
  fetchingQuickAccessLinks: boolean;
  quickAccessLinks?: QuickAccessLinkModel[];
  leftVisible: boolean;
  rightVisible: boolean;
  onControlClick: (direction: Direction) => void;
  sliderRef: RefObject<SliderHandle>;
  onScrollChange: (sliderState: ScrollState) => void;
  handleClick: (params: ClickParams) => void;
}

export const useQuickAccessLinksData = (): UseQuickAccessLinks => {
  const [visibilityState, setVisibilityState] = useState<VisibilityState>({
    leftVisible: false,
    rightVisible: true,
    position: 0,
    max: 1,
  });

  const dispatch = useDispatch();

  const quickAccessLinks = useQuickAccessLinks();

  const sliderRef = useRef<SliderHandle>(null);

  const onControlClick = useCallback(
    (direction: Direction) => sliderRef.current?.scrollTo(direction),
    [],
  );

  const onScrollChange = useCallback(
    (sliderState: ScrollState) => {
      const leftVisible = sliderState.position > sliderState.min;
      const rightVisible = sliderState.position < sliderState.max;
      if (
        sliderState.position !== visibilityState.position ||
        sliderState.max !== visibilityState.max
      ) {
        setVisibilityState({
          leftVisible,
          rightVisible,
          position: sliderState.position,
          max: sliderState.max,
        });
      }
    },
    [visibilityState.max, visibilityState.position],
  );

  const handleClick = useCallback(
    ({ title, url, index }: ClickParams) => {
      dispatch(setMenuVisibility(false));
      Gtm.push(GTM_CLICK_ON_QUICK_ACCESS({ title, url }, index + 1));
    },
    [dispatch],
  );

  const { leftVisible, rightVisible } = visibilityState;

  return {
    fetchingQuickAccessLinks: Boolean(quickAccessLinks?.fetching),
    quickAccessLinks: quickAccessLinks?.data,
    leftVisible,
    rightVisible,
    onControlClick,
    sliderRef,
    onScrollChange,
    handleClick,
  };
};

import { useStore } from 'react-redux';

import type { SpartacuxState } from '@/domains/core/shell/applicationState/root.state';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';
import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type {
  AlgoliaQueryDetails,
  AlgoliaTrackingSuggestionClick,
} from '../../interfaces/algolia';

export const useAlgoliaCTRTrackingEvent = (): {
  sendAlgoliaSuggestionCTRTrackingEvent: (
    event: AlgoliaTrackingSuggestionClick,
  ) => void;
  sendAlgoliaProductCTRTrackingEvent: (
    product: Product,
    position: number,
    queryDetails: AlgoliaQueryDetails,
  ) => void;
} => {
  const store = useStore<
    SpartacuxProductDiscoveryDomainState & SpartacuxState
  >();

  const sendAlgoliaSuggestionCTRTrackingEvent = async (
    event: AlgoliaTrackingSuggestionClick,
  ) => {
    const { sendSuggestionsTracking } = await import('./tracking');
    const state = store.getState();
    sendSuggestionsTracking(state, event);
  };

  const sendAlgoliaProductCTRTrackingEvent = async (
    product: Product,
    position: number,
    queryDetails: AlgoliaQueryDetails,
  ) => {
    const { sendProductTracking } = await import('./tracking');
    const state = store.getState();
    sendProductTracking(state, product, position, queryDetails);
  };

  return {
    sendAlgoliaSuggestionCTRTrackingEvent,
    sendAlgoliaProductCTRTrackingEvent,
  };
};

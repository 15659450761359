import { all, call, put, takeLatest } from 'typed-redux-saga';

import { waitForAndGetFlagValuesSaga } from '@/core/flags/sagas/waitForAndGetFlagValues.saga';
import logger from '@/domains/core/observability/logger';
import { abTestAndFeatureFlagValidated } from '@/domains/productDiscovery/CommercialAnimation/utils/abTestAndFeatureFlagValidated';
import { getUserSegments } from '@/domains/productDiscovery/CommercialAnimation/utils/getUserSegments';
import {
  FETCH_PRE_HEADERS_FAILED,
  FETCH_PRE_HEADERS_SUCCESS,
  FETCHING_PRE_HEADERS,
  INIT_PRE_HEADERS,
} from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/PreHeader.actions';
import { fetchPreHeadersGql } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/services/fetch';
import type { PreHeaderModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';

function* getPreHeaders(): Generator<any, any, any> {
  try {
    yield put({
      type: FETCHING_PRE_HEADERS,
    });

    const { userSegments, flags } = yield* all({
      userSegments: call(getUserSegments),
      flags: call(waitForAndGetFlagValuesSaga),
    });

    const preHeaders: PreHeaderModel[] = yield* call(
      fetchPreHeadersGql,
      userSegments,
    );

    const validPreHeaders =
      preHeaders?.length > 0
        ? preHeaders.filter((preHeader: PreHeaderModel) =>
            abTestAndFeatureFlagValidated(preHeader, flags),
          )
        : [];

    yield put({
      type: FETCH_PRE_HEADERS_SUCCESS,
      preHeaders: validPreHeaders.slice(0, 3),
    });
  } catch (error) {
    logger.error(error);
    yield put({
      type: FETCH_PRE_HEADERS_FAILED,
      error: error.message,
    });
  }
}

export function* rootSaga(): Generator {
  yield takeLatest(INIT_PRE_HEADERS, getPreHeaders);
}

import { useEffect } from 'react';

import { useOverlay } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useOverlay';
import { isIOSDevice } from '@/domains/productDiscovery/HeaderLeonidas/utils/isIOSDevice';

export const useScrollFreezeWhenOverlay = () => {
  const overlay = useOverlay();

  useEffect(() => {
    if (
      overlay !== undefined &&
      document.body.getAttribute('data-overlay') !== overlay
    ) {
      document.body.setAttribute('data-overlay', overlay);
    }
  }, [overlay]);

  useEffect(() => {
    if (isIOSDevice()) {
      document.body.setAttribute('data-is-ios-device', 'true');
    }
  }, []);
};

export const enum SponsoredProductsLocationType {
  ADD_TO_CART_PAGE = 'ADD_TO_CART_PAGE',
  CART_PAGE = 'CART_PAGE',
  CATEGORY_PAGE = 'CATEGORY_PAGE',
  CATEGORY_SPONSORED_BRAND = 'CATEGORY_SPONSORED_BRAND',
  CATEGORY_SPONSORED_BRAND_PRODUCT = 'CATEGORY_SPONSORED_BRAND_PRODUCT',
  GOOGLE_SHOPPING_PAGE = 'GOOGLE_SHOPPING_PAGE',
  GOOGLE_SHOPPING_PAGE_TOP = 'GOOGLE_SHOPPING_PAGE_TOP',
  HOME_PAGE = 'HOME_PAGE',
  MASS_LANDING_PAGE = 'MASS_LANDING_PAGE',
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  PRODUCT_PAGE_HIGHLIGHT = 'PRODUCT_PAGE_HIGHLIGHT',
  PRODUCT_PAGE_TOP = 'PRODUCT_PAGE_TOP',
  SEARCH_BAR = 'SEARCH_BAR',
  SEARCH_PAGE = 'SEARCH_PAGE',
  SEARCH_SPONSORED_BRAND = 'SEARCH_SPONSORED_BRAND',
  SEARCH_SPONSORED_BRAND_PRODUCT = 'SEARCH_SPONSORED_BRAND_PRODUCT',
}

import type { FunctionComponent } from 'react';
import { twJoin } from 'tailwind-merge';

import { Link } from '@/core/routing/components/Link';
import { routes } from '@/core/routing/routes/routes.default';
import { isB2C } from '@/core/settings/utils';
import { B2bMulticolorLightHorizontalHeaderLogo } from '@/core/tamagoshiTailwind/logos/b2b/B2bMulticolorLightHorizontalHeaderLogo';
import { B2cMulticolorLightHorizontalHeaderLogo } from '@/core/tamagoshiTailwind/logos/b2c/B2cMulticolorLightHorizontalHeaderLogo';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { GTM_CLICK_ON_MM_LOGO } from '@/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';

interface Props {
  className?: string;
}

export const Logo: FunctionComponent<Props> = ({ className }) => {
  return (
    <Link
      href={routes.home}
      data-testid="logoHomeCta"
      className={twJoin(
        `mx-xs my-s flex flex-col flex-nowrap items-stretch justify-center
        desktop:ml-l desktop:mr-m desktop:min-h-[44px]`,
        className,
      )}
      onClick={() => Gtm.push(GTM_CLICK_ON_MM_LOGO())}
    >
      {isB2C() ? (
        <B2cMulticolorLightHorizontalHeaderLogo
          className="block h-[22px] w-[132px] desktop:h-[30px]
            desktop:w-[179px]"
        />
      ) : (
        <B2bMulticolorLightHorizontalHeaderLogo
          className="block h-[22px] w-[164px] desktop:h-[30px]
            desktop:w-[223px]"
        />
      )}
    </Link>
  );
};

Logo.displayName = 'Logo';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { searchDisplayEmptySearchSelector } from './useDisplayEmptySearch';
import { suggestionsAutoCompleteSelector } from './useSuggestionsAutoComplete';
import { suggestionsEmptySearchSelector } from './useSuggestionsEmptySearch';

export const selectCurrentSuggestions = createSelector(
  [
    suggestionsAutoCompleteSelector,
    suggestionsEmptySearchSelector,
    searchDisplayEmptySearchSelector,
  ],
  (autoCompleSuggestions, emptySearchSuggestions, isEmptySearch) =>
    isEmptySearch ? emptySearchSuggestions : autoCompleSuggestions,
);

export const useCurrentSuggestions = () =>
  useSelector(selectCurrentSuggestions);

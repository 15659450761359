import { translate } from '@/domains/core/localization/translate';
export const {
  defaultTopProductsTitle,
  // = mobile
  topProductsB2BTitle,
  topSalesTitle,
  lastSeenTitle,
  similarityTitle,
  favoriteProductsB2BTitle
} = translate({
  "defaultTopProductsTitle": "Top ventas",
  "topProductsB2BTitle": "Top productos Pro",
  "topSalesTitle": "Top ventas del momento",
  "lastSeenTitle": "Productos vistos hace poco",
  "similarityTitle": "\xA1Creemos que esto puede interesarte!",
  "favoriteProductsB2BTitle": "Productos recomendados"
});
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, type FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDynamicReducer } from '@/domains/core/applicationState/hooks/useDynamicReducer';
import { SafeStorage } from '@/domains/core/data/utils/SafeStorage/SafeStorage';
import { pageTypeSelector } from '@/domains/core/page/page.selectors';
import { isB2B } from '@/domains/core/settings/utils';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import {
  LOCAL_STORAGE_NEWSLETTER_DISPLAYED_TIMESTAMP,
  SESSION_STORAGE_NEWSLETTER_NB_PAGES_VIEWED_KEY,
} from '@/domains/retention/NewsletterModal/constants';
import { useNewsletterModalVisible } from '@/domains/retention/NewsletterModal/hooks/useModalVisibility';
import { useSuccessModalVisible } from '@/domains/retention/NewsletterModal/hooks/useSuccessModalVisibility';
import {
  hideModal,
  showModal,
} from '@/domains/retention/NewsletterModal/Newsletter.actions';
import { reducer } from '@/domains/retention/NewsletterModal/Newsletter.reducer';
import type { NewsletterModalProps } from '@/domains/retention/NewsletterModal/NewsletterModal';
import {
  GTM_CLOSE_NEWSLETTER_MODAL,
  GTM_DISPLAY_NEWSLETTER_MODAL,
} from '@/domains/retention/NewsletterModal/services/gtmEvents';
import { automaticModalTrigger } from '@/domains/retention/NewsletterModal/utils/automaticNewsletterModalTrigger';

const DynamicNewsletterModal = dynamic<NewsletterModalProps>(
  () =>
    import('@/domains/retention/NewsletterModal/NewsletterModal').then(
      (mod) => mod.NewsletterModal,
    ),
  { ssr: false },
);

export const NewsletterModalContainer: FunctionComponent = () => {
  const router = useRouter();
  const { pathname } = router;
  const dispatch = useDispatch();
  const isModalVisible = useNewsletterModalVisible();
  const isModalSuccessVisible = useSuccessModalVisible();

  useDynamicReducer('retention.newsletter', reducer);

  const pageType = useSelector(pageTypeSelector);

  useEffect(() => {
    if (
      !isB2B() &&
      ['/cart', '/product'].includes(pathname) &&
      !isModalVisible &&
      automaticModalTrigger()
    ) {
      dispatch(showModal());
      const safeLocalStorage = SafeStorage.getLocalStorage();
      safeLocalStorage.setItem(
        LOCAL_STORAGE_NEWSLETTER_DISPLAYED_TIMESTAMP,
        `${Date.now()}`,
      );
      Gtm.push(GTM_DISPLAY_NEWSLETTER_MODAL(pageType || 'unknown'));
    }
  }, [pathname, dispatch, isModalVisible, pageType]);

  useEffect(() => {
    const handleRouteChange = () => {
      const safeSessionStorage = SafeStorage.getSessionStorage();
      const nbPagesViewed = parseInt(
        safeSessionStorage.getItem(
          SESSION_STORAGE_NEWSLETTER_NB_PAGES_VIEWED_KEY,
        ) || '1',
        10,
      );
      safeSessionStorage.setItem(
        SESSION_STORAGE_NEWSLETTER_NB_PAGES_VIEWED_KEY,
        `${nbPagesViewed + 1}`,
      );
    };

    if (!isB2B()) {
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    dispatch(hideModal());
    Gtm.push(GTM_CLOSE_NEWSLETTER_MODAL());
  };

  return isModalVisible && !isModalSuccessVisible ? (
    <DynamicNewsletterModal onCloseModal={onClose} />
  ) : null;
};

NewsletterModalContainer.displayName = 'NewsletterModalContainer';

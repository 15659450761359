import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { CategoryMetadata, CategoryState } from '../Category.model';
import { selectCategory, selectCategoryMetadata } from '../selectors/category';

export const useCategory = (): CategoryState | undefined =>
  useSelector(selectCategory);

export const useCategoryMetadata = (): CategoryMetadata | undefined =>
  useSelector<
    SpartacuxProductDiscoveryDomainState<'b2c' | 'b2b'>,
    CategoryMetadata | undefined
  >(selectCategoryMetadata);

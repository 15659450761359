import { useSelector } from 'react-redux';

import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';

export const headerBannersSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): BannerCampaign[] | undefined =>
  state.productDiscovery?.header?.headerBanners?.data;

export const useHeaderBanners = () => useSelector(headerBannersSelector);

import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const ArrowBack: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconsSystemArrowBack" viewBox="0 0 24 24" {...props}>
    <path
      d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 00-1.41 0l-6.59 6.59a.996.996 0 000 1.41l6.59 6.59a.996.996 0 101.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z"
      fill="currentColor"
    />
  </Svg>
);

ArrowBack.displayName = 'ArrowBack';

export enum Status {
  WAITING = 'isWaiting',
  FETCHING = 'isFetching',
  DONE = 'done',
  ERROR = 'error',
}

export const PRODUCTS_SUGGESTIONS_INDEX = 0;
export const SPONSORED_PRODUCTS_SUGGESTIONS_INDEX = 1;
export const MAX_HITS_PER_PAGE = 6;
export const DEFAULT_STATUS = Status.WAITING;

import type { FunctionComponent, PropsWithChildren } from 'react';

import { useOverlay } from '../../../hooks/useOverlay';

interface HeaderMainRootProps extends PropsWithChildren {
  className?: string;
}

export const HeaderMainRoot: FunctionComponent<HeaderMainRootProps> = ({
  className,
  children,
}) => {
  const overlay = useOverlay();
  return (
    <div className={className} data-overlay={overlay} data-testid="header-main">
      {children}
    </div>
  );
};

HeaderMainRoot.displayName = 'HeaderMainRoot';

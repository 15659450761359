export interface WishListIconWithMiniPopupProps {
  className?: string;
}

export enum WishListMiniPopupEventIDs {
  CONSUMED = '', // default
  CLICK_ACTION = 'click_action',
  CLICK_CLOSE = 'click_close',
  CLICK_ELSEWHERE = 'click_elsewhere',
}

export enum WishListMiniPopupMessageTypes {
  NORMAL = 'normal',
  ALERT = 'alert',
}

import type { FunctionComponent } from 'react';

import { Link } from '@/domains/core/routing/components/Link';
import type { MiniProductCardLinkProps } from '@/domains/productDiscovery/MiniProductCard/components/interface';

export const MiniProductCardLink: FunctionComponent<
  MiniProductCardLinkProps
> = ({ className, onClick, children, href }) => (
  <Link className={className} href={href} onClick={onClick}>
    {children}
  </Link>
);

MiniProductCardLink.displayName = 'MiniProductCardLink';

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { isB2B } from '@/core/settings/utils';
import { useSearchFocusedSuggestionPosition } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchFocusedSuggestionPosition';
import { useSearchAIQueries } from '@/productDiscovery/Listings/modules/SearchAI/hooks/useSearchAIQueries';
import { headerSearchAI, headerSearchB2B, headerSearchB2C } from '../SearchForm/translations';
interface Props {
  suggestionsDisplayed?: boolean;
  searchValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  onFocus: () => void;
  searchAI?: boolean;
  opened: boolean;
}
export const SearchFormInput = forwardRef<HTMLInputElement, Props>(({
  searchValue,
  onChange,
  onKeyDown,
  onFocus,
  suggestionsDisplayed,
  searchAI,
  opened
}, forwardedRef) => {
  const searchAIQueriesName = useSearchAIQueries().map(({
    name
  }) => name);
  const focusedSuggestedPosition = useSearchFocusedSuggestionPosition();
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const ref = useRef<HTMLInputElement>(null);
  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);
  const showDynamicAIPlaceholder = searchAI && suggestionsDisplayed && searchValue === '';
  const translations = isB2B() ? headerSearchB2B : headerSearchB2C;
  useEffect(() => {
    if (focusedSuggestedPosition === 0 && ref?.current !== document.activeElement && suggestionsDisplayed) {
      ref?.current?.focus();
    }
  }, [focusedSuggestedPosition, suggestionsDisplayed, ref]);
  useEffect(() => {
    if (showDynamicAIPlaceholder) {
      const placeholderTimeout = setTimeout(() => {
        setCurrentPlaceholderIndex(prevIndex => (prevIndex + 1) % searchAIQueriesName.length);
      }, 3000);
      return () => {
        clearTimeout(placeholderTimeout);
      };
    }
  }, [showDynamicAIPlaceholder, currentPlaceholderIndex, searchAIQueriesName.length]);

  // Trick to avoid showing the placeholder for 500ms when the suggestions are displayed
  const placeholderAI = suggestionsDisplayed ? '' : headerSearchAI.placeholder;
  return <>
        <input className={twJoin(`relative flex h-[44px] w-full flex-1 appearance-none truncate
            rounded-[22px] bg-foundation-inverted pl-m pr-[44px]
            placeholder:text-foundation-secondary focus:outline-none
            [&::-webkit-search-cancel-button]:hidden`, !!searchValue && 'bg-foundation-inverted pr-[44px]', opened && 'border-2 border-accent-primary', showDynamicAIPlaceholder && 'block')} placeholder={searchAI ? placeholderAI : translations.placeholder} aria-label={translations.label} onChange={event => onChange(event)} onKeyDown={event => onKeyDown(event)} onFocus={onFocus} defaultValue={searchValue} ref={ref} type="search" />
        {showDynamicAIPlaceholder && <label className="text-foundation-textIconSecondary pointer-events-none
              absolute left-[18px] top-1/2 -translate-y-1/2">
            Essayez
            <span className="animate-blink" key={currentPlaceholderIndex}>
              {` "${searchAIQueriesName[currentPlaceholderIndex]}"`}
            </span>
          </label>}
      </>;
});
SearchFormInput.displayName = 'SearchFormInput';
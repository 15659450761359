import { useFlags } from '@/core/flags/flags.hooks';
import type { ABFFlagFeature } from '@/domains/productDiscovery/CommercialAnimation/interfaces/ABFFlags';

export const useABFFlagsValidator = (argument: ABFFlagFeature): boolean => {
  const ids = [];
  ids.push(argument.abTest ? argument.abTest.id.toString() : '');
  ids.push(argument.featureFlag ? argument.featureFlag.id.toString() : '');

  const [abTest, featureFlag] = useFlags(ids);

  if (argument.abTest && abTest !== (argument.abTest.feature === 'b')) {
    return false;
  }

  if (argument.featureFlag && featureFlag !== argument.featureFlag.active) {
    return false;
  }

  return true;
};

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { SuggestionsState } from '../HeaderLeonidas.searchBar.model';

export const suggestionsSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): SuggestionsState | undefined =>
  state.productDiscovery?.searchBar?.suggestions;

export const useSuggestions = () => useSelector(suggestionsSelector);

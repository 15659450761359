import dynamic from 'next/dynamic';
import React from 'react';

import { useDisplayZendeskChat } from '@/domains/digitalAdvice/zendesk/utils/useDisplayZendeskChat';

import { ZendeskChatProps } from './ZendeskChat';

const ZendeskChat = dynamic<ZendeskChatProps>(
  () =>
    import('@/domains/digitalAdvice/zendesk/ZendeskChat').then(
      (module) => module.ZendeskChat,
    ),
  { ssr: false },
);

export const DynamicZendeskChat: React.FC<ZendeskChatProps> = (props) => {
  const displayChat = useDisplayZendeskChat();

  return displayChat ? <ZendeskChat {...props} /> : null;
};

export const GTM_DISPLAY_NEWSLETTER_MODAL = (pageType: string) => ({
  event: 'interaction_display newsletter modal',
  e_category: 'navigation',
  e_action: 'Display newsletter modal',
  amp_event_name: 'Display newsletter modal',
  event_trigger: pageType,
  interaction_hit: 'True',
});

export const GTM_CLOSE_NEWSLETTER_MODAL = () => ({
  event: 'interaction_close newsletter modal',
  e_category: 'navigation',
  e_action: 'Close newsletter modal',
  e_label: 'NL_Modal',
  amp_event_name: 'Close newsletter modal',
  event_trigger: 'NL_Modal',
  interaction_hit: 'False',
});

export const GTM_NEWSLETTER_SUBSCRIPTION_SUCCESS = (
  fromNewsletter = false,
) => ({
  event: 'interaction_newsletter subscription success',
  e_category: 'navigation',
  e_action: 'Newsletter subscription success',
  e_label: fromNewsletter ? 'NL_Modal' : 'Footer CTA',
  amp_event_name: 'Newsletter subscription success',
  event_trigger: fromNewsletter ? 'NL_Modal' : 'Footer CTA',
  interaction_hit: 'False',
});

export const GTM_NEWSLETTER_SUBSCRIPTION_ERROR = (fromNewsletter = false) => ({
  event: 'interaction_newsletter subscription error',
  e_category: 'navigation',
  e_action: 'Newsletter subscription error',
  e_label: fromNewsletter ? 'NL_Modal' : 'Footer CTA',
  amp_event_name: 'Newsletter subscription error',
  event_trigger: fromNewsletter ? 'NL_Modal' : 'Footer CTA',
  interaction_hit: 'False',
});

export const GTM_LEGAL_MENTIONS_CLICKED_FROM_NEWSLETTER = (href: string) => ({
  event: 'interaction_click on legal mentions link',
  e_category: 'navigation',
  e_action: 'Click on legal mentions link',
  e_label: 'NL_Modal',
  amp_event_name: 'Click on legal mentions link',
  event_trigger: 'NL_Modal',
  interaction_hit: 'False',
  redirection_link: href,
});

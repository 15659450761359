import {
  FunctionComponent,
  MouseEvent as ReactMouseEvent,
  SyntheticEvent,
} from 'react';
import { useDispatch } from 'react-redux';

import { isFrancePlatform } from '@/core/settings/utils';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';
import { displayAdvertiserInformation } from '@/productDiscovery/CommercialAnimation/CommercialAnimation.actions';
import { createAdvertiserInformationPopupContent } from '@/productDiscovery/CommercialAnimation/utils/createAdvertiserInformationPopupContent';
import { getMerchandisingInfos } from '@/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/ProductsList/components/AlgoliaMiniProductCard/utils/getMerchandisingInfos';
import { getProductCardTranslations } from '@/productDiscovery/Listings/components/ProductCardListingsEnhancer/translations';
import {
  MiniProductCard,
  MiniProductCardProps,
} from '@/productDiscovery/MiniProductCard/MiniProductCard';

import popupStyles from '@/productDiscovery/CommercialAnimation/components/AdvertiserInformation/AdvertiserInformationPopup.module.scss';

interface AlgoliaMiniProductCardProps
  extends Omit<
    MiniProductCardProps,
    'onClick' | 'title' | 'imageSrc' | 'price' | 'href'
  > {
  product: Product;
  favorite: boolean;

  onClick?(
    event: ReactMouseEvent<HTMLElement, MouseEvent>,
    product: Product,
  ): unknown;
}

export const AlgoliaMiniProductCard: FunctionComponent<
  AlgoliaMiniProductCardProps
> = ({ onClick, product, ...otherProps }) => {
  const handleClick = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) onClick(event, product);
  };
  const dispatch = useDispatch();

  const labelType = getMerchandisingInfos(product);
  const sponsoredData = {
    adPayer: product.adPayer,
    sellerName: product.sellerName,
    brandName: product.brandName,
  };
  const translations = getProductCardTranslations(
    product.modelsCount,
    undefined,
    isFrancePlatform(),
    sponsoredData,
  );
  const onAdvertiserLabelClicked = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { sellerName: sponsor, brandName: advertiser } = sponsoredData;
    const adInfo = {
      sponsor,
      advertiser,
      userSegments: false,
      dsaPopup: !!(sponsor || advertiser),
      content: createAdvertiserInformationPopupContent(
        advertiser,
        sponsor,
        false,
        popupStyles.content,
      ),
    };
    dispatch(displayAdvertiserInformation(adInfo));
  };
  return (
    <MiniProductCard
      title={product.title}
      imageSrc={product.imageFullpath}
      price={product.prices.main}
      retailPrice={product.prices.retail || undefined}
      brandName={product.brandName}
      brandImageSrc={product.brandImageFullpath}
      href={product.url}
      onClick={handleClick}
      labelType={labelType}
      translations={translations}
      onAdvertiserLabelClicked={onAdvertiserLabelClicked}
      {...otherProps}
    />
  );
};

AlgoliaMiniProductCard.displayName = 'AlgoliaMiniProductCard';

import type { FunctionComponent } from 'react';

import { BreakpointProvider } from '@/core/tamagoshi/MediaQuery/MediaQuery';
import { useDynamicReducer } from '@/domains/core/applicationState/hooks/useDynamicReducer';
import { Overlay } from '@/domains/productDiscovery/HeaderLeonidas/components/Overlay/Overlay';
import { PreHeader } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/PreHeader';
import { reducer as headerLeonidasReducer } from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.reducer';

import { HeaderMain } from '../components/HeaderMain/HeaderMain';

import styles from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.module.scss';

interface HeaderLeonidasProps {
  pageType: string;
}

export const HeaderLeonidas: FunctionComponent<HeaderLeonidasProps> = () => {
  useDynamicReducer('productDiscovery.headerLeonidas', headerLeonidasReducer);

  return (
    <BreakpointProvider>
      <header className={styles.header}>
        <Overlay />
        <PreHeader />
        <HeaderMain />
      </header>
    </BreakpointProvider>
  );
};

HeaderLeonidas.displayName = 'HeaderLeonidas';

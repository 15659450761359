import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { ProductSuggestionType } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/model';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';

import type {
  ProductCardClickTracking,
  ProductsViewedTracking,
  ProductTracking,
} from '../../interfaces/tracking';

const productsTracking = (
  products: Product[],
  productsType: ProductSuggestionType,
  prPosition?: number,
): ProductTracking[] =>
  products.map((product, index) => ({
    list_name: `${productsType} products`,

    pr_id: product.articleId,
    pr_name: product.title,

    pr_product_id: product.productId,
    pr_article_id: product.articleId,

    pr_stock: 'available',
    pr_position: (prPosition || index) + 1,
    pr_price: product.prices.main,
    pr_base_price: product.prices.main,

    pr_lowest_category_name: product.categoriesLast?.[0],
    pr_lowest_category_id: product.categoriesLastId,

    pr_seller_name: product.sellerName,
    pr_seller_id: product.sellerId,

    pr_brand: product.brandName,
    pr_brand_id: product.brandId,

    pr_model_name: product.title,
    pr_model_id: product.modelId,
    pr_variant: null,
    pr_attributes_count: null,

    pr_is_restocking: false,
    pr_is_sponsored: false,
    pr_is_pro: product.isB2b,
    pr_is_mf: product.isMmf,
    pr_is_cpt: product.isCompetitive,
    pr_is_in_promo:
      product.commercialOperationIds &&
      product.commercialOperationIds.length > 0,
  }));

interface ProductCardClick {
  product: Product;
  productsType: ProductSuggestionType;
  prPosition: number;
  pageType?: string;
}

export const sendProductClickTracking = ({
  product,
  productsType,
  prPosition,
  pageType,
}: ProductCardClick) => {
  Gtm.push<ProductCardClickTracking>({
    event: 'interaction_product click',
    e_category: 'ecommerce',
    e_action: 'product click',
    e_label: `${productsType} products`,
    amp_event_name: 'Click on product card',
    event_trigger: 'search_bar',
    playlist_name: `${
      productsType.charAt(0).toUpperCase() + productsType.slice(1)
    } products`,
    page_type: pageType,
    pr_products: productsTracking([product], productsType, prPosition),
  });
};

interface ProductsViewed {
  products: Product[];
  position: number;
  productsType: ProductSuggestionType;
  engineName?: string;
  fingerPrint?: string | null;
  playlistFingerprint?: string | null;
  pageType?: string;
}

export const sendViewedTracking = ({
  products,
  position,
  productsType,
  engineName,
  fingerPrint,
  playlistFingerprint,
  pageType,
}: ProductsViewed) => {
  Gtm.push<ProductsViewedTracking>({
    event: 'interaction_display playlist',
    e_category: 'ecommerce',
    e_action: 'Display playlist',
    e_label: `${productsType} products - search_bar`,
    amp_event_name: 'Display playlist',
    event_trigger: 'search_bar',
    listing_fingerprint: fingerPrint,
    playlist_name: `${
      productsType.charAt(0).toUpperCase() + productsType.slice(1)
    } products`,
    playlist_article_ids: products.map((product) => product.articleId),
    playlist_category_ids: products.map((product) => product.categoriesLastId),
    playlist_model_ids: products.map((product) => product.modelId),
    playlist_product_ids: products.map((product) => product.productId),
    playlist_position: position,
    playlist_fingerprint: playlistFingerprint,
    playlist_reconciliation: engineName
      ? `${engineName} - search_bar`
      : undefined,
    card_number: products.length,
    page_type: pageType,
    pr_products: productsTracking(products, productsType),
  });
};

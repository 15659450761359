import { useFlags } from '@/domains/core/flags/flags.hooks';
import { isFrancePlatform } from '@/domains/core/settings/utils';
import { StringABTests } from '@/productDiscovery/Listings/pages/Search/constants';

export const useSearchAIEnabled = (): boolean => {
  // NOTE Display Search AI module only for FR platform
  const allowPlatform = isFrancePlatform();
  const [searchAIEnabled] = useFlags([StringABTests.SEARCH_AI]);

  return !!searchAIEnabled && allowPlatform;
};

import dynamic from 'next/dynamic';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Cross as CloseIcon } from '@/core/tamagoshi/icons/Cross';
import { Text } from '@/core/tamagoshi/Text/Text';
import { Image } from '@/domains/core/Image/Image';
import { Link } from '@/domains/core/routing/components/Link';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { CampaignModalProps } from '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal';
import { useMarketingCampaigns } from '@/domains/productDiscovery/CommercialAnimation/hooks/useMarketingCampaigns';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { GTM_CLICK_ON_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/clickOnBannerCampaign';
import { PromoItem } from '@/productDiscovery/HeaderLeonidas/b2b/components/Promos/PromoItem';
import { PartnerAdBanner } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/PartnerAdBanner';
import { spaceSlugType } from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/spaceSlugTypes';

import { MenuCloseButton } from '../../../components/MenuCloseButton/MenuCloseButton';
import { setPromosVisibility } from '../../store/HeaderLeonidas.actions';
import translations from './translations';
import { usePromos } from './usePromos';

import promoBlockStyles from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/PromoBlock/PromoBlock.module.scss';
import styles from './Promos.module.scss';

const DynamicModal = dynamic<CampaignModalProps>(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal'
      )
    ).CampaignModal,
);

export const Promos = () => {
  const { isPromosVisible, onCloseButton, promosRef } = usePromos();
  const promos = useMarketingCampaigns();
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);

  const handleCampaignClick = useCallback(
    (campaign: BannerCampaign, index: number, showModal: boolean) => {
      if (showModal) {
        setDisplayModal(true);
      } else {
        dispatch(setPromosVisibility(false));
      }
      Gtm.push(
        GTM_CLICK_ON_BANNER_CAMPAIGN(
          'promo_block',
          campaign.id,
          campaign.name,
          campaign.link.url || '',
          index,
          campaign.sponsor !== null && campaign.advertiser !== null,
          campaign.pageType || undefined,
          campaign.trackingId || undefined,
        ),
      );
    },
    [dispatch],
  );

  if (!isPromosVisible || !promos?.length) return null;

  return (
    <div className={styles.container} ref={promosRef}>
      <div className={styles.main}>
        <div className={styles.header}>
          <Text type="h4" weight="bold">
            {translations.label}
          </Text>
          <button
            type="button"
            onClick={onCloseButton}
            className={styles.closeMobile}
          >
            <CloseIcon />
          </button>
        </div>
        <ul className={styles.list}>
          {promos.map((campaign, index) => {
            let content;
            const { id, name, image: campaignImage, link } = campaign;
            const image = (
              <Image
                src={campaignImage.mobileUrl}
                alt={name}
                height="280"
                width="280"
                className={styles.image}
              />
            );
            if (!link.url) {
              content = (
                <div data-privatesale-id={id} data-privatesale-name={name}>
                  {image}
                </div>
              );
            } else {
              content =
                link.url && link.target !== '_modal' ? (
                  <Link
                    href={link.url}
                    data-privatesale-id={id}
                    data-privatesale-name={name}
                    onClick={() => handleCampaignClick(campaign, index, false)}
                    tabIndex={index}
                    role="link"
                    target={link.target}
                  >
                    {image}
                  </Link>
                ) : (
                  <>
                    <button
                      className={styles.linkWithModal}
                      data-privatesale-id={id}
                      data-privatesale-name={name}
                      data-testid="button-for-modal"
                      onClick={() =>
                        handleCampaignClick(
                          campaign,
                          index,
                          link.target === '_modal',
                        )
                      }
                      type="button"
                    >
                      {image}
                    </button>
                    {displayModal && (
                      <DynamicModal
                        url={link.url}
                        onClose={() => {
                          dispatch(setPromosVisibility(false));
                          setDisplayModal(false);
                        }}
                      />
                    )}
                  </>
                );
            }

            return campaign.isPartnerAd ? (
              <PartnerAdBanner
                key={id}
                partnerAdBannerCampaign={campaign}
                bannerTypeTracking="onglet_banner"
                spaceSlug={spaceSlugType.PROMO_BLOCK}
                containerClassName={promoBlockStyles.root}
              />
            ) : (
              <PromoItem
                key={id}
                campaign={campaign}
                position={index}
                content={content}
              />
            );
          })}
        </ul>
      </div>
      <MenuCloseButton className={styles.close} onClick={onCloseButton} />
    </div>
  );
};

import { isLocalStorageAvailable } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/utils';

export const isSameDateThanLastClose = (key: string) => {
  const lastClose = isLocalStorageAvailable()
    ? localStorage.getItem(key)
    : null;
  if (!lastClose) return false;

  const lastCloseDate = new Date(lastClose);
  const now = new Date();

  return (
    now.getFullYear() === lastCloseDate.getFullYear() &&
    now.getMonth() === lastCloseDate.getMonth() &&
    now.getDay() === lastCloseDate.getDay()
  );
};

import classnames from 'classnames';
import { forwardRef, useMemo, type ReactElement } from 'react';
import type React from 'react';

import { Carousel } from '@/core/tamagoshi/Carousel/Carousel';
import { useLatestRef } from '@/domains/core/data/hooks/useLatestRef';
import { useCustomerProductPreferences } from '@/domains/customerManagement/customerProductPreferences/hooks/useCustomerProductPreferences';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';

import { ProductNotFound } from '../ProductNotFound/ProductNotFound';
import { AlgoliaMiniProductCard } from './components/AlgoliaMiniProductCard/AlgoliaMiniProductCard';
import { ProductsContainer } from './components/ProductsContainer/ProductsContainer';

import styles from './ProductsList.module.scss';

export interface ProductsListProps {
  className?: string;
  values: Product[];
  isDesktop: boolean;
  title?: string | ReactElement<{ className: string }>;
  button?: ReactElement<{ className: string }>;
  onClickProductCard?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    product: Product,
    position: number,
  ) => void;
  showFavorites?: boolean;
}

export const ProductsList = forwardRef<HTMLDivElement, ProductsListProps>(
  (
    {
      className,
      title,
      button,
      values,
      onClickProductCard,
      isDesktop,
      showFavorites = false,
    },
    ref,
  ) => {
    const latestOnClickProductCard = useLatestRef(onClickProductCard);
    const rootClassnames = classnames(styles.root, className);
    const { purchasedTwice } = useCustomerProductPreferences();
    /*TODO: this was a call to 'useFeatureFlags' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
    const [customerProductPreferencesEnabled] = [true];
    /*TODO: this was a call to 'useABTests' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
    const [customerProductPreferencesAbtest] = [false];

    const favorite =
      showFavorites &&
      customerProductPreferencesEnabled &&
      customerProductPreferencesAbtest;

    const hasFavorites = useMemo(
      () => favorite && values.some((item) => purchasedTwice.has(item.modelId)),
      [favorite, purchasedTwice, values],
    );
    const productCards = useMemo(
      () =>
        values.map((product, index) => (
          <AlgoliaMiniProductCard
            key={product.productId}
            className={styles.product}
            product={product}
            imageLoading="lazy"
            vertical
            onClick={(event, productClicked) =>
              latestOnClickProductCard.current?.(event, productClicked, index)
            }
            favorite={favorite && purchasedTwice.has(product.modelId)}
          />
        )),
      [favorite, latestOnClickProductCard, purchasedTwice, values],
    );

    if (!productCards.length) {
      return <ProductNotFound />;
    }

    if (isDesktop) {
      return (
        <ProductsContainer
          productsElement={productCards}
          title={title}
          button={button}
          classname={hasFavorites ? styles.favorites : undefined}
        />
      );
    }

    return (
      <>
        <Carousel
          className={rootClassnames}
          sliderClassName={classnames(styles.slider, {
            [styles.favorites]: hasFavorites,
          })}
          controlsClassName={styles.sliderControls}
          title={title}
          horizontalPadding="md"
          ref={ref}
        >
          {productCards}
        </Carousel>
        {button && <div className={styles.seeMoreButton}>{button}</div>}
      </>
    );
  },
);

ProductsList.displayName = 'ProductsList';

import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import { ShoppingCartOutline } from '@/core/tamagoshi/icons/ShoppingCartOutline';
import { Link } from '@/domains/core/routing/components/Link';
import { routes } from '@/domains/core/routing/routes/routes.default';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { GTM_CLICK_ON_CART } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';
import { useCartItemCount } from '@/shoppingCart/CartOverview/hooks/useCartOverview';

import { leonidasCartText } from './translations';

import styles from './Cart.module.scss';

interface Props {
  className?: string;
}

export const Cart: FunctionComponent<Props> = ({
  className,
  ...otherProps
}) => {
  const itemsCount = useCartItemCount();

  return (
    <Link
      href={routes.cart}
      rel="nofollow"
      className={classnames(styles.container, className)}
      data-testid="cart-icon"
      onClick={() =>
        Gtm.push(
          GTM_CLICK_ON_CART({
            cartSize: itemsCount,
          }),
        )
      }
      title={leonidasCartText}
      {...otherProps}
    >
      <ShoppingCartOutline className={styles.cartImg} />
      {itemsCount > 0 && (
        <span
          className={styles.basketBadgeCount}
          data-testid="basket-badge-count"
        >
          {itemsCount > 9 ? (
            <>
              9<span className={styles.add}>+</span>
            </>
          ) : (
            itemsCount
          )}
        </span>
      )}
    </Link>
  );
};

Cart.displayName = 'Cart';

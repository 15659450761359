import { translate } from '@/domains/core/localization/translate';
export const {
  rootLabel
} = translate({
  "rootLabel": "Sugerencias de investigaci\xF3n"
});
export const {
  autocomplete,
  emptySearchB2C,
  emptySearchB2B,
  autocompleteB2B,
  searchHistory,
  deleteHistory,
  searchAI
} = {
  autocomplete: translate({
    "searchBrandTitle": "Marcas top"
  }),
  emptySearchB2C: translate({
    "searchBrandTitle": "Marcas del momento",
    "searchCategoryTitle": "Categor\xEDas del momento",
    "searchQueryTitle": "B\xFAsquedas populares"
  }),
  emptySearchB2B: translate({
    "searchBrandTitle": "Marcas Pro",
    "searchCategoryTitle": "Categor\xEDas del momento",
    "searchQueryTitle": "B\xFAsquedas de los Pros"
  }),
  autocompleteB2B: translate({
    "searchProSelection": "Top selecciones de los Pros"
  }),
  searchHistory: translate({
    "title": "Mis b\xFAsquedas recientes"
  }),
  deleteHistory: translate({
    "title": "Eliminar todo"
  }),
  searchAI: translate({
    "title": "Exemples de recherches",
    "history": "Recherches r\xE9centes"
  })
};
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useCallback, useState, type FunctionComponent } from 'react';

import { Link } from '@/domains/core/routing/components/Link';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { CampaignModalProps } from '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import MemoizedHeaderBannerImg from '@/domains/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBannerImg';
import { GTM_CLICK_ON_HEADER_BANNER } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/clickOnHeaderBanner';
import { BannerCampaignAdvertiserLabel } from '@/productDiscovery/CommercialAnimation/components/AdvertiserInformation/BannerCampaignAdvertiserLabel';

import styles from './HeaderBanner.module.scss';

const DynamicModal = dynamic<CampaignModalProps>(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal'
      )
    ).CampaignModal,
);

interface Props {
  headerBanner: BannerCampaign;
  className?: string;
}

export const HeaderBannerItem: FunctionComponent<Props> = ({
  headerBanner,
  className,
}) => {
  const [displayModal, setDisplayModal] = useState(false);

  const handleBannerClick = useCallback(() => {
    if (headerBanner) {
      setDisplayModal(true);
      Gtm.push(GTM_CLICK_ON_HEADER_BANNER(headerBanner));
    }
  }, [headerBanner]);

  if (!headerBanner) {
    return null;
  }

  return !headerBanner.link.url || headerBanner.link.target !== '_modal' ? (
    <Link
      className={classnames(styles.container, className)}
      href={headerBanner.link.url || ''}
      onClick={() =>
        Gtm.push(GTM_CLICK_ON_HEADER_BANNER(headerBanner as BannerCampaign))
      }
      target={headerBanner.link.target}
    >
      {(headerBanner.sponsor || headerBanner.advertiser) && (
        <BannerCampaignAdvertiserLabel
          sponsor={headerBanner.sponsor}
          advertiser={headerBanner.advertiser}
          userSegments={headerBanner.userSegments}
          withRadius={false}
          spaceSlug="header_banner"
        />
      )}
      <MemoizedHeaderBannerImg
        desktopUrl={headerBanner.image.desktopUrl || ''}
        altText={headerBanner.image.altText}
      />
    </Link>
  ) : (
    <>
      <button
        className={classnames(styles.container, styles.itemLink, className)}
        data-testid="headerBanner-button"
        onClick={handleBannerClick}
        type="button"
      >
        {(headerBanner.sponsor || headerBanner.advertiser) && (
          <BannerCampaignAdvertiserLabel
            sponsor={headerBanner.sponsor}
            advertiser={headerBanner.advertiser}
            userSegments={headerBanner.userSegments}
            withRadius={false}
            spaceSlug="header_banner"
          />
        )}
        <MemoizedHeaderBannerImg
          desktopUrl={headerBanner.image.desktopUrl || ''}
          altText={headerBanner.image.altText}
        />
      </button>
      {displayModal && (
        <DynamicModal
          url={headerBanner.link.url}
          onClose={() => setDisplayModal(false)}
        />
      )}
    </>
  );
};

import type { FunctionComponent } from 'react';

import { HeaderBannerItem } from '@/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBannerItem';
import { useInitHeaderBanners } from '@/productDiscovery/HeaderLeonidas/components/HeaderBanner/useInitHeaderBanners';

interface Props {
  className?: string;
}

export const HeaderBanner: FunctionComponent<Props> = ({ className }) => {
  const headerBanners = useInitHeaderBanners();
  const headerBanner =
    headerBanners && headerBanners.length > 0 ? headerBanners[0] : undefined;

  if (!headerBanners || !headerBanner) {
    return null;
  }

  return <HeaderBannerItem className={className} headerBanner={headerBanner} />;
};

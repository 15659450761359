import React from 'react';

import { useTrackViewedElement } from '@/core/tracking/hooks/useTrackViewedElement';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { GTM_DISPLAY_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/displayBannerCampaign';

import styles from './PromoItem.module.scss';

interface Props {
  content: React.ReactNode;
  campaign: BannerCampaign;
  position: number;
}

export const PromoItem: React.FC<Props> = ({ content, campaign, position }) => {
  const [campaignRef] = useTrackViewedElement(() => {
    Gtm.push(
      GTM_DISPLAY_BANNER_CAMPAIGN(
        'promo_block',
        campaign.id,
        campaign.name,
        campaign.link.url || '',
        position,
        campaign.sponsor !== null && campaign.advertiser !== null,
        campaign.pageType || undefined,
        campaign.trackingId || undefined,
      ),
    );
  });

  return (
    <li className={styles.root} ref={campaignRef}>
      {content}
    </li>
  );
};

import { useRouter } from 'next/router';
import { useMemo } from 'react';

import {
  replaceMinusOperatorBySpace,
  sanitizeQuery,
} from '@/domains/productDiscovery/Listings/services/querystring';

export const useQuery = (): string => {
  const { query } = useRouter();

  const termQuery = useMemo(() => {
    return (
      query.query ||
      (query.category &&
        replaceMinusOperatorBySpace(query.category as string)) ||
      (query.brand && replaceMinusOperatorBySpace(query.brand as string)) ||
      ''
    );
  }, [query.query, query.category, query.brand]);

  return useMemo(() => sanitizeQuery(termQuery as string), [termQuery]);
};

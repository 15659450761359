import {
  merchandisingLabels,
  type MerchandisingLabel,
} from '@/core/tamagoshi/MerchandisingTag/interfaces';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';
import { getPromoTags } from '@/productDiscovery/CommercialAnimation/utils/getProductPromoTags';

export const getMerchandisingInfos = (
  product: Product,
): MerchandisingLabel | undefined => {
  const tags = getPromoTags(product);
  if (product.isSponsored) {
    return merchandisingLabels.SPONSORED;
  }
  if (tags.includes(merchandisingLabels.BLACKFRIDAY)) {
    return merchandisingLabels.BLACKFRIDAY;
  }
  if (tags.includes(merchandisingLabels.SALES)) {
    return merchandisingLabels.SALES;
  }
  if (tags.includes(merchandisingLabels.MANOMANODAYS)) {
    return merchandisingLabels.MANOMANODAYS;
  }
  if (tags.includes(merchandisingLabels.PROMO)) {
    return merchandisingLabels.PROMO;
  }
  if (tags.includes(merchandisingLabels.LOWERED_PRICE)) {
    return merchandisingLabels.LOWERED_PRICE;
  }

  return undefined;
};

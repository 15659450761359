import type { ActionCreator } from 'redux';

import type { ActionWithPayload } from '@/domains/core/applicationState/interfaces';

import { WishListMiniPopupState } from './interfaces/HeaderLeonidas.model';

export const SET_MENU_VISIBILITY =
  'productDiscovery/HeaderLeonidas/SET_MENU_VISIBLE';

export const SET_FOCUSED_SUGGESTION_POSITION =
  'productDiscovery/HeaderLeonidas/SET_FOCUSED_SUGGESTION_POSITION';

export type SetFocusedSuggestionPositionAction = ActionWithPayload<
  { value: number },
  typeof SET_FOCUSED_SUGGESTION_POSITION
>;

export const setFocusedSuggestionPosition: ActionCreator<
  SetFocusedSuggestionPositionAction
> = (value: number) => ({
  payload: { value },
  type: SET_FOCUSED_SUGGESTION_POSITION,
});

type SetMenuVisibilityAction = ActionWithPayload<
  { visible: boolean },
  typeof SET_MENU_VISIBILITY
>;

export const setMenuVisibility: ActionCreator<SetMenuVisibilityAction> = (
  visible: boolean,
) => ({
  payload: { visible },
  type: SET_MENU_VISIBILITY,
});

export const SET_SELECTED_FAMILY =
  'productDiscovery/HeaderLeonidas/SET_SELECTED_FAMILY';

type SetSelectedFamilyAction = ActionWithPayload<
  { family: number; category?: number },
  typeof SET_SELECTED_FAMILY
>;

export const SET_CONNECT_BUTTON_VISIBILITY =
  'productDiscovery/HeaderLeonidas/SET_CONNECT_BUTTON_VISIBILITY';

export const WISHLIST_MINI_POPUP_SHOW =
  'productDiscovery/HeaderLeonidas/WISHLIST_MINI_POPUP_SHOW';

export const WISHLIST_MINI_POPUP_HIDE =
  'productDiscovery/HeaderLeonidas/WISHLIST_MINI_POPUP_HIDE';

export const WISHLIST_MINI_POPUP_EVENT =
  'productDiscovery/HeaderLeonidas/WISHLIST_MINI_POPUP_EVENT';

type SetConnectButtonVisibilityAction = ActionWithPayload<
  { visible: boolean },
  typeof SET_CONNECT_BUTTON_VISIBILITY
>;

type ShowWishlistMiniPopupAction = ActionWithPayload<
  {
    visible?: boolean;
    imageUrl?: string;
    message: string;
  },
  typeof WISHLIST_MINI_POPUP_SHOW
>;

type HideWishlistMiniPopupAction = ActionWithPayload<
  void,
  typeof WISHLIST_MINI_POPUP_HIDE
>;

type ClickWishlistMiniPopupAction = ActionWithPayload<
  { eventId: string },
  typeof WISHLIST_MINI_POPUP_EVENT
>;

export const setConnectButtonVisibility: ActionCreator<
  SetConnectButtonVisibilityAction
> = (visible: boolean) => ({
  payload: { visible },
  type: SET_CONNECT_BUTTON_VISIBILITY,
});

export const showWishlistPopup: ActionCreator<ShowWishlistMiniPopupAction> = (
  payload: WishListMiniPopupState,
) => ({
  payload: { ...payload },
  type: WISHLIST_MINI_POPUP_SHOW,
});

export const hideWishlistPopup: ActionCreator<
  HideWishlistMiniPopupAction
> = () => ({
  type: WISHLIST_MINI_POPUP_HIDE,
});

export const interactiveWishlistMiniPopupEvent: ActionCreator<
  ClickWishlistMiniPopupAction
> = (actionId: string) => ({
  type: WISHLIST_MINI_POPUP_EVENT,
  payload: { eventId: actionId },
});

export const setSelectedFamily: ActionCreator<SetSelectedFamilyAction> = (
  family: number,
  category?: number,
) => ({
  payload: { family, category },
  type: SET_SELECTED_FAMILY,
});

export const SET_SELECTED_CATEGORY =
  'productDiscovery/HeaderLeonidas/SET_SELECTED_CATEGORY';

type SetSelectedCategoryAction = ActionWithPayload<
  { category: number },
  typeof SET_SELECTED_CATEGORY
>;

export const setSelectedCategory: ActionCreator<SetSelectedCategoryAction> = (
  category: number,
) => ({
  payload: { category },
  type: SET_SELECTED_CATEGORY,
});

export type Action =
  | SetConnectButtonVisibilityAction
  | SetMenuVisibilityAction
  | SetSelectedFamilyAction
  | SetSelectedCategoryAction
  | SetFocusedSuggestionPositionAction
  | ShowWishlistMiniPopupAction
  | HideWishlistMiniPopupAction
  | ClickWishlistMiniPopupAction;

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { AutoCompleteSuggestions } from '../interfaces/suggestions';

export const suggestionsAutoCompleteSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): AutoCompleteSuggestions | undefined =>
  state.productDiscovery?.searchBar?.autoComplete;

export const useSuggestionsAutoComplete = () =>
  useSelector(suggestionsAutoCompleteSelector);

import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import { type FunctionComponent } from 'react';

export const ArrowDown: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconsSystemArrowDown" viewBox="0 0 24 24" {...props}>
    <path
      d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 111.41 1.41l-4.59 4.59a.996.996 0 01-1.41 0L6.7 10.7a.996.996 0 010-1.41c.39-.38 1.03-.39 1.42 0z"
      fill="currentColor"
    />
  </Svg>
);

ArrowDown.displayName = 'ArrowDown';

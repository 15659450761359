import { isB2B } from '@/core/settings/utils';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';

import type {
  LinkViewMoreTracking,
  ProductCardClickTracking,
  ProductCardQueryClickTracking,
  ProductsViewedTracking,
} from '../../interfaces/tracking';

export const sendProductClickTracking = (
  products: Product[],
  product: Product,
  position: number,
  query: string,
) => {
  Gtm.push<ProductCardClickTracking>({
    event: 'interaction_product click',
    event_trigger: `Search bar`,
    amp_event_name: 'Click on product card',
    is_redirected_from_search: 'True',
    playlist_name: 'product_suggestion',
    playlist_reconciliation: 'product_suggestion_search_bar',
    search_query: query,
    search_keywords: undefined,
    search_type: 'products suggestions',
    search_item_position: position,
    autocomplete_item_label: product.title,
    autocomplete_item_type: 'product_suggestion',
    autocomplete_item_position: products.findIndex(
      ({ productId }) => productId === product.productId,
    ),
    autocomplete_items_count: products.length,
    mgr: product.cpc,
    pr_products: processPrProducts(products),
  });
};

export const sendQueryProductClickTracking = (
  products: Product[],
  product: Product,
  position: number,
  query: string,
) => {
  Gtm.push<ProductCardQueryClickTracking>({
    event: 'interaction_search query result',
    e_category: 'navigation',
    e_action: 'Search query result',
    event_trigger: `header ${isB2B() ? 'b2b' : 'b2c'} standard`,
    amp_event_name: 'Search query result',
    is_redirected_from_search: 'True',
    search_query: query,
    search_keywords: undefined,
    search_type: 'products suggestions',
    search_item_position: position,
    autocomplete_item_label: product.title,
    autocomplete_item_type: 'product_suggestion',
    autocomplete_item_position: products.findIndex(
      ({ productId }) => productId === product.productId,
    ),
    autocomplete_items_count: products.length,
  });
};

export const sendViewedTracking = (products: Product[]) => {
  Gtm.push<ProductsViewedTracking>({
    event: 'interaction_view_item_list',
    e_category: 'ecommerce',
    event_trigger: `Search bar`,
    amp_event_name: 'Display playlist',
    block_clicked: 'suggested_product',
    playlist_name: 'product_suggestion',
    playlist_type: `${products.some((product) => product.isSponsored) ? 'hybrid' : 'natural'}`,
    page_type: 'search',
    playlist_reconcialiation: 'product_suggestion_search_bar',
    interaction_hit: 'True',
    number_sp_display: products.filter((p) => p.isSponsored).length,
    pr_products: processPrProducts(products),
  });
};

export const sendViewMoreClickTracking = () => {
  Gtm.push<LinkViewMoreTracking>({
    event: 'interaction_click on view more products',
    event_trigger: `header ${isB2B() ? 'b2b' : 'b2c'} standard`,
    amp_event_name: 'Click on view more products',
    e_category: 'navigation',
    e_action: 'Click on view more products',
    e_label: 'products',
    interaction_hit: 'False',
  });
};

function processPrProducts(products: Product[]) {
  return products.map((product) => ({
    pr_id: product.articleId,
    pr_product_id: parseInt(product.productId, 10),
    pr_price: product.prices.main,
    pr_brand_id: product.brandId,
    pr_brand_name: product.brandName,
    pr_category_level_name_1: product.categoriesLast[0],
    pr_article_id: product.articleId,
    pr_name: product.title,
    pr_seller_id: product.sellerId,
    pr_model_id: product.modelId,
    pr_position: products.findIndex(
      ({ productId }) => productId === product.productId,
    ),
    pr_lowest_category_id: 0,
    pr_technical_attributes: undefined,
    pr_is_second_hand_product: false,
    pr_is_sponsored: product.isSponsored,
    pr_is_mf: product.isMmf,
  }));
}

import type { MsRecommendationProcessedResponse } from '../../../interfaces/msRecommendation';
import type { Product } from '../../../interfaces/product';
import type {
  PageType,
  Recommendations,
} from '../../../interfaces/productRecommendation';

export const initialState: ProductRecommendationState = {
  fetching: false,
};

export interface ProductRecommendationState {
  fetching: boolean;
  recommendationsConfig?: MsRecommendationProcessedResponse;
  recommendations?: Recommendations;
  topSaleProducts?: Product[];
  error?: string;
}

export interface ProductRecommendationParams {
  pageType: string;
  engineName?: string;
  originalName?: string;
  productId?: string | number;
  categoryId?: string | number;
  modelId?: string | number;
  qaId?: string;
  productIds?: string;
  categoryIds?: string;
  modelIds?: string;
  withABPools?: boolean;
}

export interface v3ProductRecommendationParams {
  engineNames: string[];
  pageType?: PageType;
  productIds?: string[] | number[];
  categoryIds?: string[] | number[];
  modelIds?: string[] | number[];
}

interface RecommendationEngine {
  engineName?: string;
  productCollection?: {
    modelIds: number[];
  };
}

export interface RecommendationsByEngines {
  recommendationEngines: RecommendationEngine[];
}

import classnames from 'classnames';
import { FunctionComponent } from 'react';

import { InfoOutline as InfoIcon } from '@/core/tamagoshi/icons/InfoOutline';
import { Leaf as IconLeaf } from '@/core/tamagoshi/icons/Leaf';
import { RoundFrance as IconRoundFrance } from '@/core/tamagoshi/icons/RoundFrance';
import {
  merchandisingLabels,
  type MerchandisingLabel,
} from '@/core/tamagoshi/MerchandisingTag/interfaces';
import { Tag } from '@/core/tamagoshi/Tag/Tag';
import { Text } from '@/domains/core/tamagoshi/Text/Text';

import type { MiniProductCardTranslations } from '../MiniProductCard/interfaces';

import styles from './MerchandisingTag.module.scss';

interface MerchandisingTagProps {
  className?: string;
  tag: MerchandisingLabel;
  translations: MiniProductCardTranslations['tags'];
  onAdvertiserLabelClicked?(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): unknown;
}

export const MerchandisingTag: FunctionComponent<MerchandisingTagProps> = ({
  className,
  tag,
  translations,
  onAdvertiserLabelClicked,
  ...props
}) => {
  const rootClassnames = classnames(styles.root, className);

  return (
    <div {...props} className={rootClassnames}>
      {tag === merchandisingLabels.PROMO && translations?.promo?.label && (
        <Tag className={styles.item} color="red">
          {translations.promo.label}
        </Tag>
      )}
      {tag === merchandisingLabels.COUPON && translations?.coupon?.label && (
        <Tag className={styles.item} color="blue">
          {translations.coupon.label}
        </Tag>
      )}
      {tag === merchandisingLabels.IS_ECO_RESPONSABLE &&
        translations?.isEcoResponsible?.label && (
          <Tag className={styles.item} color="green" border>
            <IconLeaf />
            {translations.isEcoResponsible.label}
          </Tag>
        )}
      {tag === merchandisingLabels.IS_LOCAL && translations?.isLocal?.label && (
        <Tag className={styles.item} color="darkBlue" border>
          <IconRoundFrance />
          {translations.isLocal.label}
        </Tag>
      )}
      {tag === merchandisingLabels.TOP_SALE && translations?.topSale?.label && (
        <Tag className={styles.item} color="yellow">
          {translations.topSale.label}
        </Tag>
      )}
      {tag === merchandisingLabels.BLACKFRIDAY &&
        translations?.blackFriday?.label && (
          <Tag className={styles.item} color="black">
            {translations.blackFriday.label}
          </Tag>
        )}
      {tag === merchandisingLabels.SALES && translations?.sales?.label && (
        <Tag className={styles.item} color="red">
          {translations.sales.label}
        </Tag>
      )}
      {tag === merchandisingLabels.MANOMANODAYS &&
        translations?.manomanoDays?.label && (
          <Tag className={styles.item} color="red">
            {translations.manomanoDays.label}
          </Tag>
        )}
      {tag === merchandisingLabels.SPONSORED &&
        translations?.sponsored?.label &&
        onAdvertiserLabelClicked && (
          <button
            className={styles.button}
            onClick={onAdvertiserLabelClicked}
            data-testid="info-label"
          >
            <Text tag="span" type="small" className={styles.root}>
              <span className={styles.label}>
                {translations.sponsored.label}
              </span>
              <InfoIcon className={styles.info} />
            </Text>
          </button>
        )}
    </div>
  );
};

MerchandisingTag.displayName = 'MerchandisingTag';

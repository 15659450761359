import { FunctionComponent, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorMessage } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/ErrorMessage/ErrorMessage';
import { ProductsList } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/ProductsList/ProductsList';
import { Skeleton } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/Skeleton/Skeleton';
import type { ProductSuggestionStatus } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/model';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';
import { setSearchSuggestionsVisibility } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.actions';
import { useAlgoliaCTRTrackingEvent } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useAlgoliaCTRTrackingEvent/useAlgoliaCTRTrackingEvent';
import { useMatchMedia } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useMatchMedia';
import { useProductSuggestionsQueryDetails } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useProductSuggestionsQueryDetails';
import { useProductSuggestionsStatus } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useProductSuggestionsStatus';
import { useSearchInput } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchInput';
import type { AlgoliaQueryDetails } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/algolia';
import { selectNbHits } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/selectors/selectNbHits';
import {
  sendSponsoredProductClickTracking,
  sendSponsoredProductImpressionTracking,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/services/tracking/sponsoredProductEvents.tracking';

import { DEFAULT_STATUS, Status } from '../../constants';
import { SeeMoreButton } from '../SeeMoreButton/SeeMoreButton';
import {
  sendProductClickTracking,
  sendQueryProductClickTracking,
  sendViewedTracking,
  sendViewMoreClickTracking,
} from './tracking';
import { seeMore } from './translations';

export interface SearchResultProductsProps {
  className?: string;
  tag?: React.ComponentType | keyof JSX.IntrinsicElements;
  status?: ProductSuggestionStatus;
  products?: Product[];
}

export const SearchResultProducts: FunctionComponent<
  SearchResultProductsProps
> = ({ className, tag: Tag = 'div', products = [] }) => {
  const dispatch = useDispatch();
  const nbHits = useSelector(selectNbHits);
  const searchInput = useSearchInput();
  const isDesktop = useMatchMedia('(min-width: 1024px)');
  const status = useProductSuggestionsStatus() || DEFAULT_STATUS;
  const queryDetails =
    useProductSuggestionsQueryDetails() as AlgoliaQueryDetails;

  const { sendAlgoliaProductCTRTrackingEvent } = useAlgoliaCTRTrackingEvent();

  const seeMoreLinkClick = () => {
    sendViewMoreClickTracking();
    dispatch(setSearchSuggestionsVisibility(false));
  };

  const productCardClick = async (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    product: Product,
    prPosition: number,
  ) => {
    const { saveInSearchHistory } = await import(
      '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/utils/searchHistory/save'
    );
    saveInSearchHistory(searchInput);

    window.requestIdleCallback(() => {
      sendProductClickTracking(products, product, prPosition, searchInput);
      sendQueryProductClickTracking(products, product, prPosition, searchInput);
      sendAlgoliaProductCTRTrackingEvent(product, prPosition, queryDetails);
      sendSponsoredProductClickTracking(product);
    });

    dispatch(setSearchSuggestionsVisibility(false));
  };

  const seeMoreButtonComponentLabel = seeMore.format({
    number: nbHits || 0,
    isMax: (nbHits || 0) >= 500,
  }) as string;

  const sendViewedTrack = useCallback(() => {
    if (products.length > 0) {
      window.requestIdleCallback(() => {
        sendViewedTracking(products);
        sendSponsoredProductImpressionTracking(products);
      });
    }
  }, [products]);

  useLayoutEffect(() => {
    if (status === Status.DONE) {
      const timerId = setTimeout(sendViewedTrack, 1000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [status, sendViewedTrack]);

  const getContentByStatus = () => {
    switch (status) {
      case Status.DONE:
        return (
          <ProductsList
            values={products}
            button={
              <SeeMoreButton
                onClick={seeMoreLinkClick}
                label={seeMoreButtonComponentLabel}
              />
            }
            onClickProductCard={productCardClick}
            isDesktop={isDesktop}
            showFavorites
          />
        );
      case Status.WAITING:
      case Status.FETCHING:
        return <Skeleton />;

      case Status.ERROR:
      default:
        return <ErrorMessage />;
    }
  };

  return <Tag className={className}>{getContentByStatus()}</Tag>;
};

SearchResultProducts.displayName = 'SearchResultProducts';

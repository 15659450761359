import { translate, translateFormatter } from '@/core/localization/translate';
export const countdownDaysLeft = translateFormatter<{
  days: number;
}>("Acaba en {days, plural, =1 {# d\xEDa} other {# d\xEDas}}", undefined, undefined, "es");
export const countdownTimeLeft = translateFormatter<{
  hoursFormatted: string;
  minutesFormatted: string;
  secondsFormatted: string;
}>("{hoursFormatted}h {minutesFormatted}m {secondsFormatted}s", undefined, undefined, "es");
export const {
  countdownDays,
  countdownHours,
  countdownMinutes,
  countdownSeconds
} = translate({
  "countdownDays": "D\xCDAS",
  "countdownHours": "HORAS",
  "countdownMinutes": "MINS",
  "countdownSeconds": "SEGS"
});
import {
  addToCartInitialState,
  type AddToCartState,
} from '@/shoppingCart/AddToCart/AddToCart.interfaces';

import type { SpartacuxShoppingCartState } from '../../ShoppingCartState';

export const selectAddToCartState = (
  state: SpartacuxShoppingCartState,
): AddToCartState =>
  state.shoppingCart?.addToCartModal || addToCartInitialState;

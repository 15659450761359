import { isB2B } from '@/domains/core/settings/utils';
import type { PreHeaderModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';

const stripEmojis = (str: string) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    )
    .replace(/\s+/g, ' ')
    .trim();

export const GTM_CLICK_ON_PRE_HEADER = (
  message: string | undefined,
  blockClicked: 'single' | 'multiple',
  pageType: string | undefined,
) => ({
  event: 'interaction_click on pre-header link',
  amp_event_name: 'Click on pre-header link',
  e_action: 'Click on pre-header link',
  e_category: 'navigation',
  e_label: isB2B() ? 'pre-header b2b' : 'pre-header b2c',
  event_trigger: isB2B() ? 'pre-header b2b' : 'pre-header b2c',
  click_element: stripEmojis(message || 'unknown'),
  block_clicked: blockClicked,
  interaction_hit: 'False',
  page_type: pageType || 'unknown',
});

export const GTM_DISPLAY_PRE_HEADER_MODAL = (
  item: PreHeaderModel,
  itemPosition: number,
  pageType: string | undefined,
) => ({
  event: 'interaction_display banner',
  amp_event_name: 'Display banner',
  e_action: 'Display banner',
  e_category: 'navigation',
  e_label: 'intermediary modal',
  event_trigger: 'intermediary modal',
  campaign_name: stripEmojis(item.message || 'unknown'),
  campaign_id: item.id,
  campaign_market: isB2B() ? 'b2b' : 'b2c',
  campaign_position: itemPosition,
  space_slug: 'intermediary modal',
  page_type: pageType || 'unknown',
});

export const GTM_CLICK_ON_PRE_HEADER_MODAL_ITEM = (
  item: PreHeaderModel,
  itemPosition: number,
  pageType: string | undefined,
) => ({
  event: 'interaction_click on banner',
  amp_event_name: 'Click on banner',
  e_action: 'Click on banner',
  e_category: 'navigation',
  e_label: 'intermediary modal',
  event_trigger: 'intermediary modal',
  campaign_name: stripEmojis(item.message || 'unknown'),
  campaign_id: item.id,
  campaign_market: isB2B() ? 'b2b' : 'b2c',
  campaign_position: itemPosition,
  space_slug: 'intermediary modal',
  page_type: pageType || 'unknown',
});

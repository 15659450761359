import { useEffect, useRef } from 'react';

/**
 * Custom hook for creating a mutable reference that always holds the latest value.
 *
 * This hook creates a mutable reference to store a value and updates the reference whenever
 * the input value changes, ensuring that it always holds the latest value. It is particularly
 * useful for ensuring you can access the most up-to-date value of props or state inside an
 * event handler or `useEffect()` without having to explicitly include the value in a dependency array.
 *
 * @param value - The initial value to be stored in the reference.
 * @typeparam T - The type of the value being stored in the reference.
 * @returns A reference object that always holds the latest value.
 *
 * @example
 * ```jsx
 *
 * function ExampleComponent() {
 *   const [count, setCount] = useState(0);
 *
 *   // Create a mutable reference to the `count` state using the `useLatestRef` hook.
 *   const countRef = useLatestRef(count);
 *
 *   // This effect will run whenever `count` changes, but `countRef` will always
 *   // hold the latest value, even if the effect closes over an older version of `count`.
 *   useEffect(() => {
 *     setTimeout(() => {
 *       // Even if `count` has changed, `countRef.current` holds the latest value.
 *       console.log(`Count from countRef: ${countRef.current}`);
 *     }, 1000);
 *   }, [count]);
 *
 *   return (
 *     <div>
 *       <p>Count: {count}</p>
 *       <button onClick={() => setCount(count + 1)}>Increment</button>
 *     </div>
 *   );
 * }
 *
 * ```
 */
export function useLatestRef<T>(value: T): { readonly current: T } {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

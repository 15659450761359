import { translate } from '@manomano-internal/i18n/esm/placeholders';
import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import styles from './ErrorMessage.module.scss';
const {
  message
} = translate({
  "message": "Se ha producido un error. Por favor, int\xE9ntalo m\xE1s tarde."
});
interface Props {
  className?: string;
}
export const ErrorMessage: FunctionComponent<Props> = ({
  className
}) => {
  const rootClassnames = classnames(styles.root, className);
  return <div className={rootClassnames}>
      <div className={styles.message}>
        <span className={styles.text}>{message}</span>
      </div>
    </div>;
};
ErrorMessage.displayName = 'ErrorMessage';
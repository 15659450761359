import { translate } from '@/domains/core/localization/translate';
export const {
  leonidasMenuExploreCatalogue,
  leonidasMenuLinksTitle,
  leonidasMenuProductsTitle,
  leonidasMenuSeeMoreTitle
} = translate({
  "leonidasMenuExploreCatalogue": "Ver el cat\xE1logo",
  "leonidasMenuLinksTitle": "Nuestra experiencia",
  "leonidasMenuProductsTitle": "Productos",
  "leonidasMenuSeeMoreTitle": "Ver m\xE1s"
});
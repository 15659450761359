import {
  CHANGE_EMAIL_ADDRESS,
  HIDE_NEWSLETTER_MODAL,
  HIDE_NEWSLETTER_SUCCESS_MODAL,
  SHOW_INVALID_EMAIL_ERROR,
  SHOW_NEWSLETTER_MODAL,
  SHOW_NEWSLETTER_SUCCESS_MODAL,
  SUBSCRIBE_NEWSLETTER,
  SUBSCRIBE_NEWSLETTER_FAILED,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  type Action,
} from './Newsletter.actions';
import { initialState, type Newsletter } from './Newsletter.model';

export const reducer = (
  state: Newsletter = initialState,
  action: Action,
): Newsletter => {
  switch (action.type) {
    case SHOW_NEWSLETTER_MODAL:
      return {
        ...state,
        isModalNewsletterVisible: true,
      };
    case SHOW_NEWSLETTER_SUCCESS_MODAL:
      return {
        ...state,
        isModalSuccessVisible: true,
      };
    case SHOW_INVALID_EMAIL_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case HIDE_NEWSLETTER_MODAL:
      return {
        ...state,
        isModalNewsletterVisible: false,
      };
    case HIDE_NEWSLETTER_SUCCESS_MODAL:
      return {
        ...state,
        isModalSuccessVisible: false,
      };
    case CHANGE_EMAIL_ADDRESS:
      return {
        ...state,
        error: null,
        emailAddress: action.emailAddress,
      };
    case SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        emailAddress: action.emailAddress,
        error: null,
      };
    case SUBSCRIBE_NEWSLETTER_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case SUBSCRIBE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        emailAddress: '',
        isModalNewsletterVisible: false,
        isModalSuccessVisible: true,
      };
    default:
      return state;
  }
};

export default reducer;

import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const promosVisibilitySelector = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean | undefined =>
  state.productDiscovery?.headerLeonidas?.ui.promosVisible;

export const usePromosVisibility = () => useSelector(promosVisibilitySelector);

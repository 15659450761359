import type { MultipleQueriesQuery } from '@algolia/client-search';

import { getDefaultIndex } from '@/domains/productDiscovery/algolia/settings/settings.common';
import { DEFAULT_ATTRIBUTES_TO_RETRIEVE } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/services/algolia/constants';
import { PRODUCTS_IN_SEARCHBAR_SLIDER } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/constants';
import { buildPlainMultipleQueriesQuery } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/services/algolia/buildMultipleQueriesQuery';

const FAMILY_IDS = [823, 463, 4605, 1, 673, 173, 205, 323, 4203, 116, 3943];

interface BuildTopProducts {
  filters: string;
  options?: {
    facetFilters?:
      | string
      | readonly string[]
      | ReadonlyArray<readonly string[]>;
    hitsPerPage?: number;
  };
}

interface TopSales {
  alternateTopSalesRequest?: boolean;
  options?: BuildTopProducts['options'];
}

interface Statics {
  staticSuggestions?: string[];
  options?: BuildTopProducts['options'];
}

interface LastSeenProducts {
  lastSeenProducts?: string[];
  options?: BuildTopProducts['options'];
}

interface SimilarityProducts {
  similarityProducts?: string[];
  options?: BuildTopProducts['options'];
}

export const buildTopProductsSuggestionsSearchParameters = ({
  filters,
  options,
}: BuildTopProducts): MultipleQueriesQuery =>
  buildPlainMultipleQueriesQuery('', getDefaultIndex().value, {
    restrictSearchableAttributes: [],
    filters,
    hitsPerPage: options?.hitsPerPage,
    attributesToRetrieve: DEFAULT_ATTRIBUTES_TO_RETRIEVE,
    responseFields: ['hits'],
    attributesToHighlight: [],
    ruleContexts: [],
    facetFilters: options?.facetFilters,
  });

export const buildTopSalesSuggestionsSearchParameters = ({
  options,
}: TopSales): MultipleQueriesQuery =>
  buildTopProductsSuggestionsSearchParameters({
    options: {
      facetFilters: [],
      hitsPerPage: PRODUCTS_IN_SEARCHBAR_SLIDER,
      ...options,
    },
    filters: FAMILY_IDS.map((id) => `categories.l0.id:${id}`).join(' OR '),
  });

export const buildStaticProductsSuggestionsSearchParameters = ({
  staticSuggestions = [],
  options,
}: Statics): MultipleQueriesQuery =>
  buildTopProductsSuggestionsSearchParameters({
    options: {
      facetFilters: [],
      hitsPerPage: PRODUCTS_IN_SEARCHBAR_SLIDER,
      ...options,
    },
    filters: staticSuggestions
      .map(
        (modelId, i) =>
          `model_id:${modelId}<score=${staticSuggestions.length - i}>`,
      )
      .join(' OR '),
  });

export const buildLastSeenProductsSearchParameters = ({
  lastSeenProducts = [],
  options,
}: LastSeenProducts): MultipleQueriesQuery =>
  buildTopProductsSuggestionsSearchParameters({
    options: {
      facetFilters: [],
      hitsPerPage: PRODUCTS_IN_SEARCHBAR_SLIDER,
      ...options,
    },
    filters: lastSeenProducts
      .map(
        (objectId, i) =>
          `objectID:${objectId}<score=${lastSeenProducts.length - i}>`,
      )
      .join(' OR '),
  });

export const buildSimilarityProductsSearchParameters = ({
  similarityProducts = [],
  options,
}: SimilarityProducts): MultipleQueriesQuery =>
  buildTopProductsSuggestionsSearchParameters({
    options: {
      facetFilters: [],
      hitsPerPage: PRODUCTS_IN_SEARCHBAR_SLIDER,
      ...options,
    },
    filters: similarityProducts
      .map(
        (modelId, i) =>
          `model_id:${modelId}<score=${similarityProducts.length - i}>`,
      )
      .join(' OR '),
  });

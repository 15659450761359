import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type React from 'react';

import styles from '../../Menu.module.scss';

interface Props extends React.PropsWithChildren {
  className?: string;
}

export const MenuSeparator: FunctionComponent<Props> = ({
  children,
  className,
}) => (
  <li
    data-testid="menuSeparator"
    className={classnames(styles.menuSeparator, className)}
  >
    <span>{children}</span>
  </li>
);

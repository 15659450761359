import { get } from '@/productDiscovery/HeaderLeonidas/services/utils/cache';

import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';
import type { AutoCompleteSuggestions } from '../interfaces/Header.search.model';
import type { MenuCategory } from '../interfaces/MenuTree.model';

export const selectMenuCategories = (
  state: SpartacuxProductDiscoveryDomainState,
): MenuCategory[] | undefined => {
  if (typeof window === 'undefined') {
    return get('initialReduxStateProductDiscoveryHeaderMenuCategory')?.data
      .items;
  }

  return state.productDiscovery?.header?.menu?.category?.items;
};

export const selectAutoCompleteSuggestions = (
  state: SpartacuxProductDiscoveryDomainState,
): AutoCompleteSuggestions | undefined =>
  state.productDiscovery?.search?.suggestions as AutoCompleteSuggestions;

export const selectFetchingSuggestions = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean => !!state.productDiscovery?.search?.fetchingSuggestions;

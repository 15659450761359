import { put, takeLatest } from 'typed-redux-saga';

import {
  CLEAN_CART_OVERVIEW_REQUEST,
  CLEAN_CART_OVERVIEW_SUCCESS,
} from '@/shoppingCart/CartOverview/actions/GetCartOverview.actions';
import { LOCAL_STORAGE_KEY } from '@/shoppingCart/CartOverview/CartOverview.constants';
import { removeEntrieFromLocalStorage } from '@/shoppingCart/CartOverview/localStorage/removeEntrieFromLocalStorage';

export function* cleanCartOverviewSaga() {
  removeEntrieFromLocalStorage({ key: LOCAL_STORAGE_KEY });
  yield* put({ type: CLEAN_CART_OVERVIEW_SUCCESS });
}

export function* watchCleanCartOverview() {
  yield* takeLatest(CLEAN_CART_OVERVIEW_REQUEST, cleanCartOverviewSaga);
}

import type { MultipleQueriesQuery } from '@algolia/client-search';
import type { SearchClient } from 'algoliasearch';

import { getAppSetting } from '@/domains/core/settings/appSettings';
import {
  getClientAppKey,
  getOptions,
} from '@/domains/productDiscovery/algolia/settings/settings.client';
import {
  getAppId,
  getProxySettings,
  getRequestOptions,
} from '@/domains/productDiscovery/algolia/settings/settings.common';
import { searchEngineProxyPool } from '@/domains/productDiscovery/algolia/settings/utils/trafficDistribution';

import type { SuggestionsSearchResults } from '../../interfaces/algolia';

let suggestionsSearchClient: SearchClient | null = null;
let suggestionsRedirectionClient: SearchClient | null = null;

let productsClient: SearchClient | null = null;

const getProductsTimeout = (): number =>
  parseInt(
    getAppSetting('ALGOLIA_HEADER_SEARCH_PRODUCTS_TIMEOUT') as string,
    10,
  );
const getSuggestionsSearchTimeout = (): number =>
  parseInt(
    getAppSetting('ALGOLIA_HEADER_SEARCH_SUGGESTIONS_TIMEOUT') as string,
    10,
  );
const getSuggestionsRedirectionsTimeout = (): number =>
  parseInt(
    getAppSetting('ALGOLIA_SUGGESTIONS_REDIRECTIONS_TIMEOUT') as string,
    10,
  );

const suggestionsClientInstance = async (
  timeout: number,
): Promise<SearchClient> => {
  const { default: algoliasearch } = await import('algoliasearch');

  const client = algoliasearch(
    getAppId(),
    getClientAppKey(),
    getOptions(timeout),
  );

  return client;
};

export const createSuggestionsSearchClientInstance = async () => {
  if (!suggestionsSearchClient) {
    suggestionsSearchClient = await suggestionsClientInstance(
      getSuggestionsSearchTimeout(),
    );
  }

  return suggestionsSearchClient;
};

export const createSuggestionsRedirectionsClientInstance = async () => {
  if (!suggestionsRedirectionClient) {
    suggestionsRedirectionClient = await suggestionsClientInstance(
      getSuggestionsRedirectionsTimeout(),
    );
  }

  return suggestionsRedirectionClient;
};

export const productsSearchClientInstance = async (
  searchEngineProxyEnabled = false,
): Promise<SearchClient> => {
  if (!productsClient) {
    const { default: algoliasearch } = await import('algoliasearch');

    const withSearchEngineProxy =
      searchEngineProxyEnabled && searchEngineProxyPool();

    const appId = getAppId();
    const appKey = getClientAppKey();
    const timeout = getProductsTimeout();
    const proxySettings = getProxySettings(withSearchEngineProxy);
    const options = getOptions(timeout, proxySettings, withSearchEngineProxy);

    productsClient = algoliasearch(appId, appKey, options);
  }

  return productsClient;
};

export const searchMultiple = async (
  searchClient: SearchClient,
  searchParameters: MultipleQueriesQuery[],
  userToken: string,
) => {
  const requestOptions = getRequestOptions({ userToken });
  const { results } =
    await searchClient.multipleQueries<SuggestionsSearchResults>(
      searchParameters,
      requestOptions,
    );

  return results;
};

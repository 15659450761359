import { useSelector } from 'react-redux';

import { marketingCampaignsSelector } from '@/domains/productDiscovery/CommercialAnimation/commercialAnimation.selectors';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const useMarketingCampaigns = () =>
  useSelector<
    SpartacuxProductDiscoveryDomainState,
    BannerCampaign[] | undefined
  >(marketingCampaignsSelector);

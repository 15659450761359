import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { SearchResultProducts } from '@/productDiscovery/HeaderLeonidas/modules/productSuggestions/modules/searchResults/components/SearchResultProducts/SearchResultProducts';
import { SuggestionBlock } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/SuggestionBlock/SuggestionBlock';
import { EmptyLeftSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/components/AutoCompleteSuggestions/components/EmptyLeftSuggestions/EmptyLeftSuggestions';
import { useRemoveHistorySuggestion } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/hooks/useRemoveHistorySuggestion';
import { useSuggestionClick } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/hooks/useSuggestionClick';
import { autocomplete as autocompleteTranslations } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/translations';
import { calculateStartingPosition } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/utils/calculateStartingPosition';
import { useAvailableSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useAvailableSuggestions';
import { useFetchingSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useFetchingSuggestions';
import { useMatchMedia } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useMatchMedia';
import { Suggestion } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/suggestions';

import { SuggestionsSkeleton } from '../SuggestionsSkeleton/SuggestionsSkeleton';

import styles from '../../Suggestions.module.scss';

export const AutoCompleteSuggestions: FunctionComponent = () => {
  const availableSuggestions = useAvailableSuggestions();
  const { queries = [], products, brands } = availableSuggestions || {};
  const onRemoveHistorySuggestion = useRemoveHistorySuggestion();

  const values = availableSuggestions
    ? Object.values(availableSuggestions).flat()
    : [];

  const handleSuggestionClick = useSuggestionClick({
    values,
  });

  const isDesktop = useMatchMedia('(min-width: 1024px)');

  return (
    <>
      <NoLeftSuggestions queries={queries} brands={brands} />

      {queries.length > 0 && (
        <SuggestionBlock
          suggestions={queries}
          onClick={handleSuggestionClick}
          onRemove={onRemoveHistorySuggestion}
          startingPosition={calculateStartingPosition()}
          autoComplete
        />
      )}

      {brands && brands.length > 0 && (
        <SuggestionBlock
          suggestions={isDesktop ? brands.slice(0, 3) : brands}
          title={autocompleteTranslations.searchBrandTitle}
          onClick={handleSuggestionClick}
          onRemove={onRemoveHistorySuggestion}
          startingPosition={calculateStartingPosition(queries?.length)}
          autoComplete
        />
      )}

      <SearchResultProducts
        tag="li"
        className={classnames(
          styles.suggestionBlock,
          styles.productSuggestions,
        )}
        products={products}
        key={calculateStartingPosition(queries?.length) + 1}
      />
    </>
  );
};

AutoCompleteSuggestions.displayName = 'AutoCompleteSuggestions';

function NoLeftSuggestions({
  queries,
  brands,
}: {
  queries?: Suggestion[];
  brands?: Suggestion[];
}) {
  const isFetching = useFetchingSuggestions();

  const hasNoSuggestions = !queries?.length && !brands?.length;
  const displaySkeleton = isFetching && hasNoSuggestions;
  const displayNoLeftSuggestions = !isFetching && hasNoSuggestions;

  return (
    <>
      {displaySkeleton && <SuggestionsSkeleton withProducts={false} />}
      {displayNoLeftSuggestions && <EmptyLeftSuggestions />}
    </>
  );
}

import { queryData } from '@/domains/core/graphqlClient/queryClient';
import { getPlatform, isB2B } from '@/domains/core/settings/utils';
import type { UserSegments } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { PreHeadersDocument } from '@/productDiscovery/HeaderLeonidas/components/PreHeader/services/graphql/generated/preHeader.query.generated';

export const fetchPreHeadersGql = (userSegments: UserSegments) =>
  queryData(PreHeadersDocument, {
    platform: getPlatform().toUpperCase(),
    channel: 'WEB',
    market: isB2B() ? 'B2B' : 'B2C',
    userSegments: userSegments || {},
  }).then((result: any) => result.preHeaders);

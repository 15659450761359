import {
  SET_CONNECT_BUTTON_VISIBILITY,
  SET_FOCUSED_SUGGESTION_POSITION,
  SET_MENU_VISIBILITY,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_FAMILY,
  WISHLIST_MINI_POPUP_EVENT,
  WISHLIST_MINI_POPUP_HIDE,
  WISHLIST_MINI_POPUP_SHOW,
  type Action,
} from './HeaderLeonidas.actions';
import {
  initialState,
  OVERLAY_MENU,
  OVERLAY_NONE,
  OVERLAY_SEARCH,
  WishListMiniPopupState,
  type HeaderLeonidasState,
} from './interfaces/HeaderLeonidas.model';
import {
  SET_SEARCH_INPUT,
  SET_SEARCH_SUGGESTIONS_VISIBILITY,
  type Action as SearchAction,
} from './modules/searchBar/HeaderLeonidas.searchBar.actions';

export function reducer(
  state: HeaderLeonidasState = initialState,
  action: Action | SearchAction,
): HeaderLeonidasState {
  switch (action.type) {
    case SET_CONNECT_BUTTON_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          connectButtonVisible: !!action.payload?.visible,
          overlay: action.payload?.visible ? OVERLAY_NONE : state.ui.overlay,
          menuVisible: action.payload?.visible ? false : state.ui.menuVisible,
        },
      };

    case SET_MENU_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          overlay: action.payload?.visible ? OVERLAY_MENU : OVERLAY_NONE,
          menuVisible: !!action.payload?.visible,
          promosVisible: action.payload?.visible
            ? false
            : state.ui.promosVisible,
          selectedFamily: !action.payload?.visible
            ? 0
            : state.ui.selectedFamily,
          selectedCategory: !action.payload?.visible
            ? 0
            : state.ui.selectedCategory,
        },
      };

    case SET_SEARCH_INPUT: {
      const inputValue = action.payload?.inputValue || '';
      return {
        ...state,
        ui: {
          ...state.ui,
          search: {
            ...state.ui.search,
            input: inputValue,
          },
          displayEmptySearch: !!state.ui.suggestionsVisible && !inputValue,
        },
      };
    }

    case SET_SEARCH_SUGGESTIONS_VISIBILITY: {
      const visible = action.payload?.visible;
      return {
        ...state,
        ui: {
          ...state.ui,
          overlay: visible ? OVERLAY_SEARCH : OVERLAY_NONE,
          suggestionsVisible: !!visible,
          displayEmptySearch: !state.ui.search?.input,
          menuVisible: visible ? false : state.ui.menuVisible,
          promosVisible: visible ? false : state.ui.promosVisible,
          connectButtonVisible: visible ? false : state.ui.connectButtonVisible,
        },
      };
    }

    case SET_SELECTED_FAMILY:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedFamily: action.payload?.family || 0,
          selectedCategory: action.payload?.category || 0,
        },
      };

    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedCategory: action.payload?.category || 0,
        },
      };

    case SET_FOCUSED_SUGGESTION_POSITION:
      return {
        ...state,
        ui: {
          ...state.ui,
          focusedSuggestionPosition: action.payload?.value || 0,
        },
      };

    case WISHLIST_MINI_POPUP_SHOW:
      return {
        ...state,
        ui: {
          ...state.ui,
          wishlistMiniPopup: {
            visible: true,
            ...action.payload,
          } as WishListMiniPopupState,
        },
      };

    case WISHLIST_MINI_POPUP_HIDE:
      return {
        ...state,
        ui: {
          ...state.ui,
          wishlistMiniPopup: {
            ...state.ui.wishlistMiniPopup,
            visible: false,
          } as WishListMiniPopupState,
        },
      };

    case WISHLIST_MINI_POPUP_EVENT:
      return {
        ...state,
        ui: {
          ...state.ui,
          wishlistMiniPopup: {
            ...state.ui.wishlistMiniPopup,
            effectEventId: action.payload?.eventId,
          } as WishListMiniPopupState,
        },
      };

    default:
      return state;
  }
}

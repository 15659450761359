import classnames from 'classnames';
import React, { type FunctionComponent } from 'react';

import { Skeleton as CustomSkeleton } from '@/core/tamagoshi/Skeleton/Skeleton';

import styles from './Skeleton.module.scss';

interface Props {
  className?: string;
  withAddToCartButton?: boolean;
}

export const Skeleton: FunctionComponent<Props> = React.memo(
  ({ className, withAddToCartButton = true }) => {
    const rootClassnames = classnames(styles.root, className);

    return (
      <div className={rootClassnames} data-testid="products-skeleton">
        <div className={styles.group}>
          {[1, 2, 3, 4, 5, 6].map((key) => (
            <CustomSkeleton className={styles.product} key={`product-${key}`} />
          ))}
        </div>

        {withAddToCartButton && (
          <div className={styles.buttonWrapper}>
            <CustomSkeleton className={styles.seeMoreButton} />
          </div>
        )}
      </div>
    );
  },
);

Skeleton.displayName = 'Skeleton';

import { isB2B } from '@/core/settings/utils';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

export const GTM_CLICK_ON_HEADER_BANNER = (banner: BannerCampaign) => ({
  event: 'interaction_click on header banner',
  e_category: 'ecommerce',
  e_action: 'campaign click',
  e_label: undefined,
  amp_event_name: 'Click on header banner',
  event_trigger: isB2B() ? 'header b2b' : 'header b2c',
  block_clicked: 'header_banner',
  redirection_link: banner.link.url,
  campaigns: [
    {
      campaign_name: banner.name,
      campaign_id: banner.id,
      campaign_creative: undefined,
      campaign_position: 1,
      campaign_market: isB2B() ? 'b2b' : 'b2c',
    },
  ],
  space_slug: 'header_banner',
  interaction_hit: 'False',
});

import { useLayoutEffect, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { ProductsList } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/ProductsList/ProductsList';
import { Title } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/Title/Title';
import type { ProductSuggestionType } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/model';
import { useHighlightedProducts } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/modules/emptySearchResults/components/HighlightedProducts/useHighlightedProducts';
import { setSearchSuggestionsVisibility } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/HeaderLeonidas.searchBar.actions';
import { useAlgoliaCTRTrackingEvent } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useAlgoliaCTRTrackingEvent/useAlgoliaCTRTrackingEvent';
import { useProductSuggestionsQueryDetails } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useProductSuggestionsQueryDetails';
import type { AlgoliaQueryDetails } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/algolia';

import type { Product } from '../../../../interfaces/product';

export interface HighlightedProductsProps {
  products: Product[];
  engineName?: string;
  position?: number;
  type?: ProductSuggestionType;
}

export const HighlightedProducts: FunctionComponent<
  HighlightedProductsProps
> = ({ products, position = 1, engineName, type = 'top' }) => {
  const dispatch = useDispatch();
  const { sendAlgoliaProductCTRTrackingEvent } = useAlgoliaCTRTrackingEvent();
  const queryDetails =
    useProductSuggestionsQueryDetails() as AlgoliaQueryDetails;

  const {
    listTitle,
    isDesktop,
    sendProductCardClickTracking,
    sendPlaylistViewedTracking,
  } = useHighlightedProducts({
    products,
    productsType: type,
    position,
    engineName,
  });

  const onProductCardClickHandler = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    product: Product,
    prPosition: number,
  ) => {
    dispatch(setSearchSuggestionsVisibility(false));

    sendProductCardClickTracking(product, prPosition);
    sendAlgoliaProductCTRTrackingEvent(product, prPosition, queryDetails);
  };

  useLayoutEffect(() => {
    const timerId = setTimeout(sendPlaylistViewedTracking, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [sendPlaylistViewedTracking]);

  return (
    <ProductsList
      values={products}
      title={<Title label={listTitle} />}
      onClickProductCard={onProductCardClickHandler}
      isDesktop={isDesktop}
      showFavorites={false}
    />
  );
};

HighlightedProducts.displayName = 'HighlightedProducts';

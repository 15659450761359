import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDynamicSaga } from '@/domains/core/applicationState/hooks/useDynamicSaga';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { initPreHeaders } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/PreHeader.actions';
import { rootSaga } from '@/domains/productDiscovery/HeaderLeonidas/components/PreHeader/PreHeader.saga';
import type { PreHeaderGql } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/Header.model';
import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

export const preHeadersGqlSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): PreHeaderGql | undefined => state.productDiscovery?.header?.preHeaderGql;

export const useInitPreHeaders = () => {
  useDynamicSaga('preHeaderSaga', rootSaga);
  const preHeader = useSelector(preHeadersGqlSelector);

  const auth = useSelector(authSelector);
  const { hasRetrievedCustomerSegments } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasRetrievedCustomerSegments && !preHeader?.fetched) {
      dispatch(initPreHeaders());
    }
  }, [dispatch, hasRetrievedCustomerSegments, preHeader?.fetched]);

  return preHeader;
};

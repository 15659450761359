import type { Product as ProductAddedToCart } from '@/domains/shoppingCart/AddToCart/AddToCart.interfaces';

import type {
  CartOverviewItems,
  CartOverviewProduct,
} from '../interfaces/CartOverview';
import { formatProducts } from './formatProducts';

export const addProductToCartOverview = (
  addedToCart: ProductAddedToCart[],
  products: CartOverviewItems,
) => {
  let formatCartOverviewProducts: CartOverviewProduct[] = [];
  const x = Object.entries(products);
  if (x) {
    formatCartOverviewProducts = x.map(([key, value]) => ({
      id: Number(key),
      quantity: value,
    }));
  }

  const concattedProducts = formatProducts(addedToCart).concat(
    formatCartOverviewProducts,
  );

  const formattedProducts: CartOverviewProduct[] = concattedProducts.reduce(
    (acc: CartOverviewProduct[], cur: CartOverviewProduct) => {
      const { id } = cur;

      const found: CartOverviewProduct | undefined = acc.find(
        (el: CartOverviewProduct) => el.id === id,
      );
      if (found) {
        found.quantity += cur.quantity;
      } else {
        acc.push(cur);
      }
      return acc;
    },
    [],
  );

  return formattedProducts;
};

import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiIcon } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const ChevronDownIcon = makeTamagoshiIcon((props, ref) => (
  <Svg
    optimizationId="tama-chevron-down"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83893 8.31093L12.0054 12.4355L16.1718 8.31093C16.5906 7.89636 17.2671 7.89636 17.6859 8.31093C18.1047 8.72551 18.1047 9.39522 17.6859 9.8098L12.757 14.6891C12.3383 15.1036 11.6617 15.1036 11.243 14.6891L6.31409 9.8098C5.8953 9.39522 5.8953 8.72551 6.31409 8.31093C6.73289 7.90699 7.42013 7.89636 7.83893 8.31093Z"
      fill="currentColor"
    />
  </Svg>
));

ChevronDownIcon.displayName = 'ChevronDownIcon';

import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import { httpClient } from '@/core/httpClient/httpClient';
import type { RawMSResponse } from '@/productDiscovery/HeaderLeonidas/interfaces/microservices';

interface Value {
  etag?: AxiosResponse['headers']['etag'];
  data?: any;
}

const cache: {
  [key: string]: Value;
} = {};

export const get = (key: string) => cache[key];
export const set = (key: string, value: Value) => {
  cache[key] = value;
  return cache[key];
};

export async function getFromURLOrCache<T>(
  url: string,
  timeout?: number,
): Promise<T> {
  const headers: AxiosRequestConfig['headers'] = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Use-Cache': String(true),
  };

  let cachedValue = get(url);

  if (!cachedValue) {
    cachedValue = set(url, {});
  }

  if (cachedValue.etag) {
    headers['If-None-Match'] = cachedValue.etag;
  }

  const response = await httpClient.get<RawMSResponse<T>>(url, {
    headers,
    validateStatus: (status) => status < 400,
    timeout,
  });

  if (response.status === 304) {
    return cachedValue.data;
  }

  set(url, {
    etag: response.headers?.etag,
    data: response.data.content,
  });

  return response.data.content;
}

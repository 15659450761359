import { useEffect, useState } from 'react';

interface ReturnProps {
  isClient: boolean;
  isServer: boolean;
}

export const useApplicationMounted = (): ReturnProps => {
  const [isClient, setApplicationMounted] = useState(false);

  useEffect(() => {
    setApplicationMounted(true);
  }, []);

  return {
    isClient,
    isServer: !isClient,
  };
};

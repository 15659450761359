import type { FunctionComponent } from 'react';

import { ArrowRight } from '@/core/tamagoshi/icons/ArrowRight';
import { isB2B } from '@/domains/core/settings/utils';
import { FamilyIcon as FamilyIconB2B } from '@/domains/productDiscovery/HeaderLeonidas/b2b/components/FamilyIcon/FamilyIcon';
import { FamilyIcon as FamilyIconB2C } from '@/productDiscovery/HeaderLeonidas/b2c/components/FamilyIcon/FamilyIcon';
import type { MenuCategory } from '@/productDiscovery/HeaderLeonidas/interfaces/MenuTree.model';

import styles from '../../Menu.module.scss';

interface Props {
  family: MenuCategory;
  select: () => any;
}

export const MenuFamilyItemButton: FunctionComponent<Props> = ({
  family,
  select,
}) => (
  <button
    type="button"
    onClick={select}
    className={styles.menuFamilyItemLink}
    data-testid="category-item"
  >
    {isB2B() ? (
      <FamilyIconB2B
        id={Number(family.id)}
        className={styles.menuFamilyItemLinkIcon}
      />
    ) : (
      <FamilyIconB2C
        id={Number(family.id)}
        className={styles.menuFamilyItemLinkIcon}
      />
    )}
    <span>{family.name}</span>
    <ArrowRight className={styles.menuFamilyItemLinkArrow} />
  </button>
);

MenuFamilyItemButton.displayName = 'MenuFamilyItemButton';

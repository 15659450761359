import logger from '@/core/observability/logger';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';
import { SponsoredProductsLocationType } from '@/productDiscovery/SponsoredProducts/interfaces/SponsoredProducts';
import {
  ClickTrackingSpec,
  SPTrackingApi,
} from '@/productDiscovery/SponsoredProducts/services/tracking/api';

export const sendSponsoredProductClickTracking = (product: Product) => {
  if (!product.isSponsored) return;
  try {
    const event: ClickTrackingSpec = {
      seller_id: product.sellerId,
      product_id: parseInt(product.productId, 10),
      cpc: product.cpc,
      location: SponsoredProductsLocationType.SEARCH_BAR,
      mabaya_ad_info: product.adTrackingData,
      position: product.position || undefined,
    };
    SPTrackingApi.trackClick(event);
  } catch (error) {
    logger.error(new Error(`Unable to track click: ${error.stack || error}`));
  }
};

export const sendSponsoredProductImpressionTracking = (products: Product[]) => {
  const impressionEvent = products
    .filter((product) => product.isSponsored)
    .map((sponsoredProduct) => {
      return {
        cpc: sponsoredProduct.cpc,
        location: SponsoredProductsLocationType.SEARCH_BAR,
        mabaya_ad_info: sponsoredProduct.adTrackingData,
        product_id: parseInt(sponsoredProduct.productId, 10),
        position: sponsoredProduct.position || undefined,
      };
    });

  if (impressionEvent.length > 0) {
    try {
      SPTrackingApi.trackImpression(impressionEvent);
    } catch (error) {
      logger.error(
        new Error(`Unable to track impressions: ${error.stack || error}`),
      );
    }
  }
};

import type { Offer as OfferInterface } from '@/domains/productDiscovery/ProductPage/modules/MainDetails/components/Seller/interfaces/OtherSellers.model';
import {
  initialState as productRecommendationInitialState,
  type ProductRecommendationState,
} from '@/domains/productDiscovery/Recommendation/modules/ProductRecommendation/interfaces/ProductRecommendation.model';

import type { TrackedProductDetails } from '../common/interfaces/TrackedProductDetails';

export const addToCartInitialState: AddToCartState = {
  isVisible: false,
  isPending: false,
  errors: [],
  savedProductIds: [],
  products: [],
  offer: undefined,
  productRecommendation: productRecommendationInitialState,
  productsTotalPrice: 0,
};

export type Product = TrackedProductDetails;

export interface AddToCartState {
  isVisible: boolean;
  isPending: boolean;
  errors: Error[];
  savedProductIds: number[];
  products: Product[];
  offer?: OfferInterface;
  playlistReconciliation?: {
    playlistFingerprint: string;
    playlistReconciliation: string;
    playlistName: { titleLabel?: string; blockClicked?: string };
  };
  productRecommendation?: ProductRecommendationState;
  displayAddToCartCustomSummary?: boolean;
  productsTotalPrice?: number;
  showCrossBorderPurchaseBlockedModal?: boolean;
}

export interface Error {
  id: number;
  appCode: string;
}

import interactionResponse from 'await-interaction-response';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { pageTypeSelector } from '@/domains/core/page/page.selectors';
import { isB2C } from '@/domains/core/settings/utils';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { useCustomerProductPreferences } from '@/domains/customerManagement/customerProductPreferences/hooks/useCustomerProductPreferences';
import { useFingerprint } from '@/domains/productDiscovery/fingerprint/hooks/useFingerprint';
import type { ProductSuggestionType } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/model';
import type { Product } from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/interfaces/product';
import {
  sendProductClickTracking,
  sendViewedTracking,
} from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/modules/emptySearchResults/components/HighlightedProducts/tracking';
import {
  defaultTopProductsTitle,
  favoriteProductsB2BTitle,
  lastSeenTitle,
  similarityTitle,
  topProductsB2BTitle,
  topSalesTitle,
} from '@/domains/productDiscovery/HeaderLeonidas/modules/productSuggestions/modules/emptySearchResults/components/HighlightedProducts/translations';
import { useMatchMedia } from '@/domains/shoppingCart/hooks/useMatchMedia';

interface UseHighlightedProductsProps {
  products: Product[];
  productsType: ProductSuggestionType;
  position: number;
  engineName?: string;
}

interface UseHighlightedProducts {
  listTitle: string;
  isDesktop: boolean;
  sendProductCardClickTracking: (product: Product, prPosition: number) => void;
  sendPlaylistViewedTracking: () => void;
}

export const useHighlightedProducts = ({
  products,
  productsType,
  position,
  engineName,
}: UseHighlightedProductsProps): UseHighlightedProducts => {
  const pageType = useSelector(pageTypeSelector);
  const { userId } = useSelector(authSelector);
  const fingerPrint = useFingerprint();
  const playlistFingerprint = useMemo(
    () =>
      userId && engineName ? `${engineName}-${userId}-${Date.now()}` : null,
    [userId, engineName],
  );
  const isDesktop = useMatchMedia('(min-width: 1024px)');
  const { purchasedTwice } = useCustomerProductPreferences();

  /*TODO: this was a call to 'useFeatureFlags' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
  const [customerProductPreferencesEnabled] = [true];
  /*TODO: this was a call to 'useABTests' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
  const [customerProductPreferencesAbtest] = [false];

  const sendProductCardClickTracking = useCallback(
    (product: Product, prPosition: number) => {
      sendProductClickTracking({ product, productsType, prPosition, pageType });
    },
    [productsType, pageType],
  );

  const sendPlaylistViewedTracking = useCallback(async () => {
    await interactionResponse();
    sendViewedTracking({
      products,
      position,
      productsType,
      engineName,
      fingerPrint,
      playlistFingerprint,
      pageType,
    });
  }, [
    products,
    position,
    productsType,
    engineName,
    fingerPrint,
    playlistFingerprint,
    pageType,
  ]);

  const topProductsTitle = useMemo(() => {
    if (isB2C()) {
      return isDesktop ? topSalesTitle : defaultTopProductsTitle;
    }

    const hasFavorites =
      customerProductPreferencesAbtest &&
      customerProductPreferencesEnabled &&
      products.some((item) => purchasedTwice.has(item.modelId));

    return hasFavorites ? favoriteProductsB2BTitle : topProductsB2BTitle;
  }, [
    isDesktop,
    products,
    purchasedTwice,
    customerProductPreferencesAbtest,
    customerProductPreferencesEnabled,
  ]);

  const productsListTitle = (type?: ProductSuggestionType) => {
    switch (type) {
      case 'top':
        return topProductsTitle;
      case 'history':
        return lastSeenTitle;
      case 'similarity':
        return similarityTitle;
      default:
        return defaultTopProductsTitle;
    }
  };

  return {
    listTitle: productsListTitle(productsType),
    isDesktop,
    sendProductCardClickTracking,
    sendPlaylistViewedTracking,
  };
};

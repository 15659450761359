import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import type { MessageFormatterReturnType } from '@/domains/core/localization/translate';
import styles from './ProductsContainer.module.scss';
interface Props {
  classname?: string;
  productsElement: JSX.Element[];
  title?: MessageFormatterReturnType;
  button?: ReactElement<{
    className: string;
  }>;
}
export const ProductsContainer: FunctionComponent<Props> = ({
  productsElement,
  classname,
  title,
  button
}) => {
  const rootClassnames = classnames(classname, styles.root);
  return <div className={rootClassnames}>
      {title}
      <div className={styles.products}>{productsElement}</div>
      {button && <div className={styles.seeMoreButton}>{button}</div>}
    </div>;
};
ProductsContainer.displayName = 'ProductsContainer';
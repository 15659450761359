import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { StoreError } from '../../../interfaces/error';
import type {
  MassLandingMetadata,
  MassLandingState,
} from '../MassLanding.model';

export const selectMassLanding = (
  state: Partial<SpartacuxProductDiscoveryDomainState>,
): MassLandingState | undefined => state?.productDiscovery?.massLandingPage;

export const selectMassLandingMetadata = (
  state: SpartacuxProductDiscoveryDomainState,
): MassLandingMetadata | undefined => selectMassLanding(state)?.metadata;

export const selectMassLandingError = (
  state: SpartacuxProductDiscoveryDomainState,
): StoreError | undefined => selectMassLanding(state)?.error;

export const selectMassLandingCategoryId = (
  state: SpartacuxProductDiscoveryDomainState,
): string => selectMassLandingMetadata(state)?.categoryId?.toString() ?? '';

export const useMassLandingCategoryId = () =>
  useSelector(selectMassLandingCategoryId);

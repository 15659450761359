import type { PropsWithChildren } from 'react';

import {
  BasePage,
  type BasePageProps,
} from '@/domains/core/shell/components/BasePage';

export const BaseLayout = ({
  children,
  ...props
}: PropsWithChildren<BasePageProps>) => (
  <BasePage {...props}>{children}</BasePage>
);

export const isLocalStorageAvailable = () => {
  let storage;
  try {
    storage = window.localStorage;
    const testValue = '__storage_test__';
    storage.setItem(testValue, testValue);
    storage.removeItem(testValue);
    return true;
  } catch (e) {
    return false;
  }
};

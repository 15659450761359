import { useSelector } from 'react-redux';

import type {
  MassLandingMetadata,
  MassLandingState,
} from '../MassLanding.model';
import {
  selectMassLanding,
  selectMassLandingMetadata,
} from '../selectors/massLanding';

export const useMassLanding = (): MassLandingState | undefined =>
  useSelector(selectMassLanding);

export const useMassLandingMetadata = (): MassLandingMetadata | undefined =>
  useSelector(selectMassLandingMetadata);

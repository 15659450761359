import type React from 'react';

import { Span } from '@/core/tamagoshiTailwind/components/Typography/Typography';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';
import { Link } from '@/domains/core/routing/components/Link';
import { useABFFlagsValidator } from '@/domains/productDiscovery/CommercialAnimation/hooks/useABFFlagsValidator';
import type { QuickAccessLinkModel } from '@/domains/productDiscovery/HeaderLeonidas/interfaces/HeaderLeonidas.server.model';

interface QuickAccessLinksItemProps {
  link: QuickAccessLinkModel;
  handleClick: (e: React.MouseEvent) => void;
}

export const QuickAccessLinksItem: React.FC<QuickAccessLinksItemProps> = ({
  link,
  handleClick,
}) => {
  if (!useABFFlagsValidator(link)) {
    return null;
  }

  const { title, url, target, className = '' } = link;

  const classNames = twMerge(
    `flex max-h-[24px] cursor-pointer flex-row flex-nowrap items-center
    justify-center whitespace-nowrap border-none bg-none px-s py-xs
    text-foundation-inverted decoration-0 hover:rounded-card
    hover:bg-brand-primary active:rounded-card active:bg-brand-primary`,
    `desktop:relative desktop:h-[24px] desktop:min-h-[24px]
    desktop:overflow-hidden`,
    className === 'promo' &&
      `rounded-card bg-promotion-primary hover:bg-promotion-primaryHover
      active:bg-promotion-primaryHover`,
    className === 'blackfriday' &&
      `rounded-card bg-foundation-quinary hover:bg-foundation-overlayStrong
      active:bg-foundation-overlayStrong`,
    className === 'b2b' &&
      `b2b rounded-card bg-accent-primary text-foundation-primary
      hover:bg-accent-primaryHover active:bg-accent-primaryHover`,
  );

  return (
    <div>
      {url ? (
        <Link
          className={classNames}
          href={url}
          target={target}
          key={title}
          data-testid="quickaccess-link"
          onClick={handleClick}
        >
          <Span scale="body2" weight="semibold">
            {title}
          </Span>
        </Link>
      ) : (
        <button
          type="button"
          className={classNames}
          key={title}
          data-testid="quickaccess-button"
          onClick={handleClick}
        >
          <Span scale="body2" weight="semibold">
            {title}
          </Span>
        </button>
      )}
    </div>
  );
};

QuickAccessLinksItem.displayName = 'QuickAccessLinksItem';

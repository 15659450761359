import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

import type { StoreError } from '../../../interfaces/error';
import type { CategoryMetadata, CategoryState } from '../Category.model';

export const selectCategory = (
  state: Partial<SpartacuxProductDiscoveryDomainState>,
): CategoryState | undefined => state?.productDiscovery?.category;

export const selectCategoryMetadata = (
  state: SpartacuxProductDiscoveryDomainState,
): CategoryMetadata | undefined => selectCategory(state)?.metadata;

export const selectCategoryError = (
  state: SpartacuxProductDiscoveryDomainState,
): StoreError | undefined => selectCategory(state)?.error;

import type { FC } from 'react';

interface Props {
  title: string;
  onActionLinkClick?(): void;
  actionLinkTitle?: string;
}

export const SuggestionBlockTitle: FC<Props> = ({
  title,
  onActionLinkClick,
  actionLinkTitle,
}) => (
  <div
    className="mb-xs inline-flex w-full items-center justify-between p-xs
      font-bold text-foundation-primary first:my-0"
  >
    <span>{title}</span>
    {onActionLinkClick && (
      <span
        onKeyUp={onActionLinkClick}
        role="button"
        tabIndex={0}
        className="font-semibold underline"
        onClick={onActionLinkClick}
      >
        {actionLinkTitle}
      </span>
    )}
  </div>
);
